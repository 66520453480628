<div class="d-flex align-item-center min-vh-100 main-container">
  <div
    class="border-end sidebar"
    [ngStyle]="{
      width: collapsible ? '6.5rem' : '17%',
      transition: 'width 0.5s'
    }"
  >
    <app-sidebar
      [collapsible]="collapsible"
      (emitCollapsed)="collapsible = $event"
    ></app-sidebar>
  </div>
  <div
    class="col-11"
    [ngStyle]="{
      'margin-left': '6rem'
    }"
  >
    <div
      class="container-fluid g-0 p-5 pe-3"
      [ngClass]="{ 'pb-0': router.url.split('/')[3] === 'help' }"
    >
      <router-outlet></router-outlet>
      <button class="top-arrow" [class.show-link]="showBtn" (click)="goToTop()">
        <img src="assets/icons/top-arrow-icon.svg" alt="" width="25" />
      </button>
    </div>
  </div>
</div>
