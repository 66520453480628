import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TokenConfiguration } from 'src/app/services/core/IApp';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'token-form',
  templateUrl: './token-form.component.html',
  styleUrls: ['./token-form.component.scss'],
})
export class TokenFormComponent {
  @Input() token: TokenConfiguration | null = null;

  @Input() tokenType: string = '';

  @Output() onSubmit: EventEmitter<TokenConfiguration> =
    new EventEmitter<TokenConfiguration>();

  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();

  @Output() onChanges: EventEmitter<TokenConfiguration> =
    new EventEmitter<TokenConfiguration>();

  tokenForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    amount: new FormControl('', Validators.compose([Validators.required])),
    type: new FormControl('', Validators.required),
    duration: new FormControl('', Validators.required),
    unit: new FormControl('', Validators.required),
  });

  units: string[] = ['days', 'weeks', 'months', 'years'];

  constructor(public globals: GlobalsService) {}

  ngOnInit() {
    this.tokenForm.controls['type'].setValue(this.tokenType);
  }

  ngOnChanges() {
    if (this.token) {
      this.tokenForm.patchValue({
        ...this.token,
        duration: this.token.expireAt.duration,
        unit: this.token.expireAt.unit,
      });
    }

    if (this.tokenForm.controls['type'].value !== 'credit') {
      this.tokenForm.controls['duration'].clearValidators();
      this.tokenForm.controls['unit'].clearValidators();
    } else {
      this.tokenForm.controls['duration'].setValidators([Validators.required]);
      this.tokenForm.controls['unit'].setValidators([Validators.required]);
    }
  }

  submit() {
    if (this.tokenForm.invalid) {
      return;
    }
    this.onSubmit.emit(this.tokenForm.value);
  }

  selectDropdown(event: any) {
    // close other dropdown options
    event.preventDefault();
    const element = document.querySelectorAll('.dropdown-options');
    element.forEach((el) => {
      if (
        el.classList.contains('show') &&
        el !== event.target.nextElementSibling
      ) {
        el.classList.remove('show');
      }
    });

    // add show class to dropdown options
    event.target.children[0]?.classList.toggle('show');

    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target?.classList.contains('dropdown-option')) {
        element.forEach((el) => {
          if (el.classList.contains('show')) {
            el.classList.remove('show');
          }
        });
      }
    });
  }

  setUnit(unit: string) {
    this.tokenForm.controls['unit'].setValue(unit);
  }

  get name() {
    return this.tokenForm.get('name') as FormControl;
  }

  get amount() {
    return this.tokenForm.get('amount') as FormControl;
  }

  get type() {
    return this.tokenForm.get('type') as FormControl;
  }

  get duration() {
    return this.tokenForm.get('duration') as FormControl;
  }

  get unit() {
    return this.tokenForm.get('unit') as FormControl;
  }
}
