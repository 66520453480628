import { Pipe, PipeTransform } from '@angular/core';
import { Country } from '../core/IApp';

@Pipe({
  name: 'phoneFilter',
})
export class PhoneFilterPipe implements PipeTransform {
  transform(phone: Country[], searchTerm: string): Country[] {
    if (!phone || !searchTerm) return phone;

    return phone.filter(
      (phone) =>
        phone.name
          .toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase()) ||
        phone.phone_code
          .toString()
          .includes(searchTerm.toLocaleLowerCase().replace('+', ''))
    );
  }
}
