import { Component } from '@angular/core';
import { SwapUserService } from '../../../services/api/swap_user/swap-user.service';
import { GlobalsService } from '../../../services/core/globals';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent {
  modalId = '#contactUserModal';
  modalId2 = '#contactUserViaEmailModal';
  modalId3: string = '#deactivateReasonModal';
  modalId4: string = '#freezeAccountModal';
  filterTabs: Array<{ name: string; value: string }> = [
    {
      name: 'Active Users',
      value: 'online',
    },
    {
      name: 'Frozen Users',
      value: 'freeze',
    },
    {
      name: 'Deactivated Users',
      value: 'deactivated',
    },
  ];

  constructor(
    public swapUserService: SwapUserService,
    public globals: GlobalsService
  ) {}

  async ngOnInit() {
    await this.swapUserService.getSwapUsers();
  }

  ngAfterViewInit() {
    setTimeout(async () => {
      let status = this.globals.router.url.split('?')[1] ?? '';
      status = status.split('=')[1];
      if (!status) return;
      this.swapUserService.swapUsersPagination.mode =
        this.filterTabs.find((tab) => tab.value === status) ??
        this.filterTabs[0];
      await this.swapUserService.getSwapUsers();
    }, 100);
  }

  ngOnDestroy() {
    this.swapUserService.resetParams();
  }

  selectDropdown(event: any) {
    // close other dropdown options
    event.preventDefault();
    let mainElement = event.target;
    const element = document.querySelectorAll('.dropdown-options');
    element.forEach((el) => {
      if (
        el.classList.contains('show') &&
        el !== mainElement.nextElementSibling
      ) {
        el.classList.remove('show');
      }
    });

    if (mainElement.nodeName !== 'BUTTON') mainElement = mainElement.parentNode;

    // add show class to dropdown options
    for (let i = 0; i < mainElement.children.length; i++) {
      if (mainElement.children[i].classList.contains('dropdown-options')) {
        mainElement.children[i].classList.toggle('show');
      }
    }
    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target?.classList.contains('dropdown-option')) {
        element.forEach((el) => {
          if (el.classList.contains('show')) {
            el.classList.remove('show');
          }
        });
      }
    });
  }

  async itemsPerPageChanged(number: number) {
    this.swapUserService.swapUsersPagination.itemsPerPage = number;
    await this.swapUserService.getSwapUsers();
  }

  async search(event: string) {
    this.swapUserService.swapUsersPagination.search = event;
    await this.swapUserService.getSwapUsers();
  }

  async changePage(page: number) {
    this.swapUserService.swapUsersPagination.page = page;
    await this.swapUserService.getSwapUsers();
  }

  async sortUsersBy(sortBy: string) {
    this.swapUserService.swapUsersPagination.sort.status = sortBy;
    this.swapUserService.swapUsersPagination.sort.order =
      this.swapUserService.swapUsersPagination.sort.order === 'asc'
        ? 'desc'
        : 'asc';
    await this.swapUserService.getSwapUsers();
  }

  async activateAccount() {
    await this.swapUserService.deactivateSwapUser(
      this.swapUserService.swapUser,
      ''
    );
    await this.swapUserService.getSwapUsers();
  }

  async unfreezeAccount() {
    await this.swapUserService.freezeSwapUser(
      this.swapUserService.swapUser,
      false
    );
    await this.swapUserService.getSwapUsers();
  }

  async resetFilters() {
    this.swapUserService.resetParams();
    await this.swapUserService.getSwapUsers();
  }

  navigateToUserProfile(userId: string, tab: string) {
    this.globals.router.navigate(['/main/users/profile', userId], {
      queryParams: { tab },
    });
  }
}
