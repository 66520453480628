import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/services/api/auth/auth.service';
import { HelpHistoryService } from 'src/app/services/api/help-history/help-history.service';
import { SwapUserService } from 'src/app/services/api/swap_user/swap-user.service';
import { TransactionsService } from 'src/app/services/api/transaction/transactions.service';
import { EmailToUser } from 'src/app/services/core/IApp';
import { GlobalsService } from 'src/app/services/core/globals';
import { NgZone } from '@angular/core';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() title: string = '';
  modalId: string = '#deactivateReasonModal';
  modalId2: string = '#deactivateAccountModal';
  modalId3: string = '#freezeAccountModal';
  modalId4 = '#contactUserModal';
  modalId5 = '#contactUserViaEmailModal';
  modalId6: string = '#extendTranxTimeModal';
  dropdownOpen: boolean = false;
  keepDropdownOpen: boolean = false;
  reason: string = '';

  constructor(
    private titleService: Title,
    public globals: GlobalsService,
    public swapUserService: SwapUserService,
    public helpHistoryService: HelpHistoryService,
    public transactionService: TransactionsService,
    public authService: AuthService,
    private ngZone: NgZone
  ) {
    this.globals.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.title = this.title || this.titleService.getTitle();
      }
    });
  }

  async ngOnInit() {
    // this.globals.spinner.show();
    this.title = this.title || this.titleService.getTitle();
    await this.authService.getNotifications(1);
    // this.globals.spinner.hide();
  }

  async deactivateAccount() {
    await this.swapUserService.deactivateSwapUser(
      this.swapUserService.swapUser,
      this.reason
    );
    this.reason = '';
    this.swapUserService.swapUser.deactivated.active = true;
  }

  async freezeAccount() {
    await this.swapUserService.freezeSwapUser(
      this.swapUserService.swapUser,
      true
    );
    this.swapUserService.swapUser.freeze = true;
    if (
      this.globals.router.url.includes('users') &&
      this.globals.router.url.split('/').length > 3
    ) {
      await this.swapUserService.getSwapUser(this.swapUserService.swapUser._id);
    } else {
      await this.swapUserService.getSwapUsers();
    }
  }

  selectDropdown(event: any) {
    // close other dropdown options
    event.preventDefault();
    let mainElement = event.target;
    const element = document.querySelectorAll('.dropdown-options');
    element.forEach((el) => {
      if (
        el.classList.contains('show') &&
        el !== mainElement.nextElementSibling
      ) {
        el.classList.remove('show');
      }
    });

    if (mainElement.nodeName !== 'BUTTON') mainElement = mainElement.parentNode;

    // add show class to dropdown options
    for (let i = 0; i < mainElement.children.length; i++) {
      if (mainElement.children[i].classList.contains('dropdown-options')) {
        mainElement.children[i].classList.toggle('show');
      }
    }

    // close dropdown options on click outside
    document.addEventListener('mousedown', (e: any) => {
      const element = document.querySelectorAll('.dropdown-options');
      element.forEach((el) => {
        if (!el.contains(e.target)) {
          el.classList.remove('show');
        }
      });
    });
  }

  contactUser(type: string) {
    switch (type) {
      case 'chat':
        this.globals.spinner.show();
        let user = this.swapUserService.swapUser;
        this.globals.router.navigate(['/main/help-history']);
        this.globals.spinner.hide();
        this.globals.toast.success(
          `You have successfully contacted ${user.name.first} ${user.name.last} (${user.username})`
        );
        break;
      case 'email':
        this.globals.closeModal(this.modalId);
        this.globals.openModal(this.modalId5);
        break;
      default:
        break;
    }
  }

  async sendEmail(emailForm: EmailToUser) {
    let resp: any = await this.helpHistoryService.createTicket(
    emailForm.subject,
      emailForm.category,
      this.swapUserService.swapUser._id
    );
    emailForm.ticketId = resp._id;
    emailForm.mailId = '';
    await this.helpHistoryService.sendEmailToUser(emailForm);
  }

  async extendTime(time: number) {
    this.globals.spinner.show();
    await this.transactionService.extendNegotiationTime(
      this.transactionService.transaction.messageId,
      this.transactionService.transaction._id,
      time
    );
    await this.transactionService.getMessagesByBiddingId(
      this.transactionService.transaction._id
    );
    this.globals.spinner.hide();
  }




  async markAsRead(
    notificationId: string,
    trigger?: string,
  ) {
    trigger && this.globals.navigateByTrigger(trigger);
    await this.authService.markNotificationAsRead(notificationId);
  }



  markAllNotificationsAsRead(event: MouseEvent) {
    event.stopPropagation();
    this.dropdownOpen = false; 
    this.ngZone.run(async () => {
      try {
        await this.authService.markAllAsRead();   
      } catch (error) {
        console.error('Error marking notifications as read:', error);
   
      }
    });
  }

}
