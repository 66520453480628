<swap-modal
  [modalId]="modalId"
  [centered]="true"
  [backdrop]="'static'"
  [keyboard]="false"
>
  <div class="row m-3">
    <div class="col-12 d-flex justify-content-between mb-2">
      <img src="assets/images/featured-icon-3.svg" alt="" />
      <button
        type="button"
        class="closeBtn mt-3"
        data-bs-dismiss="modal"
        aria-label="Close"
        #closeBtn
      >
        <img src="assets/icons/close-icon-pink.svg" alt="" />
      </button>
    </div>
    <div class="col-12 mb-3">
      <h5 class="fw-700 mb-4">Ticket Merge</h5>
      <div class="row g-0 ticket mb-4">

        <div class="col d-flex align-items-center">
          <ng-container *ngIf="ticketId">
            <span class="label font-12">#{{ ticketId.slice(-8) }}</span>
          </ng-container>
          <ng-container *ngIf="ticket && ticket._id">
            <span class="label font-12">#{{ ticket._id.slice(-8) }}</span>
          </ng-container>
        </div>
        <div class="col-4">
          <p class="mb-0 fw-500">Content</p>
          <p class="mb-0 text-muted text-nowrap font-12">
            May 16, 2022 . 17:25
          </p>
        </div>
      </div>
      <p class="mb-3 font-12 text-muted">
        Tickets can be combined with other unresolved tickets from the same
        requester. However, this ticket cannot be merged as the customer
        currently has no pending tickets.
      </p>
      <h6 class="fw-700 mb-4">Ticket ID to Merge into</h6>
      <form class="row g-0 gap-4 mb-4" [formGroup]="mergeTicketForm" novalidate (ngSubmit)="mergeTicket(); closeBtn.click()">
        <div class="col">
          <input
            type="text"
            class="form-control"
            id="ticketId"
            placeholder="Enter Ticket ID"
            formControlName="linkedTicketId"
            required
          />
          <div class="text-danger ms-3" *ngIf="linkedTicketId.invalid && (linkedTicketId.dirty || linkedTicketId.touched)">
            <small class="mb-0" *ngIf="linkedTicketId?.errors?.['required']">
              Ticket ID is required
            </small>
          </div>
        </div>
        <div class="col-4">
          <button
            type="submit"
            class="btn btn-secondary btn-block w-100"
            aria-label="Close"
          >
            Merge
          </button>
        </div>
      </form>
      <button
        type="button"
        class="btn btn-outline btn-block"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        Cancel
      </button>
    </div>
  </div>
</swap-modal>
