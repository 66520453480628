import { Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
// import { TransactionNotificationsComponent } from 'src/app/shared/components/transaction-notifications/transaction-notifications.component';
import { Notification } from './IApp';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  keywords = ['received', 'uploaded', 'extend', 'minutes'];

  constructor(private toast: HotToastService) {}

  send(type: string, data: Notification | any) {
    switch (type) {
      case 'browser':
        this.BrowserNotification(
          data.title,
          data.message,
          data.biddingId &&
            window.location.origin + '/main/transaction/' + data.biddingId
        );
        break;
    //   case 'toast':
    //     this.toastNotification(data);
        break;
      case 'sound':
        this.soundNotification(data);
        break;
      default:
        break;
    }
  }

  private BrowserNotification(title: string, body: string, route: string) {
    body = body.replace(/{{/g, '').replace(/}}/g, '');
    if (!('Notification' in window)) {
    } else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      this.createNotification(title, body, route);
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          this.createNotification(title, body, route);
        }
      });
    }
  }

  private createNotification(title: string, body: string, route: string) {
    var notification = new Notification(title, {
      icon: 'assets/images/logoIcon.svg',
      body: body,
    });
    notification.onclick = function (event) {
      event.preventDefault(); // prevent the browser from focusing the Notification's tab
      window.open(route, '_blank');
    };
  }

//   private toastNotification(data: any) {
//     if (
//       window.location.pathname.includes('messages') &&
//       !this.includesAll(data.message, this.keywords)
//     )
//       return;
//     this.toast.show(TransactionNotificationsComponent, {
//       data,
//       autoClose: false,
//       dismissible: true,
//       className: 'transaction-notification color-text',
//       id: data.userId,
//     });
//     this.soundNotification('toast');
//   }

  private soundNotification(type: string) {
    switch (type) {
      case 'toast':
        var audio = new Audio('assets/sounds/toast.mp3');
        audio.volume = 0.2;
        audio.play();
        break;
      case 'message':
        var audio = new Audio('assets/sounds/message-alert.mp3');
        audio.volume = 0.2;
        audio.play();
        break;
      default:
        break;
    }
  }

  private includesAll(message: string, keywords: Array<string>) {
    return keywords.every((keyword) => message.toLowerCase().includes(keyword));
  }
}
