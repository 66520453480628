import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Toolbar, Editor } from 'ngx-editor';

@Component({
  selector: 'messaging-form',
  templateUrl: './messaging-form.component.html',
  styleUrls: ['./messaging-form.component.scss'],
})
export class MessagingFormComponent {
  @Output() emitMessage: EventEmitter<{ comment: string }> = new EventEmitter<{
    comment: string;
  }>();

  @Input() showEmail: boolean = false;

  messageForm: FormGroup = new FormGroup({
    comment: new FormControl('', Validators.required),
  });

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['ordered_list', 'bullet_list'],
    ['link', 'image'],
    ['text_color', 'background_color'],
  ];

  editor: Editor = new Editor();

  onSubmit() {
    if (this.messageForm.invalid) {
      return;
    }
    this.emitMessage.emit({
      comment: this.messageForm.value.comment,
    });
    this.messageForm.reset({
      comment: '',
      email: false,
    });
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.onSubmit();
    }
  }
}
