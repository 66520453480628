<div class="row m-3 mx-0">
  <div class="col-12 d-flex justify-content-between py-3 mb-5 border-bottom">
    <h5 class="fw-700 text-center mb-0">
      {{ type !== "remove" ? "Token Top Up" : "Token Deduct" }}
    </h5>
    <button
      (click)="clearCheckboxes()"
      type="button"
      class="closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
    >
      <img src="assets/icons/close-icon.svg" alt="" />
    </button>
  </div>
  <div class="col-12 mb-5">
    <form
      class="row gy-4 justify-content-center"
      [formGroup]="bulkTopUpForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="col-12">
        <div for="bulkUsers" class="mb-1 fw-700">
          Users ({{ swapUsers.length }}/50)
        </div>

        <div class="usersContainer">
          <span *ngFor="let user of swapUsers | slice : 0 : 5">
            <label for="{{ user.id }}" placeholder="Enter amount" class="user"
              >@{{ user.username }}
              <img
                src="assets/icons/close-icon-sm.svg"
                alt=""
                (click)="removeUser(user.id)"
              />
            </label>

            <input
              [ngStyle]="{
                display: 'none'
              }"
              type="checkbox"
              value="{{ user }}"
              id="{{ user.id }}"
              class="input-border"
              formControlName="users"
            />
          </span>
          <span *ngIf="swapUsers.length > 5" class="moreUsers">
            <!-- Plus Icon -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
              />
            </svg>
            {{ swapUsers.length - 5 }} more
          </span>
        </div>
      </div>
      <div class="col-12" *ngIf="type !== 'remove'">
        <label for="category" class="mb-1 fw-700">Token Type</label>
        <div
          class="input-border dropdown"
          (click)="selectDropdown($event)"
          [ngClass]="{ 'text-muted': !bulkTopUpForm.value.type }"
        >
          {{ bulkTopUpForm.value.type || "Select Token Type" }}
          <div class="dropdown-options">
            <div
              class="dropdown-option"
              *ngFor="let type of tokenTypes"
              (click)="selectItem(type, 'type')"
            >
              {{ type }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="type !== 'remove'">
        <label for="category" class="mb-1 fw-700">Validity</label>
        <div
          class="input-border dropdown"
          (click)="selectDropdown($event)"
          [ngClass]="{ 'text-muted': !bulkTopUpForm.value.expiresAt }"
        >
          {{ bulkTopUpForm.value.expiresAt || "Select Validity" }}
          <div class="dropdown-options">
            <div
              class="dropdown-option"
              *ngFor="let val of validities"
              (click)="selectItem(val, 'expiresAt')"
            >
              {{ val }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <label for="amount" class="mb-1 fw-700">Amount (EXs)</label>
        <input
          placeholder="Enter amount"
          type="number"
          id="amount"
          class="input-border"
          formControlName="amount"
        />
      </div>
      <div class="col-11 text-end">
        <button class="swapBtn" (click)="closeBtn.click()" type="button">
          Cancel
        </button>
        <button
          class="swapBtn update ms-2"
          type="submit"
          [disabled]="!bulkTopUpForm.valid"
        >
          Send
        </button>
      </div>
    </form>
  </div>
</div>
