import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Ticket } from 'src/app/services/core/IApp';

@Component({
  selector: 'ticket-form',
  templateUrl: './ticket-form.component.html',
  styleUrls: ['./ticket-form.component.scss'],
})
export class TicketFormComponent {
  @Input() ticket: Ticket | undefined;
  @Output() emitTicket: EventEmitter<Ticket> = new EventEmitter<Ticket>();

  ticketForm: FormGroup = new FormGroup({
    subject: new FormControl('', Validators.compose([Validators.required])),
    category: new FormControl('', Validators.compose([Validators.required])),
    status: new FormControl('', Validators.compose([Validators.required])),
  });

  statuses = [
    { name: 'New', value: 'new' },
    { name: 'Open', value: 'open' },
    { name: 'Pending', value: 'pending' },
    { name: 'On Hold', value: 'on hold' },
    { name: 'Solved', value: 'solved' },
    { name: 'Closed', value: 'closed' },
  ];

  categories = [
    'App Security',
    'Transfer Delay',
    'Onboarding issue',
    'Token Query',
    'Payment Upload',
    'Uncredited Token',
  ];

  constructor() {}

  ngOnChanges() {
    if (this.ticket) this.ticketForm.patchValue(this.ticket as Ticket);
  }

  onSubmit() {
    if (this.ticketForm.invalid) {
      return;
    }
    this.emitTicket.emit(this.ticketForm.value);
    this.ticketForm.reset({
      subject: '',
      category: '',
      status: '',
    });
  }

  selectDropdown(event: any) {
    // close other dropdown options
    event.preventDefault();
    const element = document.querySelectorAll('.dropdown-options');
    element.forEach((el) => {
      if (
        el.classList.contains('show') &&
        el !== event.target.nextElementSibling
      ) {
        el.classList.remove('show');
      }
    });

    // add show class to dropdown options
    event.target.children[0]?.classList.toggle('show');

    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target?.classList.contains('dropdown-option')) {
        element.forEach((el) => {
          if (el.classList.contains('show')) {
            el.classList.remove('show');
          }
        });
      }
    });
  }

  setStatus(status: string) {
    this.ticketForm.controls['status'].setValue(status);
  }

  setCategory(category: string) {
    this.ticketForm.controls['category'].setValue(category);
  }

  displayStatus(status: string) {
    switch (status) {
      case 'new':
        return 'New';
      case 'open':
        return 'Open';
      case 'pending':
        return 'Pending';
      case 'on hold':
        return 'On Hold';
      case 'solved':
        return 'Solved';
      case 'closed':
        return 'Closed';
      default:
        return 'New';
    }
  }
}
