<div class="row m-3">
  <div class="col-12 d-flex justify-content-end mb-5">
    <button
      type="button"
      class="closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
    >
      <img src="assets/icons/close-icon-pink.svg" alt="" />
    </button>
  </div>
  <div class="col-12 mb-5">
    <h5 class="fw-700 text-center mb-4">Edit Tier 3</h5>
    <form
      class="row gy-4 justify-content-center"
      [formGroup]="tier3Form"
      (ngSubmit)="onSubmit()"
    >
      <!-- <div class="col-11 d-flex align-items-center gap-2">
        <div class="col-11 d-flex gap-3">
          <img
            src="assets/icons/facebook-cl.svg"
            alt="Facebook Image"
            width="28px"
            height="27.4px"
          />
          <div>
            <p class="fw-700 mb-0 font-14">Facebook</p>
            <p class="mb-0 text-secondary">
              {{ tier3Form.value.fb_url || "Not Set" }}
            </p>
          </div>
        </div>
        <div class="col-1 d-flex">
          <button
            class="deleteBtn bg-danger"
            type="button"
            (click)="deleteUrl('fb_url')"
          >
            <i class="bi bi-x"></i>
          </button>
        </div>
      </div>
      <div class="col-11 d-flex align-items-center gap-2 mb-3">
        <div class="col-11 d-flex gap-3">
          <img
            src="assets/icons/instagram.svg"
            alt="Instagram Image"
            width="28px"
            height="27.4px"
          />
          <div>
            <p class="fw-700 mb-0 font-14">Instagram</p>
            <p class="mb-0 text-secondary">
              {{ tier2Form.value.instagram || "Not Set" }}
            </p>
          </div>
        </div>
        <div class="col-1 d-flex">
          <button
            class="deleteBtn bg-danger"
            type="button"
            (click)="deleteUrl('instagram')"
          >
            <i class="bi bi-x"></i>
          </button>
        </div>
      </div> -->
      <div class="col-11 text-end">
        <button class="swapBtn" (click)="closeBtn.click()" type="button">
          Cancel
        </button>
        <button class="swapBtn update ms-2" type="submit">Update</button>
      </div>
    </form>
  </div>
</div>
