import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SwapUserService } from 'src/app/services/api/swap_user/swap-user.service';
import { KYC, Swap_User } from 'src/app/services/core/IApp';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'view-kyc',
  templateUrl: './view-kyc.component.html',
  styleUrls: ['./view-kyc.component.scss'],
})
export class ViewKycComponent {
  @Input() modalId: string = '';

  @Input() kyc: KYC | any;

  @Input() user: Swap_User | any;

  @Output() updateKyc: EventEmitter<{
    _id: string;
    field: string;
    status: string;
    username: string;
  }> = new EventEmitter<{
    _id: string;
    field: string;
    status: string;
    username: string;
  }>();

  modalId2: string = '#viewImageModal';

  viewImage: string = '';

  navTabs: string[] = ['summary', 'uploads'];

  activeTab: string = 'summary';

  statusList: string[] = ['successful', 'pending', 'failed'];
  status2List: string[] = ['successful', 'failed'];
  activeStatus: string = 'pending';

  constructor(
    public globals: GlobalsService,
    public swapUserService: SwapUserService
  ) {}

  selectDropdown(event: any) {
    // close other dropdown options
    event.preventDefault();
    let mainElement = event.target;
    const element = document.querySelectorAll('.dropdown-options');
    element.forEach((el) => {
      if (
        el.classList.contains('show') &&
        el !== mainElement.nextElementSibling
      ) {
        el.classList.remove('show');
      }
    });

    if (mainElement.nodeName !== 'BUTTON') mainElement = mainElement.parentNode;

    // add show class to dropdown options
    for (let i = 0; i < mainElement.children.length; i++) {
      if (mainElement.children[i].classList.contains('dropdown-options')) {
        mainElement.children[i].classList.toggle('show');
      }
    }
    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target?.classList.contains('dropdown-option')) {
        element.forEach((el) => {
          if (el.classList.contains('show')) {
            el.classList.remove('show');
          }
        });
      }
    });
  }

  async changeStatus(status: string, field: string) {
    this.updateKyc.emit({
      _id: this.kyc?._id,
      field,
      status,
      username: this.user?.username,
    });
  }

  copyToClipboard(data: string) {
    if (!data) return;
    var copy_message = 'Copied to clipboard';
    navigator.clipboard.writeText(data);
    this.globals.toast.custom(copy_message, 'success', '#fff', '#3d1560');
  }

  async uploadProofOfAddress(event: any) {
    let file = event.target.files[0];
    if (!file) return;
    let formData = new FormData();
    formData.append('address', file);
    await this.swapUserService.uploadProofOfAddress(this.kyc?._id, formData);
  }

  downloadFile(event: any, url: string) {
    event.preventDefault();
    window.open(url, '_blank');
  }

  async setDate(event: string) {
    this.kyc.documentExpirationDate = event;
    await this.changeStatus(
      this.kyc.documentExpirationDate,
      'documentExpirationDate'
    );
  }

  setDateValue(event: string) {
    this.kyc.documentExpirationDate = event;
  }

  convertDateToDays(date: string) {
    let today = new Date();
    let date2 = new Date(date);
    if (date2 < today) {
      return 'Expired';
    }

    return `${Math.floor(
      (date2.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
    )} Days`;
  }
}
