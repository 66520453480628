import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Transaction } from 'src/app/services/core/IApp';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'transaction-modal',
  templateUrl: './transaction-modal.component.html',
  styleUrls: ['./transaction-modal.component.scss'],
})
export class TransactionModalComponent {
  @Input() transaction: Transaction | any;

  @Input() showCloseButton: boolean = true;

  @Output() showProof: EventEmitter<{ biddingId: string; type: string }> =
    new EventEmitter<{ biddingId: string; type: string }>();

  @ViewChild('closeButton') closeButton: any;

  constructor(public globals: GlobalsService) {}

  calculateCurrency(val1: any, val2: any) {
    return Number(val1) * Number(val2);
  }

  navigate(user_id: string) {
    this.globals.router.navigateByUrl(`/main/users/profile/${user_id}`);
    this.globals.closeModal();
  }
}
