<swap-modal
  [modalId]="modalId"
  [scrollable]="true"
  [right]="true"
  [size]="'lg'"
>
  <div class="modal-header border-bottom py-4">
    <h6 class="modal-title fw-bolder" id="staticBackdropLabel">
      Ticket Detail
    </h6>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeModal
    ></button>
  </div>
  <div class="modal-body">
    <div class="d-flex align-items-center gap-2 mt-3">
      <div class="filterOfferOptions">
        <ul class="nav nav-pills">
          <li class="nav-item" *ngFor="let tab of navTabs">
            <a
              class="nav-link filterTab"
              id="{{ tab }}-tab"
              data-bs-toggle="tab"
              attr.data-bs-target="#{{ tab }}"
              type="button"
              role="tab"
              attr.aria-controls="{{ tab }}"
              aria-selected="false"
              [ngClass]="{ active: activeTab === tab }"
              (click)="activeTab = tab"
              >{{ tab | titlecase }}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Tab panes -->
    <div class="tab-content mt-5">
      <div
        class="tab-pane active"
        id="summary"
        role="tabpanel"
        aria-labelledby="summary-tab"
      >
        <div class="accordion" id="accordionExample">
          <div class="accordion-item mb-4">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button fw-bolder"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Overview
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="row">
                  <div class="col-6 d-flex align-items-center">
                    <p class="text-muted">Verification ID</p>
                  </div>
                  <div
                    class="col-6 d-flex justify-content-end align-items-center"
                  >
                    <p class="fw-500">
                      {{ "#" + kyc?._id.slice(-12) }}
                    </p>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-6 d-flex align-items-center">
                    <p class="text-muted">Final Status</p>
                  </div>
                  <div
                    class="col-6 d-flex justify-content-end align-items-center"
                  >
                    <button
                      class="status pointer position-relative"
                      type="button"
                      [ngClass]="{
                        success:
                          kyc?.finalStatus?.toLowerCase() === 'successful',
                        danger: kyc?.finalStatus?.toLowerCase() === 'failed',
                        warning:
                          !kyc?.finalStatus?.toLowerCase() ||
                          kyc?.finalStatus?.toLowerCase() === 'pending'
                      }"
                      (click)="selectDropdown($event)"
                    >
                      <p class="mb-0">
                        {{ kyc?.finalStatus || "Pending" | titlecase }}
                      </p>
                      <i class="bi bi-chevron-down"></i>
                      <div class="dropdown-options">
                        <div
                          class="dropdown-option"
                          *ngFor="let status of statusList"
                          (click)="changeStatus(status, 'finalStatus')"
                        >
                          {{ status | titlecase }}
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 d-flex align-items-center">
                    <p class="text-muted">Date</p>
                  </div>
                  <div
                    class="col-6 d-flex justify-content-end align-items-center"
                  >
                    <p class="fw-500">
                      {{
                        kyc?.createdAt 
                          | date : "dd MMM yyyy" : globals.utc_offset
                      }}
                    </p>
                   
                  
                  </div>
                </div>
      
              <div class="row">
                <div class="col-6 d-flex align-items-center">
                  <p class="text-muted"></p>
                </div>
                <div
                  class="col-6 d-flex justify-content-end align-items-center"
                >
                  <p class="fw-400 text-muted">
                {{globals.formatTimestamp
                    (kyc?.createdAt)}}
             
              </p>

              </div>
              </div>
                <div class="row">
                  <div class="col-6 d-flex align-items-center">
                    <p class="text-muted">Device ID</p>
                  </div>
                  <div
                    class="col-6 d-flex justify-content-end align-items-center"
                  >
                    <p class="fw-500">Not Available</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item mb-4">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button fw-bolder"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                Verification Details
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse show"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="row mb-2">
                  <div class="col d-flex align-items-center">
                    <p class="mb-0 text-muted">
                      Liveliness -
                      <b class="text-dark"
                        >CL
                        {{
                          kyc?.livelinessCheckConfidenceLevel
                            | number : "1.0-0"
                        }}%</b
                      >
                    </p>
                  </div>
                  <div
                    class="col-2 d-flex justify-content-end align-items-center"
                  >
                    <button
                      class="status pointer position-relative"
                      [ngClass]="{
                        success:
                          kyc?.livelinessCheckStatus?.toLowerCase() ===
                          'successful',
                        danger:
                          kyc?.livelinessCheckStatus?.toLowerCase() ===
                          'failed',
                        warning:
                          kyc?.livelinessCheckStatus?.toLowerCase() ===
                          'pending'
                      }"
                      type="button"
                      (click)="selectDropdown($event)"
                    >
                      <p class="mb-0">
                        {{ kyc?.livelinessCheckStatus | titlecase }}
                      </p>
                      <i class="bi bi-chevron-down"></i>
                      <div class="dropdown-options">
                        <div
                          class="dropdown-option"
                          *ngFor="let status of status2List"
                          (click)="
                            changeStatus(status, 'livelinessCheckStatus')
                          "
                        >
                          {{ status | titlecase }}
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col d-flex align-items-center">
                    <p class="mb-0 text-muted">
                      Document Check -
                      <b class="text-dark">{{
                        kyc?.documentType.includes("id")
                          ? "ID Card"
                          : (kyc?.documentType.replace("_", " ") | titlecase)
                      }}</b>
                    </p>
                  </div>
                  <div
                    class="col-2 d-flex justify-content-end align-items-center"
                  >
                    <button
                      class="status pointer position-relative"
                      [ngClass]="{
                        success:
                          kyc?.documentCheckStatus?.toLowerCase() ===
                          'successful',
                        danger:
                          kyc?.documentCheckStatus?.toLowerCase() === 'failed',
                        warning:
                          kyc?.documentCheckStatus?.toLowerCase() === 'pending'
                      }"
                      type="button"
                      (click)="selectDropdown($event)"
                    >
                      <p class="mb-0">
                        {{ kyc?.documentCheckStatus | titlecase }}
                      </p>
                      <i class="bi bi-chevron-down"></i>
                      <div class="dropdown-options">
                        <div
                          class="dropdown-option"
                          *ngFor="let status of statusList"
                          (click)="changeStatus(status, 'documentCheckStatus')"
                        >
                          {{ status | titlecase }}
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col d-flex align-items-center">
                    <p class="mb-0 text-muted">
                      Face Match -
                      <b class="text-dark"
                        >CL
                        {{
                          kyc?.faceMatchConfidenceLevel | number : "1.0-0"
                        }}%</b
                      >
                    </p>
                  </div>
                  <div
                    class="col-2 d-flex justify-content-end align-items-center"
                  >
                    <button
                      class="status pointer position-relative"
                      [ngClass]="{
                        success:
                          kyc?.faceMatchStatus?.toLowerCase() === 'successful',
                        danger:
                          kyc?.faceMatchStatus?.toLowerCase() === 'failed',
                        warning:
                          kyc?.faceMatchStatus?.toLowerCase() === 'pending'
                      }"
                      type="button"
                      (click)="selectDropdown($event)"
                    >
                      <p class="mb-0">{{ kyc?.faceMatchStatus | titlecase }}</p>
                      <i class="bi bi-chevron-down"></i>
                      <div class="dropdown-options">
                        <div
                          class="dropdown-option"
                          *ngFor="let status of statusList"
                          (click)="changeStatus(status, 'faceMatchStatus')"
                        >
                          {{ status | titlecase }}
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col d-flex align-items-center">
                    <p class="mb-0 text-muted">AML</p>
                  </div>
                  <div
                    class="col-2 d-flex justify-content-end align-items-center"
                  >
                    <button
                      class="status pointer position-relative"
                      [ngClass]="{
                        success:
                          kyc?.antiMoneyLaunderingStatus?.toLowerCase() ===
                          'successful',
                        danger:
                          kyc?.antiMoneyLaunderingStatus?.toLowerCase() ===
                          'failed',
                        warning:
                          kyc?.antiMoneyLaunderingStatus?.toLowerCase() ===
                            'pending' || !kyc?.antiMoneyLaunderingStatus
                      }"
                      type="button"
                      (click)="selectDropdown($event)"
                    >
                      <p class="mb-0">
                        {{
                          kyc?.antiMoneyLaunderingStatus || "Pending"
                            | titlecase
                        }}
                      </p>
                      <i class="bi bi-chevron-down"></i>
                      <div class="dropdown-options">
                        <div
                          class="dropdown-option"
                          *ngFor="let status of status2List"
                          (click)="
                            changeStatus(status, 'antiMoneyLaunderingStatus')
                          "
                        >
                          {{ status | titlecase }}
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col d-flex align-items-center gap-2">
                    <p class="mb-0 text-muted">Proof of Address</p>
                    <label
                      for="file"
                      class="text-dark fw-500 d-flex align-items-center gap-2 pointer"
                      (click)="
                        kyc?.proofOfAddressUrl &&
                          downloadFile($event, kyc?.proofOfAddressUrl)
                      "
                    >
                      <img
                        src="assets/icons/Download-icon.svg"
                        alt="Icon"
                        [ngStyle]="{
                          transform: kyc?.proofOfAddressUrl
                            ? ''
                            : 'rotate(180deg)'
                        }"
                      />{{ kyc?.proofOfAddressUrl ? "Download" : "Upload" }}
                    </label>
                    <input
                      type="file"
                      id="file"
                      class="d-none"
                      accept=".jpeg, .jpg, .png, .pdf"
                      (change)="uploadProofOfAddress($event)"
                    />
                  </div>
                  <div
                    class="col-2 d-flex justify-content-end align-items-center"
                  >
                    <button
                      class="status success pointer position-relative"
                      [ngClass]="{
                        success:
                          kyc?.proofOfAddressStatus?.toLowerCase() ===
                          'successful',
                        danger:
                          kyc?.proofOfAddressStatus?.toLowerCase() === 'failed',
                        warning:
                          kyc?.proofOfAddressStatus?.toLowerCase() ===
                            'pending' || !kyc?.proofOfAddressStatus
                      }"
                      type="button"
                      (click)="selectDropdown($event)"
                    >
                      <p class="mb-0">
                        {{ kyc?.proofOfAddressStatus || "Pending" | titlecase }}
                      </p>
                      <i class="bi bi-chevron-down"></i>
                      <div class="dropdown-options">
                        <div
                          class="dropdown-option"
                          *ngFor="let status of statusList"
                          (click)="changeStatus(status, 'proofOfAddressStatus')"
                        >
                          {{ status | titlecase }}
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col d-flex align-items-center">
                    <p class="mb-0 text-muted">Document Expiration Date</p>
                  </div>
                  <div
                    class="col-5 d-flex justify-content-end align-items-center"
                  >
                    <custom-calendar
                      label="Date"
                      buttonLabel="Apply"
                      class="documentExpInput"
                      [style]="{
                        position: 'relative',
                        height: '33px',
                        width: '100px',
                        border: 'none',
                        background: convertDateToDays(kyc?.documentExpirationDate) ===
                          'Expired' ? '#ef444440' : '#eaf9de',
                        padding: '5px 12px',
                        color:
                          convertDateToDays(kyc?.documentExpirationDate) ===
                          'Expired'
                            ? '#ef4444'
                            : '#22c55e',
                        'border-radius': '8px',
                        'text-align': 'left',
                        cursor: 'pointer'
                      }"
                      [calendarStyle]="{
                        right: 0,
                        left: 'auto'
                      }"
                      [customValue]="true"
                      [value]="convertDateToDays(kyc?.documentExpirationDate)"
                      (emitValue)="setDateValue($event)"
                      (emitDate)="setDate($event)"
                    ></custom-calendar>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="accordion-item mb-4">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button fw-bolder"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                Meta Data
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse show"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="row">
                  <div class="col-6 d-flex align-items-center">
                    <p class="text-muted">SDK Version</p>
                  </div>
                  <div
                    class="col-6 d-flex justify-content-end align-items-center"
                  >
                    <p class="mb-0">135.88.0</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 d-flex align-items-center">
                    <p class="text-muted">OS</p>
                  </div>
                  <div
                    class="col-6 d-flex justify-content-end align-items-center"
                  >
                    <p class="mb-0">Android</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 d-flex align-items-center">
                    <p class="text-muted">Certificate</p>
                  </div>
                  <div
                    class="col-6 d-flex justify-content-end align-items-center"
                  >
                    <a
                      href="#"
                      class="text-decoration-none text-dark fw-500 d-flex align-items-center gap-2"
                    >
                      <img
                        src="assets/icons/Download-icon.svg"
                        alt="Icon"
                        style="rotate: 180deg"
                      />
                      Upload</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div
        class="tab-pane"
        id="uploads"
        role="tabpanel"
        aria-labelledby="uploads-tab"
      >
        <div class="accordion" id="accordionExample">
          <div class="accordion-item mb-4">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button fw-bolder"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Meta Data
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="row">
                  <div class="col-6 d-flex align-items-center">
                    <p class="text-muted">First Name</p>
                  </div>
                  <div
                    class="col-6 d-flex justify-content-end align-items-center"
                  >
                    <p class="fw-500">
                      {{ user?.name?.first }}
                      <img
                        src="assets/icons/paste.svg"
                        class="ms-2 pointer"
                        alt="paste"
                        (click)="copyToClipboard(user?.name?.first)"
                      />
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 d-flex align-items-center">
                    <p class="text-muted">Surname</p>
                  </div>
                  <div
                    class="col-6 d-flex justify-content-end align-items-center"
                  >
                    <p class="fw-500">
                      {{ user?.name?.last }}
                      <img
                        src="assets/icons/paste.svg"
                        class="ms-2 pointer"
                        alt="paste"
                        (click)="copyToClipboard(user?.name?.last)"
                      />
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 d-flex align-items-center">
                    <p class="text-muted">Document Number</p>
                  </div>
                  <div
                    class="col-6 d-flex justify-content-end align-items-center"
                  >
                    <p class="fw-500">
                      NG72378832
                      <img
                        src="assets/icons/paste.svg"
                        class="ms-2"
                        alt="paste"
                      />
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6 d-flex align-items-center">
                    <p class="text-muted">Document Type</p>
                  </div>
                  <div
                    class="col-6 d-flex justify-content-end align-items-center"
                  >
                    <p class="fw-500">
                      {{
                        kyc?.documentType.includes("id")
                          ? "ID Card"
                          : (kyc?.documentType.replace("_", " ") | titlecase)
                      }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 d-flex align-items-center">
                    <p class="text-muted">Country</p>
                  </div>
                  <div
                    class="col-6 d-flex justify-content-end align-items-center"
                  >
                    <p class="fw-500">{{ kyc?.country }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item mb-4">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button fw-bolder"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                {{
                  kyc?.documentType.includes("id")
                    ? "ID Card"
                    : (kyc?.documentType.replace("_", " ") | titlecase)
                }}
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse show"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="picture-container">
                  <img
                    [src]="kyc?.documentFrontUrl"
                    alt="Document Front"
                    class="img-fluid pointer"
                    (click)="
                      viewImage = kyc?.documentFrontUrl; closeModal.click()
                    "
                    data-bs-toggle="modal"
                    [attr.data-bs-target]="modalId2"
                  />
                </div>
                <div
                  class="picture-container"
                  *ngIf="!kyc?.documentType.includes('passport')"
                >
                  <img
                    [src]="kyc?.documentBackUrl"
                    alt="Document Back"
                    class="img-fluid pointer"
                    (click)="
                      viewImage = kyc?.documentBackUrl; closeModal.click()
                    "
                    data-bs-toggle="modal"
                    [attr.data-bs-target]="modalId2"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item mb-4">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button fw-bolder"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                Photo
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse show"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-center">
                <img
                  [src]="kyc?.livelinessReferenceImageUrl"
                  alt="Selfie"
                  class="img-fluid pointer"
                  (click)="
                    viewImage = kyc?.livelinessReferenceImageUrl;
                    closeModal.click()
                  "
                  data-bs-toggle="modal"
                  [attr.data-bs-target]="modalId2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</swap-modal>

<view-image
  [modalId]="modalId2"
  [image]="viewImage"
  [previousModal]="modalId"
></view-image>
