import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'top-up-form',
  templateUrl: './top-up-form.component.html',
  styleUrls: ['./top-up-form.component.scss'],
})
export class TopUpFormComponent {
  @Input() type: string = '';
  @Input() swapUsers: { username: string; id: string }[] = [];
  @Output() submitBulkTopUpForm: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closeBtn') closeBtn: any;

  bulkTopUpForm: FormGroup = new FormGroup({
    users: new FormControl([], Validators.compose([Validators.required])),
    type: new FormControl('', Validators.compose([Validators.required])),
    expiresAt: new FormControl('', Validators.compose([Validators.required])),
    amount: new FormControl('', Validators.compose([Validators.required])),
    allUsers: new FormControl(false, Validators.compose([Validators.required])),
  });

  tokenTypes: string[] = ['Bonus', 'Purchased', 'Subscription'];
  validities: string[] = ['1 Month', '2 Months', '3 Months'];

  constructor() {}

  ngOnChanges() {
    const users = this.bulkTopUpForm.get('users') as FormControl;
    users.patchValue(this.swapUsers);
    if (this.type === 'remove') {
      this.bulkTopUpForm.controls['expiresAt'].clearValidators();
      this.bulkTopUpForm.controls['expiresAt'].updateValueAndValidity();
      this.bulkTopUpForm.controls['type'].clearValidators();
      this.bulkTopUpForm.controls['type'].updateValueAndValidity();
    } else {
      this.bulkTopUpForm.controls['expiresAt'].setValidators([
        Validators.required,
      ]);
      this.bulkTopUpForm.controls['expiresAt'].updateValueAndValidity();
      this.bulkTopUpForm.controls['type'].setValidators([Validators.required]);
      this.bulkTopUpForm.controls['type'].updateValueAndValidity();
    }
  }

  ngOnDestroy() {
    this.bulkTopUpForm.reset();
  }

  removeUser(id: string) {
    this.swapUsers = this.swapUsers.filter((user) => user.id !== id);
    const users = this.bulkTopUpForm.get('users') as FormControl;
    users.patchValue(this.swapUsers);
  }

  onSubmit() {
    this.submitBulkTopUpForm.emit(this.bulkTopUpForm.value);
    this.bulkTopUpForm.reset({
      users: this.swapUsers,
      type: '',
      expiresAt: '',
      amount: '',
      allUsers: false,
    });
    this.closeBtn.nativeElement.click();
  }

  clearCheckboxes() {
    // this.emitBulkTopUp.emit(true);
  }

  selectDropdown(event: any) {
    // close other dropdown options
    event.preventDefault();
    const element = document.querySelectorAll('.dropdown-options');
    element.forEach((el) => {
      if (
        el.classList.contains('show') &&
        el !== event.target.nextElementSibling
      ) {
        el.classList.remove('show');
      }
    });

    // add show class to dropdown options
    event.target.children[0]?.classList.toggle('show');

    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target?.classList.contains('dropdown-option')) {
        element.forEach((el) => {
          if (el.classList.contains('show')) {
            el.classList.remove('show');
          }
        });
      }
    });
  }

  selectItem(value: string, control: string) {
    this.bulkTopUpForm.controls[control].setValue(value);
  }
}
