<div class="row m-3 mx-0">
  <div
    class="col-12 d-flex align-items-center justify-content-between py-3 mb-5"
  >
    <h5 class="fw-700 text-center mb-0">
      {{ type === "upgrade" ? "Upgrade Plan" : "Downgrade Plan" }}
    </h5>
    <button
      type="button"
      class="closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
    >
      <img src="assets/icons/close-icon.svg" alt="" />
    </button>
  </div>
  <div class="col-12 mb-5">
    <form
      class="row gy-4 justify-content-center"
      [formGroup]="planForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="col-12">
        <label for="currentPlan" class="mb-1 fw-700">Current Plan</label>
        <input
          type="text"
          id="currentPlan"
          class="input-border"
          formControlName="currentPlan"
          readonly
        />
      </div>
      <div class="col-12">
        <label for="status" class="mb-1 fw-700">
          {{ type === "upgrade" ? "Upgrade" : "Downgrade" }} To
        </label>
        <div class="input-border dropdown" (click)="selectDropdown($event)">
          {{ planForm.value.newPlan || "Select Plan" }}
          <div class="dropdown-options">
            <div
              class="dropdown-option"
              *ngFor="let plan of plans"
              (click)="setPlan(plan.name)"
            >
              {{ plan.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <label for="status" class="mb-1 fw-700"> Period </label>
        <div class="input-border dropdown" (click)="selectDropdown($event)">
          {{ (planForm.value.period !== 'cancel' && planForm.value.period | titlecase) || "Select Period" }}
          <div class="dropdown-options">
            <div
              class="dropdown-option"
              *ngFor="let period of periods"
              (click)="setPeriod(period)"
            >
              {{ period | titlecase }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 text-end">
        <button class="swapBtn" (click)="closeBtn.click()" type="button">
          Cancel
        </button>
        <button
          class="swapBtn update ms-2"
          type="submit"
          data-bs-dismiss="modal"
          aria-label="Close"
          [disabled]="planForm.invalid"
        >
          Proceed
        </button>
      </div>
    </form>
  </div>
</div>
