import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalsService } from 'src/app/services/core/globals';
import Validation from 'src/app/services/shared/validation';

@Component({
  selector: 'change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss'],
})
export class ChangePasswordFormComponent {
  @Output() emitForm: EventEmitter<any> = new EventEmitter<any>();

  token: string = this.globals.router.url.split('/')[3] || '';

  changePasswordForm: FormGroup = new FormGroup(
    {
      token: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
        ),
      ]),
      confirmPassword: new FormControl('', [Validators.required]),
    },
    {
      validators: Validation.match('password', 'confirmPassword'),
    }
  );

  constructor(private globals: GlobalsService) {
    if (this.token)
      this.changePasswordForm.patchValue({
        token: this.token,
      });
  }

  ngOnInit() {}

  onSubmit() {
    this.emitForm.emit(this.changePasswordForm.value);
  }

  get password() {
    return this.changePasswordForm.get('password');
  }

  get confirmPassword() {
    return this.changePasswordForm.get('confirmPassword');
  }
}
