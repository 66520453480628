import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Plan } from 'src/app/services/core/IApp';

@Component({
  selector: 'plan-form',
  templateUrl: './plan-form.component.html',
  styleUrls: ['./plan-form.component.scss'],
})
export class PlanFormComponent {
  @Input() plan: Plan | null = null;

  @Output() onSubmit: EventEmitter<Plan> = new EventEmitter<Plan>();

  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();

  @Output() onChanges: EventEmitter<Plan> = new EventEmitter<Plan>();

  planForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    user_type: new FormControl('', Validators.required),
    cta: new FormControl('', Validators.required),
    price_per_month: new FormControl('', Validators.required),
    price_per_year: new FormControl('', Validators.required),
    user: new FormControl([], Validators.required),
    offers: new FormControl([], Validators.required),
    support: new FormControl([], Validators.required),
    stripe_price_monthly_id: new FormControl('', Validators.required),
    stripe_price_yearly_id: new FormControl('', Validators.required),
  });

  constructor() {}

  ngOnChanges() {
    if (this.plan && this.planForm.invalid) {
      this.planForm.patchValue({
        ...this.plan,
        user: [...(this.plan.privileges.user || [])],
        offers: [...(this.plan.privileges.offers || [])],
        support: [...(this.plan.privileges.support || [])],
        stripe_price_monthly_id: this.plan.stripe_prices?.monthly_id,
        stripe_price_yearly_id: this.plan.stripe_prices?.yearly_id,
      });
    }
  }

  submit() {
    this.onSubmit.emit(this.planForm.value);
  }

  addNewOffer() {
    const offers = this.planForm.get('offers') as FormControl;
    offers.setValue([...offers.value, { name: '', value: '' }]);
  }

  updateOfferName(event: any, index: number) {
    const offers = this.planForm.get('offers') as FormControl;
    const newOffers = [...offers.value];
    newOffers[index].name = event.target.value;
    offers.setValue(newOffers);
    this.onChanges.emit(this.planForm.value);
  }

  deleteOffer(index: number) {
    const offers = this.planForm.get('offers') as FormControl;
    const newOffers = [...offers.value];
    newOffers.splice(index, 1);
    offers.setValue(newOffers);
    this.onChanges.emit(this.planForm.value);
  }

  updateOfferValue(event: any, index: number) {
    const offers = this.planForm.get('offers') as FormControl;
    const newOffers = [...offers.value];
    newOffers[index].value = event.target.value;
    offers.setValue(newOffers);
    this.onChanges.emit(this.planForm.value);
  }

  addNewSupport() {
    const support = this.planForm.get('support') as FormControl;
    support.setValue([...support.value, { name: '', value: false }]);
  }

  updateSupportName(event: any, index: number) {
    const support = this.planForm.get('support') as FormControl;
    const newSupport = [...support.value];
    newSupport[index].name = event.target.value;
    support.setValue(newSupport);
    this.onChanges.emit(this.planForm.value);
  }

  deleteSupport(index: number) {
    const support = this.planForm.get('support') as FormControl;
    const newSupport = [...support.value];
    newSupport.splice(index, 1);
    support.setValue(newSupport);
    this.onChanges.emit(this.planForm.value);
  }

  updateSupportActive(event: any, index: number) {
    const support = this.planForm.get('support') as FormControl;
    const newSupport = [...support.value];
    newSupport[index].value = Boolean(event.target.checked);
    support.setValue(newSupport);
    this.onChanges.emit(this.planForm.value);
  }

  addNewBadgeEligibility() {
    const badge_eligibility = this.planForm.get('user') as FormControl;
    badge_eligibility.setValue([
      ...badge_eligibility.value,
      { name: '', value: false },
    ]);
  }

  updateBadgeEligibilityName(event: any, index: number) {
    const badge_eligibility = this.planForm.get('user') as FormControl;
    const newBadgeEligibility = [...badge_eligibility.value];
    newBadgeEligibility[index].name = event.target.value;
    badge_eligibility.setValue(newBadgeEligibility);
    this.onChanges.emit(this.planForm.value);
  }

  deleteBadgeEligibility(index: number) {
    const badge_eligibility = this.planForm.get('user') as FormControl;
    const newBadgeEligibility = [...badge_eligibility.value];
    newBadgeEligibility.splice(index, 1);
    badge_eligibility.setValue(newBadgeEligibility);
    this.onChanges.emit(this.planForm.value);
  }

  updateBadgeEligibilityActive(event: any, index: number) {
    const badge_eligibility = this.planForm.get('user') as FormControl;
    const newBadgeEligibility = [...badge_eligibility.value];
    newBadgeEligibility[index].value = Boolean(event.target.checked);
    badge_eligibility.setValue(newBadgeEligibility);
    this.onChanges.emit(this.planForm.value);
  }

  selectDropdown(event: any) {
    // close other dropdown options
    event.preventDefault();
    const element = document.querySelectorAll('.dropdown-options');
    element.forEach((el) => {
      if (
        el.classList.contains('show') &&
        el !== event.target.nextElementSibling
      ) {
        el.classList.remove('show');
      }
    });

    // add show class to dropdown options
    event.target.children[0]?.classList.toggle('show');

    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target?.classList.contains('dropdown-option')) {
        element.forEach((el) => {
          if (el.classList.contains('show')) {
            el.classList.remove('show');
          }
        });
      }
    });
  }
}
