<div class="row m-3">
  <div class="col-12 d-flex justify-content-end mb-5">
    <button
      type="button"
      class="closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
    >
      <img src="assets/icons/close-icon-pink.svg" alt="" />
    </button>
  </div>
  <form class="col-12 mb-5" [formGroup]="addBadgeForm" (ngSubmit)="onSubmit()">
    <h5 class="fw-700 mb-2">Add Badge</h5>
    <p class="mb-0 text-muted mb-5">Choose from the Badges below</p>
    <div class="row g-0 gx-2 mb-3">
      <div class="col-4 form-group" *ngFor="let badge of badges">
        <input
          type="radio"
          name="badge"
          [value]="badge.name"
          [id]="badge.name"
          formControlName="badge"
        />
        <label [for]="badge.name" class="mb-1">
          <svg
            width="41"
            height="40"
            viewBox="0 0 41 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            [innerHTML]="badge.path"
          ></svg>
          <p class="font-14 mb-0 mt-2">{{ badge.name }}</p>
        </label>
      </div>

    </div>
    <div class="col-12 text-end">
      <button class="swapBtn" (click)="closeBtn.click()" type="button">
        Cancel
      </button>
      <button class="swapBtn update ms-2" type="submit" data-bs-dismiss="modal">
        Update
      </button>
    </div>
  </form>
</div>
