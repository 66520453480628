import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Ticket } from 'src/app/services/core/IApp';

@Component({
  selector: 'merge-ticket-modal',
  templateUrl: './merge-ticket-modal.component.html',
  styleUrls: ['./merge-ticket-modal.component.scss'],
})
export class MergeTicketModalComponent {
  @Input() modalId: string = '';
  @Input() ticket: Ticket | undefined;
  @Output() mergeTicketEvent: EventEmitter<string> = new EventEmitter<string>();
  @Input() ticketId: string | null = null;
  mergeTicketForm: FormGroup = new FormGroup({
    linkedTicketId: new FormControl('', Validators.required),
  });

  constructor() {}

  mergeTicket() {
    if (this.mergeTicketForm.valid) {
      this.mergeTicketEvent.emit(this.mergeTicketForm.value.linkedTicketId);
    }
  }

  get linkedTicketId() {
    return this.mergeTicketForm.get('linkedTicketId') as FormControl;
  }
}
