import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/services/core/IApp';

@Component({
  selector: 'admin-management-form',
  templateUrl: './admin-management-form.component.html',
  styleUrls: ['./admin-management-form.component.scss'],
})
export class AdminManagementFormComponent {
  @Output() emitProfileData: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitDeleteUser: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitResetUserPassword: EventEmitter<any> = new EventEmitter<any>();

  personalUserDetailsForm: FormGroup = new FormGroup({
    _id: new FormControl(''),
    firstName: new FormControl('', Validators.compose([Validators.required])),
    lastName: new FormControl('', Validators.compose([Validators.required])),
    email: new FormControl(
      '',
      Validators.compose([Validators.required, Validators.email])
    ),
    role: new FormControl('', Validators.compose([Validators.required])),
  });

  @Input() user: User | undefined;

  @Input() loggedInUser: User | undefined;

  showPassword: boolean = false;

  roles: Array<string> = [
    'Super Admin',
    'Admin',
    'Technical Support',
    'Customer Support',
  ];

  ngOnChanges() {
    if (this.user) {
      this.personalUserDetailsForm.patchValue({
        ...this.user,
        firstName: this.user.name.first,
        lastName: this.user.name.last,
      });
    }

    if (
      this.loggedInUser?.role.toLocaleLowerCase().replace(' ', '') === 'admin'
    ) {
      this.roles = this.roles.filter((role) => role !== 'Super Admin');
    }
  }

  ngOnDestroy() {
    this.personalUserDetailsForm.reset();
  }

  submitProfile() {
    this.emitProfileData.emit(this.personalUserDetailsForm.value);
    this.personalUserDetailsForm.reset();
  }

  deleteUser() {
    this.emitDeleteUser.emit(this.personalUserDetailsForm.value);
    this.personalUserDetailsForm.reset();
  }

  resetPassword() {
    this.emitResetUserPassword.emit(this.personalUserDetailsForm.value.email);
    this.personalUserDetailsForm.reset();
  }

  selectDropdown(event: any) {
    // close other dropdown options
    event.preventDefault();
    const element = document.querySelectorAll('.dropdown-options');
    element.forEach((el) => {
      if (
        el.classList.contains('show') &&
        el !== event.target.nextElementSibling
      ) {
        el.classList.remove('show');
      }
    });

    // add show class to dropdown options
    event.target.children[0]?.classList.toggle('show');

    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target?.classList.contains('dropdown-option')) {
        element.forEach((el) => {
          if (el.classList.contains('show')) {
            el.classList.remove('show');
          }
        });
      }
    });
  }

  setRoles(roles: string) {
    this.personalUserDetailsForm.patchValue({
      role: roles,
    });
  }

  get f() {
    return this.personalUserDetailsForm.controls;
  }
}
