<div class="row m-3">
  <div class="col-12 d-flex justify-content-end mb-5">
    <button
      (click)="clearCheckboxes()"
      type="button"
      class="closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
    >
      <img src="assets/icons/close-icon-pink.svg" alt="" />
    </button>
  </div>
  <div class="col-12 mb-5">
    <h5 class="fw-700 text-center mb-4">Bulk {{ type }}</h5>
    <form
      class="row gy-4 justify-content-center"
      [formGroup]="bulkUpgradeForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="col-11">
        <div for="bulkUsers" class="mb-1 fw-700">Users</div>

        <div class="usersContainer">
          <span *ngFor="let user of swapUsers | slice : 0 : 5">
            <label for="{{ user }}" placeholder="Enter amount" class="user"
              >@{{ user }}
              <!-- X icon -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                (click)="removeUser(user)"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </label>

            <input
              [ngStyle]="{
                display: 'none'
              }"
              type="checkbox"
              value="{{ user }}"
              id="{{ user }}"
              class="input-border"
              formControlName="users"
            />
          </span>
          <span *ngIf="swapUsers.length > 5" class="moreUsers">
            <!-- Plus Icon -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
              />
            </svg>
            {{ swapUsers.length - 5 }} more
          </span>
        </div>
      </div>
      <div class="col-11">
        <label for="bulkTokens" class="mb-1 fw-700">Tier</label>
        <div class="input-border dropdown" (click)="selectDropdown($event)">
          {{ bulkUpgradeForm.value.tier || "Select Tier Level" }}
          <div class="dropdown-options">
            <div
              class="dropdown-option"
              *ngFor="let tier of tierLevels"
              (click)="setTierLevel(tier)"
            >
              {{ tier }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-11 text-end">
        <button class="swapBtn" (click)="closeBtn.click()" type="button">
          Cancel
        </button>
        <button class="swapBtn update ms-2" type="submit">Upgrade</button>
      </div>
    </form>
  </div>
</div>
