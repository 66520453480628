import { Component, Input } from '@angular/core';
import { Ratings_Reviews } from 'src/app/services/core/IApp';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'view-review',
  templateUrl: './view-review.component.html',
  styleUrls: ['./view-review.component.scss'],
})
export class ViewReviewComponent {
  @Input() review: Ratings_Reviews | any;
  @Input() modalId: string = '';
  @Input() utc_offset: string = '';

  constructor(public globals: GlobalsService) {}
}
