<div
  class="search"
  [ngStyle]="{
    'background-color': backgroundColor,
    'border-color': borderColor,
    padding
  }"
  [style]="style"
>
  <label for="search">
    <img class="search-icon" src="assets/images/Search.svg" for="search" />
  </label>
  <input
    class="search-input"
    type="text"
    [placeholder]="placeholder"
    id="{{ id }}"
    name="search"
    [value]="value"
    (input)="emitSearch($event)"
  />
</div>
