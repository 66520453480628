import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthService } from './api/auth/auth.service';
import { HelpHistoryService } from './api/help-history/help-history.service';
import { PlansService } from './api/plan/plan.service';
import { TokensService } from './api/tokens/tokens.service';
import { TransactionsService } from './api/transaction/transactions.service';
import { UsersService } from './api/user/users.service';
import { GlobalsService } from './core/globals';
import { RequestService } from './core/request';
import { StorageService } from './core/storage';

const providers: Array<any> = [
  GlobalsService,
  AuthService,
  RequestService,
  StorageService,
  UsersService,
  TransactionsService,
  HelpHistoryService,
  PlansService,
  TokensService,
];

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers,
})
export class ServicesModule {}
