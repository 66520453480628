import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Editor, Toolbar } from 'ngx-editor';
import { EmailToUser, Swap_User } from 'src/app/services/core/IApp';

@Component({
  selector: 'email-contact-form',
  templateUrl: './email-contact-form.component.html',
  styleUrls: ['./email-contact-form.component.scss'],
})
export class EmailContactFormComponent {
  @Input() swapUser: Swap_User | undefined;

  @Output() emitEmail: EventEmitter<EmailToUser> =
    new EventEmitter<EmailToUser>();

  @ViewChild('closeBtn') closeBtn: ElementRef | undefined;

  emailForm: FormGroup = new FormGroup({
    to: new FormControl(
      '',
      Validators.compose([Validators.required, Validators.email])
    ),
    subject: new FormControl('', Validators.compose([Validators.required])),
    category: new FormControl('', Validators.compose([Validators.required])),
    content: new FormControl('', Validators.compose([Validators.required])),
  });

  categories = [
    'App Security',
    'Transfer Delay',
    'Onboarding Issues',
    'Token Query',
    'Payment Upload',
    'Uncredited Token',
  ];

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  editor: Editor = new Editor();

  constructor() {}

  ngOnInit(): void {
    this.editor = new Editor();
  }

  ngOnChanges() {
    if (this.swapUser) {
      this.emailForm.controls['to'].setValue(this.swapUser.email);
    }
  }

  ngOnDestroy() {
    this.emailForm.reset({
      to: '',
      subject: '',
      category: '',
      content: '',
    });
    this.editor.destroy();
  }

  onSubmit() {
    if (this.emailForm.invalid) return;
    this.emitEmail.emit(this.emailForm.value as EmailToUser);
    this.emailForm.reset();
    this.swapUser = undefined;
    this.closeBtn?.nativeElement.click();
  }

  deleteReceipient() {
    this.emailForm.controls['to'].setValue(null);
    this.swapUser = undefined;
  }

  selectDropdown(event: any) {
    // close other dropdown options
    event.preventDefault();
    const element = document.querySelectorAll('.dropdown-options');
    element.forEach((el) => {
      if (
        el.classList.contains('show') &&
        el !== event.target.nextElementSibling
      ) {
        el.classList.remove('show');
      }
    });

    // add show class to dropdown options
    event.target.children[0]?.classList.toggle('show');

    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target?.classList.contains('dropdown-option')) {
        element.forEach((el) => {
          if (el.classList.contains('show')) {
            el.classList.remove('show');
          }
        });
      }
    });
  }

  setCategory(category: string) {
    this.emailForm.controls['category'].setValue(category);
  }
}
