<div class="row m-3 mx-0">
  <div class="col-12 d-flex justify-content-between py-3 mb-5 border-bottom">
    <h5 class="fw-700 text-center mb-0">
      {{ type !== "deduct" ? "Top Up All" : "Token Deduct" }}
    </h5>
    <button
      (click)="clearCheckboxes()"
      type="button"
      class="closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
    >
      <img src="assets/icons/close-icon.svg" alt="" />
    </button>
  </div>
  <div class="col-12 mb-5">
    <form
      class="row gy-4 justify-content-center"
      [formGroup]="topUpAllForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="col-12">
        <label class="mb-1 fw-700">Users ({{ swapUsersCount }})</label>
        <div class="usersContainer">
          <label for="allUsers" class="pill"> All Users </label>
          <input
            [ngStyle]="{
              display: 'none'
            }"
            type="text"
            value="{{ swapUsersCount }}"
            id="allUsers"
          />
        </div>
      </div>
      <div class="col-12">
        <label for="category" class="mb-1 fw-700">Token Type</label>
        <div
          class="input-border dropdown"
          (click)="selectDropdown($event)"
          [ngClass]="{ 'text-muted': !topUpAllForm.value.token_type }"
        >
          {{ topUpAllForm.value.token_type || "Select Token Type" }}
          <div class="dropdown-options">
            <div
              class="dropdown-option"
              *ngFor="let type of tokenTypes"
              (click)="selectItem(type, 'token_type')"
            >
              {{ type }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <label for="category" class="mb-1 fw-700">Validity</label>
        <div
          class="input-border dropdown"
          (click)="selectDropdown($event)"
          [ngClass]="{ 'text-muted': !topUpAllForm.value.validity }"
        >
          {{ topUpAllForm.value.validity || "Select Validity" }}
          <div class="dropdown-options">
            <div
              class="dropdown-option"
              *ngFor="let val of validities"
              (click)="selectItem(val, 'validity')"
            >
              {{ val }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <label class="mb-1 fw-700">Status</label>
        <div
          class="input-border dropdown"
          (click)="selectDropdown($event)"
          [ngClass]="{ 'text-muted': !topUpAllForm.value.status }"
        >
          {{ topUpAllForm.value.status || "Select Status" }}
          <div class="dropdown-options">
            <div
              class="dropdown-option"
              *ngFor="let stat of statuses"
              (click)="selectItem(stat, 'status')"
            >
              {{ stat }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <label class="mb-1 fw-700">Date</label>
        <div class="d-flex align-items-center gap-3">
          <custom-calendar
            label="Date"
            buttonLabel="Apply"
            [style]="{
              width: '100%',
              'text-align': 'center'
            }"
            class="text-secondary"
            [value]="topUpAllForm.value.start_date || ''"
            (emitValue)="setDateValue($event, 'start')"
            (emitDate)="setDate($event, 'start')"
            placeholder="From"
          ></custom-calendar>

          <label class="mb-1 fw-700">-</label>
          <custom-calendar
            label="Date"
            buttonLabel="Apply"
            (emitValue)="setDateValue($event, 'end')"
            (emitDate)="setDate($event, 'end')"
            [style]="{
              width: '100%',
              'text-align': 'center'
            }"
            class="text-secondary"
            [value]="topUpAllForm.value.end_date || ''"
            placeholder="To"
            calendarStyle="right: 0; left: auto;"
          ></custom-calendar>
        </div>
      </div>

      <div class="col-12">
        <label for="amount" class="mb-1 fw-700">Amount (EXs)</label>
        <input
          placeholder="Enter amount"
          type="number"
          id="amount"
          class="input-border"
          formControlName="amount"
        />
      </div>
      <div class="col-12 text-end">
        <button class="swapBtn" (click)="closeBtn.click()" type="button">
          Cancel
        </button>
        <button class="swapBtn update ms-2" type="submit">Send</button>
      </div>
    </form>
  </div>
</div>
