import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Token } from 'src/app/services/core/IApp';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'token-history',
  templateUrl: './token-history.component.html',
  styleUrls: ['./token-history.component.scss'],
})
export class TokenHistoryComponent {
  @Input() modalId: string = '';

  @Input() currentTab: string = 'spent';

  @Input() utc_offset: string = '';

  @Input() tokenHistory: any[] = [];

  @Output() currentTabChange: EventEmitter<string> = new EventEmitter<string>();

  tabs: string[] = ['spent', 'earned', 'purchased'];

  constructor(public globals: GlobalsService) {}

  ngOnChanges() {
    this.tokenHistory = this.groupItemsByDate(this.tokenHistory);
  }

  groupItemsByDate(items: any[]) {
    const groupedItems: Token | any = {};

    items.forEach((item) => {
      // Extract the date part (ignoring the time)
      const date = new Date(item.createdAt).toISOString().split('T')[0];

      if (!groupedItems[date]) {
        groupedItems[date] = [];
      }

      groupedItems[date].push(item);
    });

    const result = [];

    for (const date in groupedItems) {
      if (groupedItems.hasOwnProperty(date)) {
        result.push({
          date: date,
          tokens: groupedItems[date],
        });
      }
    }

    return result;
  }
}
