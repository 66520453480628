import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  @Input() modalId: string = '';

  @Input() filters: any = {};

  @Output() onFilter: EventEmitter<any> = new EventEmitter();

  @Output() onReset: EventEmitter<any> = new EventEmitter();

  filterForm: FormGroup = new FormGroup({
    minRate: new FormControl(''),
    maxRate: new FormControl(''),
    dateFrom: new FormControl(''),
    dateTo: new FormControl(''),
    paymentMethod: new FormControl(''),
    ratings: new FormControl(''),
    verificationStatus: new FormControl(''),
    country: new FormControl(''),
    online: new FormControl(''),
  });

  paymentOptions: Array<string> = [
    'Bank Transfer',
    'Cash',
    'CashApp',
    'Crypto',
    'Interac e-transfer',
    'Lloyds Bank',
    'M-PESA',
    'Momo',
    'Monzo',
    'N26',
    'Payoneer',
    'Perfect Money',
    'Revolut',
    'Skrill',
    'Starling Bank',
    'Wire Transfer (SWIFT)',
    'Wise',
    'Zelle',
    'Other',
  ];
  verificationOptions: Array<string> = ['Tier 1', 'Tier 2', 'Tier 3'];
  ratingOptions: Array<string> = [
    '0 star',
    '1 star',
    '2 star',
    '3 star',
    '4 star',
    '5 star',
  ];
  onlineOptions: Array<string> = ['Online', 'Offline'];

  constructor(public globals: GlobalsService) {}

  async ngOnInit() {}

  async ngOnChanges() {
    this.filterForm.patchValue(this.filters);
  }

  onSubmit() {
    this.onFilter.emit({
      ...this.filters,
      ...this.filterForm.value,
    });
  }

  async setDate(event: string, type: string) {
    switch (type) {
      case 'start':
        this.filterForm.controls['dateFrom'].setValue(event);
        break;
      case 'end':
        this.filterForm.controls['dateTo'].setValue(event);
        break;
      default:
        break;
    }
  }

  setDateValue(event: string, type: string) {
    switch (type) {
      case 'start':
        this.filterForm.controls['dateFrom'].setValue(event);
        break;
      case 'end':
        this.filterForm.controls['dateTo'].setValue(event);
        break;
      default:
        break;
    }
  }

  toggleSelectDropdown(event: any) {
    // close other dropdown options
    event.preventDefault();
    const element = document.querySelectorAll('.select-dropdown');
    element.forEach((el) => {
      if (
        el.classList.contains('show') &&
        el !== event.target.nextElementSibling
      ) {
        el.classList.remove('show');
      }
    });

    // add show class to dropdown options
    event.target.children[1]?.classList.toggle('show');

    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target?.classList.contains('select-dropdown-option')) {
        element.forEach((el) => {
          if (el.children[1]?.classList.contains('show')) {
            el.children[1]?.classList.remove('show');
          }
        });
      }
    });
  }

  async setFilterData(event: any, value: string, type: string) {
    switch (type) {
      case 'payment_method':
        this.filterForm.controls['paymentMethod'].setValue(value);
        break;
      case 'rating':
        this.filterForm.controls['ratings'].setValue(
          value == '0 star' ? '' : value.split(' ')[0]
        );
        break;
      case 'verification_status':
        this.filterForm.controls['verificationStatus'].setValue(value);
        break;
      case 'location':
        this.filterForm.controls['country'].setValue(value);
        break;
      case 'online_status':
        this.filterForm.controls['online'].setValue(
          value == 'Online' ? true : false
        );
        break;
      default:
        break;
    }

    // target parent and remove show class
    const parent = event.target.parentElement;
    parent.classList.remove('show');
  }

  checkIfEmpty() {
    return !Object.values(this.filterForm.value).some((val) => !!val);
  }

  resetForm() {
    this.filterForm.reset();
    this.onReset.emit();
  }
}
