<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header border-0 mx-3">
    <h2 class="modal-title fs-5 fw-bolder" id="staticBackdropLabel">
      {{ offer ? "Edit Offer" : "Create Offer" }}
    </h2>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="resetForm()"
      #closeModal
    ></button>
  </div>
  <div class="modal-body mx-3">
    <form
      [formGroup]="createOfferForm"
      class="row g-3 justify-content-between"
      (ngSubmit)="onSubmit(createOfferForm.value)"
    >
      <div class="col-5">
        <div class="fw-700 mb-2 font-14">
          I Have <span class="text-danger">*</span>
        </div>
        <div
          class="input-shape px-3 d-flex align-items-center justity-content-between"
        >
          <div
            class="dropdown-currency"
            (click)="selectCurrencyFilter()"
            id="currency-select-3"
          >
            <div class="dropdown-currency-input" id="dropdown-input-3">
              <img
                loading="lazy"
                [src]="iHave?.flag"
                alt=""
                [hidden]="iHave?.flag === ''"
              />
              <input
                type="text"
                readonly
                placeholder="Select a currency"
                name="dropdown"
                [value]="iHave?.currency"
              />
              <input
                type="text"
                placeholder="Type..."
                id="haveSearchBox"
                [(ngModel)]="searchCurrency"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
            <div class="option">
              <ng-container
                *ngFor="
                  let currency of countries | countryFilter : searchCurrency
                "
              >
                <div
                  class="option-item"
                  (click)="selectCurrency('have', currency)"
                >
                  <img
                    loading="lazy"
                    [src]="currency?.flag"
                    alt="Currency Flag"
                  />
                  <span>
                    {{
                      (currency?.iso2 | uppercase) + " - " + currency?.name
                    }}</span
                  >
                  <small>({{ currency?.currency | uppercase }})</small>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2 d-flex align-items-end justify-content-center">
        <img
          loading="lazy"
          class="pointer img-fluid"
          width="100%"
          height="100%"
          src="assets/images/round-swap-icon2.svg"
          (click)="swapCurrencies()"
        />
      </div>
      <div class="col-5">
        <div class="color-header fw-700 mb-2 font-14">
          I Want <span class="text-danger">*</span>
        </div>
        <div
          class="input-shape px-3 d-flex justify-content-between align-items-center"
        >
          <div
            class="dropdown-currency"
            id="currency-select-4"
            (click)="selectCurrencyFilter2()"
          >
            <div class="dropdown-currency-input" id="dropdown-input-4">
              <img
                loading="lazy"
                [src]="iWant.flag"
                alt=""
                [hidden]="iWant.flag === ''"
              />
              <input
                type="text"
                readonly
                placeholder="Select a currency"
                name="dropdown"
                [value]="iWant.currency"
              />
              <input
                type="text"
                placeholder="Type..."
                id="wantSearchBox"
                [(ngModel)]="searchCurrency"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
            <div class="option-2">
              <ng-container
                *ngFor="
                  let currency of countries | countryFilter : searchCurrency
                "
              >
                <div
                  class="option-item"
                  (click)="selectCurrency('want', currency)"
                >
                  <img
                    loading="lazy"
                    [src]="currency?.flag"
                    alt="Currency Flag"
                  />
                  <span>
                    {{
                      (currency?.iso2 | uppercase) + " - " + currency?.name
                    }}</span
                  >
                  <small>({{ currency?.currency | uppercase }})</small>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="fw-700 mb-2 font-14">
          Amount Available <span class="text-danger">*</span>
        </div>
        <div class="input-shape d-flex align-items-center p-3">
          <input
            formControlName="amount"
            class="font-14 color-text fw-700 me-2 amount-input"
            type="number"
            name="amount"
            (keyup)="setMinimumAmount($event)"
            placeholder="0.00"
          />
          <div class="d-flex align-items-center gap-2">
            <img
              loading="lazy"
              [src]="iHave?.flag"
              width="20px"
              [hidden]="iHave?.flag === ''"
            />
            <p class="mb-0 color-text">
              {{ iHave?.currency }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <p class="mb-0 badge">
          {{ "1 " + iHave?.currency }} =
          {{ convertedRate + " " + iWant?.currency }}
        </p>
        <p class="color-text m-0 font-12">Generic market rate</p>
      </div>
      <div class="col-4">
        <div class="color-header fw-700 mb-2 font-14">
          My Rate <span class="text-danger">*</span>
        </div>
        <div class="input-shape p-3">
          <input
            formControlName="rate"
            class="font-14 w-100 color-text fw-500"
            type="number"
            name="rate"
            placeholder="0.00"
          />
        </div>
      </div>
      <div class="col-8">
        <div class="color-header fw-700 mb-2 font-14">I want</div>
        <div
          class="input-shape p-3 d-flex align-items-center"
          [ngClass]="{ readOnly: true }"
        >
          <input
            [readonly]="true"
            [ngClass]="{ readOnly: true }"
            class="font-14 color-text fw-700 amount-input"
            type="number"
            value="{{
              createOfferForm.value.amount * createOfferForm.value.rate
            }}"
            placeholder="0.00"
          />
          <div class="d-flex align-items-center gap-2">
            <img
              loading="lazy"
              [src]="iWant?.flag"
              width="20px"
              [hidden]="iHave?.flag === ''"
            />
            <p class="mb-0 color-text">
              {{ iWant.currency }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="color-header fw-700 mb-2 font-14">Minimum Limit</div>
        <div class="input-shape p-3">
          <input
            formControlName="minAmount"
            class="font-14 w-100 color-text fw-700"
            type="number"
            name="minLimit"
            id=""
            placeholder="0.00"
          />
        </div>
        <div
          *ngIf="
            createOfferForm.controls['minAmount'].invalid &&
            createOfferForm.controls['minAmount'].touched
          "
          class="text-danger"
        >
          <div
            class="fw-500 font-12 mb-2"
            *ngIf="
                      createOfferForm.controls['minAmount'].errors?.['required'] ||
                      createOfferForm.controls['minAmount'].errors?.['min'] ||
                      createOfferForm.controls['minAmount'].errors?.['max']
                    "
          >
            maximum of {{ maxAmount }}.
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="color-header fw-700 mb-2 font-14">
          Preferred Countries
          <span class="text-muted fw-500"
            >({{ createOfferForm.value.preferredCountries?.length }}/3)</span
          >
        </div>
        <ng-multiselect-dropdown
          class="font-14 fw-500"
          [placeholder]="'choose country'"
          [settings]="dropdownSettings"
          [data]="countries"
          formControlName="preferredCountries"
        >
        </ng-multiselect-dropdown>
      </div>
      <div class="col-12">
        <div class="color-header fw-700 mb-2 font-14">
          Purpose of Transaction
        </div>
        <div class="input-shape p-3">
          <div
            class="d-flex justify-content-between align-items-center font-14 color-text fw-500"
          >
            <div
              class="dropdown-purpose"
              (click)="activeDropdown('transactionPurpose')"
              id="transactionPurpose"
            >
              <span>{{
                createOfferForm.value.transactionPurpose
                  ? createOfferForm.value.transactionPurpose
                  : "Choose"
              }}</span>

              <div
                class="w-100"
                class="dropdown-purpose-options"
                aria-label="select example"
              >
                <div
                  *ngFor="let purpose of transactionPurposeList"
                  class="dropdown-item"
                  (click)="selectOption('transactionPurpose', purpose)"
                >
                  {{ purpose }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="color-header fw-700 mb-0 font-14">Payment method</p>
      <div class="col-6">
        <div class="d-flex align-items-center mb-2 gap-2">
          <div class="color-purple-light fw-500 font-14 mr-6">
            Send via<span class="text-danger">*</span>
          </div>
          <img
            loading="lazy"
            src="assets/icons/exclaim-pink.svg"
            alt="exclaim"
            [ngbTooltip]="sendFrom"
            tooltipClass="white-tooltip"
            class="position-relative bottom-4"
          />
        </div>
        <div class="input-shape p-3">
          <div
            class="dropdown-purpose"
            id="paymentSendFrom"
            (click)="activeDropdown('paymentSendFrom')"
          >
            <p class="font-14 mb-0 truncate" style="width: 7em">
              {{
                createOfferForm.value.paymentSendFrom
                  ? createOfferForm.value.paymentSendFrom
                  : "Choose Method"
              }}
            </p>

            <div
              class="font-14 w-wk fw-500 dropdown-purpose-options"
              aria-label="select example"
            >
              <div
                *ngFor="let sendMethod of paymentList"
                class="dropdown-item"
                (click)="selectOption('paymentSendFrom', sendMethod)"
              >
                {{ sendMethod }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex align-items-center mb-2 gap-2">
          <div class="color-purple-light fw-500 font-14 mr-6">
            Receive via<span class="text-danger">*</span>
          </div>
          <img
            loading="lazy"
            src="assets/icons/exclaim-pink.svg"
            alt="exclaim"
            [ngbTooltip]="receiveThrough"
            tooltipClass="white-tooltip"
            class="position-relative bottom-4"
          />
        </div>
        <div class="input-shape p-3">
          <div
            class="dropdown-purpose"
            id="paymentReceiveThrough"
            (click)="activeDropdown('paymentReceiveThrough')"
          >
            <p class="font-14 mb-0 truncate" style="width: 7em">
              {{
                createOfferForm.value.paymentReceiveThrough
                  ? createOfferForm.value.paymentReceiveThrough
                  : "Choose Method"
              }}
            </p>

            <div
              class="font-14 w-wk fw-500 dropdown-purpose-options"
              aria-label="select example"
            >
              <div
                *ngFor="let receiveMethod of paymentList"
                class="dropdown-item"
                (click)="selectOption('paymentReceiveThrough', receiveMethod)"
              >
                {{ receiveMethod }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="color-header fw-700 mb-2 font-14">Verification status</div>
        <div class="input-shape p-3">
          <div
            class="dropdown-purpose"
            id="verificationStatus"
            (click)="activeDropdown('verificationStatus')"
          >
            <span class="font-14">{{
              createOfferForm.value.verificationStatus
                ? (createOfferForm.value.verificationStatus?.name +
                    " " +
                    createOfferForm.value.verificationStatus?.rank | titlecase)
                : "Choose Status"
            }}</span>
            <div
              class="font-14 w-wk fw-500 dropdown-purpose-options"
              aria-label="select example"
            >
              <div
                *ngFor="let status of verificationStatus"
                class="dropdown-item"
                (click)="selectOption('verificationStatus', status)"
              >
                {{ status.name + " " + status.rank | titlecase }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="color-header fw-700 mb-2 font-14">Rating</div>
        <div class="input-shape p-3">
          <div
            class="dropdown-purpose"
            id="rating"
            (click)="activeDropdown('rating')"
          >
            <span class="font-14">
              {{
                createOfferForm.value.rating
                  ? "*".repeat(createOfferForm.value.rating)
                  : "Any Rating"
              }}
            </span>
            <div
              class="font-14 w-wk fw-500 dropdown-purpose-options"
              aria-label="select example"
            >
              <div
                *ngFor="let rating of rateType"
                class="dropdown-item"
                (click)="selectOption('rating', rating)"
              >
                {{ rating }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <button
          type="submit"
          class="createBtn"
          [disabled]="createOfferForm.invalid"
        >
          {{ offer ? "Edit" : "Create" }}
        </button>
      </div>
    </form>
  </div>
</swap-modal>

<ng-template #sendFrom>
  <div class="text-left font-12 p-2">
    <div class="color-pink fw-700 mb-2">Send via</div>
    <div class="color-text fw-400">
      Choose this payment method for how you would like to send your “I have
      currency”.
    </div>
  </div>
</ng-template>

<ng-template #receiveThrough>
  <div class="text-left font-12 p-2">
    <div class="color-pink fw-700 mb-2">Receive via</div>
    <div class="color-text fw-400">
      Choose this method for how you would prefer to receive your “I want
      currency”.
    </div>
  </div>
</ng-template>
