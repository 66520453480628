import { Injectable } from '@angular/core';
import { Plan } from '../../core/IApp';
import { GlobalsService } from '../../core/globals';
import { RequestService } from '../../core/request';

@Injectable({
  providedIn: 'root',
})
export class PlansService {
  plan: Plan | null = null;
  plans: Plan[] = [];
  usersPlans: any[] = [];
  userPlan: any | null = null;
  loading: boolean = false;

  plansPagination: any = {
    itemsPerPage: 5,
    totalItemsCount: 0,
    page: 1,
    search: '',
    category: '',
    ticketStatus: '',
    sort: {
      field: 'createdAt',
      order: 'desc',
    },
  };

  usersPlansPagination: any = {
    itemsPerPage: 5,
    totalItemsCount: 0,
    page: 1,
    search: '',
    name: '',
    cancelled: '',
    sort: {
      field: 'createdAt',
      order: 'desc',
    },
  };

  totalRevenue: number = 0;

  constructor(private api: RequestService, private globals: GlobalsService) {}

  async getPlans() {
    return await new Promise(async (resolve, reject) => {
      try {
        let params = '';
        Object.keys(this.plansPagination).forEach((key) => {
          if (key === 'sort') {
            params += `&sort=${this.plansPagination.sort.field}&order=${this.plansPagination.sort.order}`;
          } else {
            params += `&${key}=${this.plansPagination[key]}`;
          }
        });
        // this.globals.spinner.show();
        const resp: any = (await this.api.get(`plans/all?${params}`)) as Plan[];
        this.plans = resp.data.plans;
        this.plansPagination.totalItemsCount = resp.data.totalItemsCount;
        // this.globals.spinner.hide();
        resolve(resp);
      } catch (ex) {
        reject(ex);
      }
    });
  }

  async getPlan(id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get(`plans/plan/${id}`);
        this.plan = resp.data as Plan;
        this.globals.spinner.hide();
        resolve(true);
      } catch (ex: any) {
        this.globals.toast.error(ex.message);
        this.globals.spinner.hide();
        reject(ex);
      }
    });
  }

  async createPlan(plan: any) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post(`plans`, plan);
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp.data);
      } catch (ex: any) {
        this.globals.toast.error(ex.message);
        this.globals.spinner.hide();
        reject(ex);
      }
    });
  }

  async updatePlan(plan: any) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.patch(`plans/plan/${plan._id}`, plan);
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        this.globals.toast.error(ex.message);
        this.globals.spinner.hide();
        reject(ex);
      }
    });
  }

  async deletePlan(id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.delete(`plans/plan/${id}`);
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        this.globals.toast.error(ex.message);
        this.globals.spinner.hide();
        reject(ex);
      }
    });
  }

  async getUsersPlans() {
    return await new Promise(async (resolve, reject) => {
      try {
        let params = '';
        Object.keys(this.usersPlansPagination).forEach((key) => {
          if (key === 'sort') {
            params += `&sort=${this.usersPlansPagination.sort.field}&order=${this.usersPlansPagination.sort.order}`;
          } else {
            params += `&${key}=${this.usersPlansPagination[key]}`;
          }
        });
        this.loading = true;
        const resp: any = (await this.api.get(
          `plans/details?${params}`
        )) as Plan[];
        this.usersPlans = resp.data.plans;
        this.usersPlansPagination.totalItemsCount = resp.data.totalItemsCount;
        this.loading = false;
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.loading = false;
        this.globals.toast.error(ex.message);
        reject(ex);
      }
    });
  }

  async getTotalRevenue() {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(`payments/revenue`);
        this.totalRevenue = resp.data.revenue;
        resolve(resp);
      } catch (ex: any) {
        this.globals.toast.error(ex.message);
        reject(ex);
      }
    });
  }

  async upgradePlan({
    newPlan,
    period,
    user_id,
  }: {
    newPlan: string;
    period: string;
    user_id: string;
  }) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.patch(`users/${user_id}`, {
          subscription: newPlan,
          period,
        });
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(err);
        reject(err);
      }
    });
  }

  resetParams() {
    this.plansPagination = {
      itemsPerPage: 5,
      totalItemsCount: 0,
      page: 1,
      search: '',
      category: '',
      ticketStatus: '',
      sort: {
        field: 'createdAt',
        order: 'desc',
      },
    };

    this.usersPlansPagination = {
      itemsPerPage: 5,
      totalItemsCount: 0,
      page: 1,
      search: '',
      name: '',
      cancelled: '',
      sort: {
        field: 'createdAt',
        order: 'desc',
      },
    };

    this.usersPlans = [];
  }
}
