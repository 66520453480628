<form [formGroup]="accountForm" (ngSubmit)="submitProfile()">
  <div class="col-6 mb-3">
    <label for="firstName" class="input-label">FIRST NAME</label>
    <input
      type="text"
      formControlName="firstName"
      id="firstName"
      class="input-border"
      required
    />
    <small
      class="text-danger"
      *ngIf="firstName.errors?.['required'] && (firstName.dirty || firstName.touched)"
    >
      First name is required
    </small>
  </div>

  <div class="col-6 mb-3">
    <label for="lastName" class="input-label"
      >LASTNAME <span class="text-danger">*</span></label
    >
    <input
      type="text"
      formControlName="lastName"
      id="lastName"
      class="input-border"
    />
    <small
      class="text-danger"
      *ngIf="lastName.errors?.['required'] && (lastName.dirty || lastName.touched)"
    >
      Last name is required
    </small>
  </div>

  <div class="col-6 mb-3">
    <label for="email" class="input-label"
      >EMAIL <span class="text-danger">*</span></label
    >
    <input
      type="text"
      formControlName="email"
      id="email"
      class="input-border"
    />
    <small
      class="text-danger"
      *ngIf="email.errors?.['required'] && (email.dirty || email.touched)"
    >
      Email is required
    </small>

    <small
      class="text-danger"
      *ngIf="email.errors?.['email'] && (email.dirty || email.touched)"
    >
      Email is invalid
    </small>
  </div>

  <!-- <div class="col-6 mb-3">
    <label for="role" class="input-label">ROLE</label>
    <div class="input-border dropdown" (click)="selectDropdown($event)">
      {{ accountForm.value.role || "Select Role" }}
      <div class="dropdown-options">
        <div
          class="dropdown-option"
          *ngFor="let role of roles"
          (click)="setRoles(role)"
        >
          {{ role }}
        </div>
      </div>
    </div>
  </div> -->

  <div class="col-6 mb-3">
    <div class="avatar-section mt-5">
      <img
        [src]="uploadedImage || 'assets/icons/user.svg'"
        (click)="accountForm.patchValue({ avatar: '' }); uploadedImage = ''"
        class="pointer"
        alt="avatar"
      />
      <input
        type="file"
        accept="image/jpeg,image/png, image/jpg"
        class="d-none"
        (change)="uploadProfileImage($event)"
        id="avatar"
      />
      <div
        class="d-flex align-items-center justify-content-between position-absolute bottom-0 w-100"
      >
        <label for="avatar">Upload Image</label>
        <label data-bs-toggle="modal" attr.data-bs-target="#{{ modalId }}"
          >Change Image</label
        >
      </div>
    </div>
    <div class="text-center ms-5">
      <p class="mb-0 text-primary">Tap to remove image</p>
    </div>
  </div>

  <div class="d-flex align-item-center justify-content-end gap-3">
    <button class="btn-submit" type="submit">Update Account</button>
  </div>
</form>

<change-avatar
  [modalId]="modalId"
  [avatar]="accountForm.value.avatar"
  (emitAvatar)="setAvatar($event)"
></change-avatar>
