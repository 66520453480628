<form
  class="col-12 comment-form"
  (ngSubmit)="onSubmit()"
  (drop)="onDrop($event)"
  [formGroup]="commentForm"
>
  <div class="d-flex align-items-center gap-3">
    <button class="uploadBtn" type="button" (click)="fileInput.click()">
      <input
        type="file"
        #fileInput
        class="d-none"
        accept=".jpg, .jpeg, .png, .gif, .pdf"
        multiple
        (change)="onFileChange($event)"
      />
      <i class="bi bi-plus-lg"></i>
    </button>

    <input
      type="text"
      name="note"
      placeholder="Make a comment"
      formControlName="note"
    />

    <button type="submit" [disabled]="!commentForm.valid">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6011 1.40827C12.3283 1.12824 11.9245 1.02391 11.548 1.13373L2.768 3.68692C2.37074 3.79729 2.08917 4.1141 2.01333 4.51658C1.93584 4.92619 2.2065 5.44616 2.56009 5.66359L5.3054 7.3509C5.58697 7.52386 5.95039 7.48048 6.1834 7.24548L9.32705 4.08226C9.48529 3.91754 9.74722 3.91754 9.90547 4.08226C10.0637 4.24149 10.0637 4.49955 9.90547 4.66428L6.75636 7.82804C6.52281 8.0625 6.47915 8.42763 6.65104 8.71096L8.32846 11.4838C8.5249 11.8132 8.86322 11.9999 9.23428 11.9999C9.27794 11.9999 9.32705 11.9999 9.3707 11.9944C9.79633 11.9395 10.1347 11.6485 10.2602 11.2367L12.863 2.46798C12.9776 2.09461 12.874 1.68829 12.6011 1.40827Z"
          fill="white"
        />
      </svg>
    </button>
  </div>
  <div class="text-danger" *ngIf="attachment?.invalid">
    <small *ngIf="attachment?.errors?.['file']">
      File type is not supported (only jpg, jpeg, png, gif, pdf) or File size is
      too large
    </small>
  </div>
  <div class="d-flex flex-column gap-2 mt-3" *ngIf="files.length > 0">
    <div class="file" *ngFor="let file of files; let i = index">
      <div class="d-flex align-items-center gap-2">
        <i class="bi bi-file-earmark text-muted"></i>
        <p class="mb-0 fw-500">
          {{ file.name.substring(0, 10)
          }}{{ file.name.length > 10 ? "..." : "" }}
        </p>
      </div>
      <div class="d-flex align-items-center">
        <p class="mb-0 text-muted">
          {{ file.name.split(".").pop() | uppercase }}
        </p>
        <button class="deleteBtn" (click)="deleteFile(i)">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.99961 14.4001C11.5342 14.4001 14.3996 11.5347 14.3996 8.0001C14.3996 4.46548 11.5342 1.6001 7.99961 1.6001C4.46499 1.6001 1.59961 4.46548 1.59961 8.0001C1.59961 11.5347 4.46499 14.4001 7.99961 14.4001ZM5.17153 5.17174C5.48394 4.85932 5.99048 4.85932 6.3029 5.17174L7.71711 6.58595C7.87332 6.74216 8.12659 6.74216 8.2828 6.58595L9.69701 5.17174C10.0094 4.85932 10.516 4.85932 10.8284 5.17174C11.1408 5.48416 11.1408 5.99069 10.8284 6.30311L9.41417 7.71732C9.25796 7.87353 9.25796 8.1268 9.41417 8.28301L10.8284 9.69722C11.1408 10.0096 11.1408 10.5162 10.8284 10.8286C10.516 11.141 10.0094 11.141 9.69701 10.8286L8.2828 9.41438C8.12659 9.25817 7.87332 9.25817 7.71711 9.41438L6.3029 10.8286C5.99048 11.141 5.48394 11.141 5.17153 10.8286C4.85911 10.5162 4.85911 10.0096 5.17153 9.69722L6.58574 8.28301C6.74195 8.1268 6.74195 7.87353 6.58574 7.71732L5.17153 6.30311C4.85911 5.99069 4.85911 5.48416 5.17153 5.17174Z"
              fill="#797B89"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</form>
