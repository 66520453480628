import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'go-back-action',
  templateUrl: './go-back-action.component.html',
  styleUrls: ['./go-back-action.component.scss']
})
export class GoBackActionComponent {
  constructor(
    private location: Location,
  ) { }

  goBack() {
    this.location.back();
  }
}
