import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxEditorModule } from 'ngx-editor';
import { CountryFilterPipe } from '../services/shared/country-filter.pipe';
import { DateAsAgoPipe } from '../services/shared/dateAsAgo';
import { PhoneFilterPipe } from '../services/shared/phone-filter.pipe';
import { SearchPipe } from '../services/shared/search.pipe';
import { TicketConversationFilterPipe } from '../services/shared/ticket-conversation-filter.pipe';
import { TicketFilterPipe } from '../services/shared/ticket-filter.pipe';
import { AddBadgeFormComponent } from './forms/add-badge-form/add-badge-form.component';
import { AdminManagementFormComponent } from './forms/admin-management-form/admin-management-form.component';
import { AdminProfileFormComponent } from './forms/admin-profile-form/admin-profile-form.component';
import { AssigneeFormComponent } from './forms/assignee-form/assignee-form.component';
import { BulkUpgradeFormComponent } from './forms/bulk-upgrade-form/bulk-upgrade-form.component';
import { ChangePasswordFormComponent } from './forms/change-password-form/change-password-form.component';
import { CommentFormComponent } from './forms/comment-form/comment-form.component';
import { EmailContactFormComponent } from './forms/email-contact-form/email-contact-form.component';
import { MessagingFormComponent } from './forms/messaging-form/messaging-form.component';
import { NotificationsFormComponent } from './forms/notifications-form/notifications-form.component';
import { OtpFormComponent } from './forms/otp-form/otp-form.component';
import { PasswordResetFormComponent } from './forms/password-reset-form/password-reset-form.component';
import { PlanFormComponent } from './forms/plan-form/plan-form.component';
import { PlanUpgradeFormComponent } from './forms/plan-upgrade-form/plan-upgrade-form.component';
import { SigninFormComponent } from './forms/signin-form/signin-form.component';
import { TicketFormComponent } from './forms/ticket-form/ticket-form.component';
import { Tier1FormComponent } from './forms/tier1-form/tier1-form.component';
import { Tier2FormComponent } from './forms/tier2-form/tier2-form.component';
import { Tier3FormComponent } from './forms/tier3-form/tier3-form.component';
import { TopUpAllFormComponent } from './forms/top-up-all-form/top-up-all-form.component';
import { TopUpFormComponent } from './forms/top-up-form/top-up-form.component';
import { UserProfileFormComponent } from './forms/user-profile-form/user-profile-form.component';
import { CancelPlanReasonComponent } from './modals/cancel-plan-reason/cancel-plan-reason.component';
import { CancelPlanComponent } from './modals/cancel-plan/cancel-plan.component';
import { ChangeAvatarComponent } from './modals/change-avatar/change-avatar.component';
import { ChooseTimeComponent } from './modals/choose-time/choose-time.component';
import { CreateFaqComponent } from './modals/create-faq/create-faq.component';
import { CreateOfferComponent } from './modals/create-offer/create-offer.component';
import { DeactivateModalComponent } from './modals/deactivate-modal/deactivate-modal.component';
import { DeactivateReasonModalComponent } from './modals/deactivate-reason-modal/deactivate-reason-modal.component';
import { FreezeModalComponent } from './modals/freeze-modal/freeze-modal.component';
import { KycActivityModalComponent } from './modals/kyc-activity-modal/kyc-activity-modal.component';
import { PlanHistoryModalComponent } from './modals/plan-history-modal/plan-history-modal.component';
import { PreviewPlanComponent } from './modals/preview-plan/preview-plan.component';
import { TokenHistoryComponent } from './modals/token-history/token-history.component';
import { TransactionModalComponent } from './modals/transaction-modal/transaction-modal.component';
import { ViewImageComponent } from './modals/view-image/view-image.component';
import { ViewKycComponent } from './modals/view-kyc/view-kyc.component';
import { ViewReviewComponent } from './modals/view-review/view-review.component';
import { ViewTicketComponent } from './modals/view-ticket/view-ticket.component';
import { ButtonComponent } from './ui/button/button.component';
import { ContactUserComponent } from './ui/contact-user/contact-user.component';
import { CustomCalendarComponent } from './ui/custom-calendar/custom-calendar.component';
import { FooterComponent } from './ui/footer/footer.component';
import { GoBackActionComponent } from './ui/go-back-action/go-back-action.component';
import { HeaderComponent } from './ui/header/header.component';
import { ModalComponent } from './ui/modal/modal.component';
import { NavbarComponent } from './ui/navbar/navbar.component';
import { NoDataComponent } from './ui/no-data/no-data.component';
import { PaginationComponent } from './ui/pagination/pagination.component';
import { PhoneInputComponent } from './ui/phone-input/phone-input.component';
import { SearchBarComponent } from './ui/search-bar/search-bar.component';
import { SidebarComponent } from './ui/sidebar/sidebar.component';
import { MergeTicketModalComponent } from './modals/merge-ticket-modal/merge-ticket-modal.component';
import { FilterComponent } from './modals/filter-modal/filter.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TokenFormComponent } from './forms/token-form/token-form.component';

const components: Array<any> = [
  HeaderComponent,
  FooterComponent,
  SigninFormComponent,
  OtpFormComponent,
  SidebarComponent,
  ButtonComponent,
  NavbarComponent,
  ModalComponent,
  AdminManagementFormComponent,
  SearchBarComponent,
  GoBackActionComponent,
  NoDataComponent,
  MessagingFormComponent,
  Tier1FormComponent,
  Tier2FormComponent,
  Tier3FormComponent,
  PhoneInputComponent,
  PhoneFilterPipe,
  TicketConversationFilterPipe,
  TicketFilterPipe,
  TopUpAllFormComponent,
  TopUpFormComponent,
  TicketFormComponent,
  AssigneeFormComponent,
  CustomCalendarComponent,
  ContactUserComponent,
  EmailContactFormComponent,
  CommentFormComponent,
  UserProfileFormComponent,
  ChangePasswordFormComponent,
  PaginationComponent,
  DateAsAgoPipe,
  DeactivateModalComponent,
  DeactivateReasonModalComponent,
  TransactionModalComponent,
  FreezeModalComponent,
  CreateOfferComponent,
  ChooseTimeComponent,
  CountryFilterPipe,
  AdminProfileFormComponent,
  PasswordResetFormComponent,
  NotificationsFormComponent,
  CreateFaqComponent,
  ViewTicketComponent,
  ViewReviewComponent,
  SearchPipe,
  BulkUpgradeFormComponent,
  AddBadgeFormComponent,
  ChangeAvatarComponent,
  ViewKycComponent,
  KycActivityModalComponent,
  PlanFormComponent,
  ViewImageComponent,
  PlanUpgradeFormComponent,
  PreviewPlanComponent,
  PlanHistoryModalComponent,
  CancelPlanReasonComponent,
  CancelPlanComponent,
  TokenHistoryComponent,
  MergeTicketModalComponent,
  FilterComponent,
  TokenFormComponent
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    RouterModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    NgxEditorModule,
    NgxSkeletonLoaderModule,
    NgbTooltipModule,
    NgbModule,
  ],
})
export class ComponentsModule {}
