import { Pipe, PipeTransform } from '@angular/core';
import { Ticket } from '../core/IApp';

@Pipe({
  name: 'ticketConversationFilter',
})
export class TicketConversationFilterPipe implements PipeTransform {
  transform(ticketConversations: Ticket[], searchTerm: string): Ticket[] {
    if (!ticketConversations || !searchTerm) return ticketConversations;

    return ticketConversations.filter(
      (ticketConversations) =>
        ticketConversations.subject
          .toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase()) ||
        ticketConversations.messages.author.name.first
          .toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase()) ||
        ticketConversations.messages.author.name.last
          .toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase())
    );
  }
}
