<form [formGroup]="passwordForm" (ngSubmit)="submitPassword()">
  <div class="col-6 mb-4">
    <label for="current_password" class="input-label">CURRENT PASSWORD</label>
    <div class="position-relative">
      <input
        [type]="showCurrentPassword ? 'text' : 'password'"
        formControlName="current_password"
        id="current_password"
        class="input-border pe-4"
        placeholder="Enter Current Password"
        required
      />
      <span
        class="position-absolute"
        style="top: 50%; right: 15px; transform: translateY(-50%)"
        (click)="showCurrentPassword = !showCurrentPassword"
      >
        <i
          class="bi"
          [ngClass]="{
            'bi-eye': !showCurrentPassword,
            'bi-eye-slash': showCurrentPassword
          }"
        ></i>
      </span>
    </div>
    <small
      class="text-danger"
      *ngIf="current_password.errors?.['required'] && (current_password.dirty || current_password.touched)"
    >
      Current Password is required
    </small>
  </div>

  <div class="col-6 mb-4">
    <label for="password" class="input-label"
      >NEW PASSWORD <span class="text-danger">*</span></label
    >
    <div class="position-relative">
      <input
        [type]="showPassword ? 'text' : 'password'"
        formControlName="password"
        id="password"
        placeholder="Enter New Password"
        class="input-border"
      />
      <span
        class="position-absolute"
        style="top: 50%; right: 15px; transform: translateY(-50%)"
        (click)="showPassword = !showPassword"
      >
        <i
          class="bi"
          [ngClass]="{
            'bi-eye': !showPassword,
            'bi-eye-slash': showPassword
          }"
        ></i>
      </span>
    </div>
    <small
      class="text-danger"
      *ngIf="password.errors?.['required'] && (password.dirty || password.touched)"
    >
      Password is required
    </small>
    <small
      class="text-danger"
      *ngIf="password.errors?.['minlength'] && (password.dirty || password.touched)"
    >
      Password must be at least 8 characters
    </small>
    <small
      class="text-danger"
      *ngIf="password.errors?.['pattern'] && (password.dirty || password.touched)"
    >
      Password must contain at least one uppercase letter, one lowercase letter,
      and one number
    </small>
  </div>

  <div class="col-6 mb-5">
    <label for="confirmPassword" class="input-label"
      >REENTER NEW PASSWORD <span class="text-danger">*</span></label
    >
    <div class="position-relative">
      <input
        [type]="showConfirmPassword ? 'text' : 'password'"
        formControlName="confirmPassword"
        id="confirmPassword"
        class="input-border"
        placeholder="Re-enter New Password"
      />
      <span
        class="position-absolute"
        style="top: 50%; right: 15px; transform: translateY(-50%)"
        (click)="showConfirmPassword = !showConfirmPassword"
      >
        <i
          class="bi"
          [ngClass]="{
            'bi-eye': !showConfirmPassword,
            'bi-eye-slash': showConfirmPassword
          }"
        ></i>
      </span>
    </div>
    <small
      class="text-danger"
      *ngIf="confirmPassword.errors?.['required'] && (confirmPassword.dirty || confirmPassword.touched)"
    >
      Confirm Password is required
    </small>

    <small
      class="text-danger"
      *ngIf="confirmPassword.errors?.['matching'] && (confirmPassword.dirty || confirmPassword.touched)"
    >
      Passwords do not match
    </small>
  </div>

  <button
    class="btn-submit"
    type="submit"
    [disabled]="
      passwordForm.invalid || passwordForm.pristine || passwordForm.pending
    "
  >
    Update Password
  </button>
</form>
