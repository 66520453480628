import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'choose-time',
  templateUrl: './choose-time.component.html',
  styleUrls: ['./choose-time.component.scss'],
})
export class ChooseTimeComponent implements OnInit {
  @Input() modalId: string = '';
  @Output() emitTimeExtention: EventEmitter<number> =
    new EventEmitter<number>();

  @ViewChild('closeBtn') closeBtn: any;

  constructor() {}

  timers = [
    { status: false, value: 1, img: 'assets/icons/smiling-face.svg' },
    { status: false, value: 2, img: 'assets/icons/beaming-face.svg' },
    { status: false, value: 3, img: 'assets/icons/star-struck.svg' },
  ];
  timeExtention: number = 0;
  selectedTimer: any = null;

  ngOnInit(): void {}

  chooseTimer() {
    this.emitTimeExtention.emit(
      this.selectedTimer && this.timeExtention <= 0
        ? this.selectedTimer.value
        : this.timeExtention
    );
    this.selectedTimer = null;
    this.timeExtention = 0;
    this.closeBtn.nativeElement.click();
  }
}
