import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ComponentsModule } from 'src/app/components/components.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';

const components: Array<any> = [MainComponent, DashboardComponent];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    MainRoutingModule,
    ComponentsModule,
    CarouselModule,
    FormsModule,
    NgbModule,
  ],
})
export class MainModule {}
