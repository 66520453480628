<button
  [type]="type"
  [disabled]="disabled"
  [ngStyle]="ngStyle"
  [ngClass]="ngClass"
  [routerLink]="routerLink"
  (click)="onClick.emit(emitValue)"
>
  {{ text }}
</button>
