import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'create-faq',
  templateUrl: './create-faq.component.html',
  styleUrls: ['./create-faq.component.scss'],
})
export class CreateFaqComponent {
  @Output() emitSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() modalId: string = '';
  @Input() faq: any | null = null;

  faqForm: FormGroup = new FormGroup({
    _id: new FormControl(''),
    category: new FormControl('', Validators.required),
    question: new FormControl('', Validators.required),
    answer: new FormControl('', Validators.required),
  });

  constructor() {}

  ngOnChanges() {
    if (this.faq) {
      this.faqForm.patchValue(this.faq);
    }
  }

  onSubmit() {
    this.emitSubmit.emit(this.faqForm.value);
    this.faqForm.reset({
      question: '',
      answer: '',
      _id: '',
    });
    this.faq = null;
  }

  get question() {
    return this.faqForm.get('question') as FormControl;
  }

  get answer() {
    return this.faqForm.get('answer') as FormControl;
  }

  get category() {
    return this.faqForm.get('category') as FormControl;
  }
}
