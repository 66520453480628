export class Variables{
    public static ACCESS_TOKEN = '%Exp@t_$w@p_Token%';
    public static PERSONAL_DETAILS = '%Exp@t_$w@p_USER&TAILS%';
    public static REG_DETAIL = '%Exp@t_$w@p_USER&REG%';
    public static USER_DETAIL = '%Exp@t_$w@p_USER&DET%';
    public static USER_TRANSACTION = '%Exp@t_$w@p_TRANSATION%';
    public static HOME_PAGE_CURRENCY_SEARCH = '%Exp@t_$w@p_Cur#$E@rch%';
    public static QR_CODE_SECRET = '%Exp@t_$w@p_QR&CODE%';
    public static SIGNIN_CREDENTIALS = '%Exp@t_$w@p_SIGNIN&CREDS%';
    public static COUNTRIES = '%Exp@t_$w@p_COUNTRIES%';
    public static LOCATION_DETAILS = '%Exp@t_$w@p_LOCATION&DETAILS%';
    public static TICKET_TOTAL = '%Exp@t_$w@p_TICKET&TOTAL%';
    public static NOTIFICATION = '%Exp@t_$w@p_NOTIFICATION%';
}

export interface Enums {
    TRANSACTION_STATUS: ['ongoing', 'pending', 'completed', 'archived', 'failed'];
    VERIFICATION_STATUS: ['pending', 'verified', 'rejected'];
  }