<div class="row m-3">
  <div class="col-12 d-flex justify-content-end mb-5">
    <button
      type="button"
      class="closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
    >
      <img src="assets/icons/close-icon-pink.svg" alt="" />
    </button>
  </div>
  <div class="col-12 mb-5">
    <h5 class="fw-700 text-center mb-4">Assignee</h5>
    <form
      class="row gy-4 justify-content-center"
      [formGroup]="assigneeForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="col-12 table_container border rounded">
        <table class="table table-hover mt-3">
          <thead>
            <tr class="font-16 fw-700">
              <td scope="col">
                Full Name
                <img
                  src="assets/icons/arrows-down-up.svg"
                  alt="..."
                  class="ms-2"
                />
              </td>
              <td scope="col">
                Role
                <img
                  src="assets/icons/arrows-down-up.svg"
                  alt="..."
                  class="ms-2"
                />
              </td>
              <td scope="col">Actions</td>
            </tr>
          </thead>
          <tbody>
            <tr class="font-14" *ngFor="let user of users">
              <td class="fw-700 font-14">
                <div class="gap-3 d-flex align-items-center">
                  <img
                    class="mr-btn-20 cir"
                    src="{{ user.avatar || 'assets/icons/user.svg' }}"
                    alt="user-image"
                    class="profileImg"
                  />
                  <div>
                    <h6 class="mb-0">
                      <b>{{ user.name.first + " " + user.name.last }}</b>
                    </h6>
                    <p class="mb-0 text-muted font-12 fw-400">
                      {{ user.username }}
                    </p>
                  </div>
                </div>
              </td>
              <td class="fw-400 font-16">
                <p class="mb-0">
                  {{ user.role.replace("_", " ") | titlecase }}
                </p>
              </td>
              <td class="fw-400 font-16">
                <div class="d-flex align-items-center gap-2">
                  <div class="radio-button">
                    <input
                      type="radio"
                      name="assignee"
                      [id]="user._id"
                      [value]="user._id"
                      formControlName="assignee"
                    />
                    <label [for]="user._id"></label>
                  </div>
                  <label class="mb-0 pointer" [for]="user._id">Assign user</label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-11 text-end">
        <button class="swapBtn" (click)="closeBtn.click()" type="button">
          Cancel
        </button>
        <button class="swapBtn update ms-2" type="submit">Assign</button>
      </div>
    </form>
  </div>
</div>
