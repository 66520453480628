<swap-modal [modalId]="modalId" [centered]="true">
  <div class="modal-header border-0">
    <img loading="lazy" src="assets/images/Featured icon.svg" />
    <button
      type="button"
      class="d-none"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeModal
    ></button>
  </div>
  <div class="modal-body mx-3">
    <p class="color-header fw-700 font-18 mb-0">Reason for Canceling Plan</p>
    <div class="list-group">
      <label class="list-group-item px-0 border-0 pointer" *ngFor="let rs of reasons">
        <input
          class="form-check-input me-1"
          type="checkbox"
          value="{{ rs }}"
          [checked]="selectedReasons.includes(rs)"
          (click)="selectReason(rs)"
        />
        {{ rs }}
      </label>
    </div>
    <div class="mb-3">
      <input
        *ngIf="selectedReasons.includes('Others')"
        [(ngModel)]="otherReason"
        type="text"
        class="form-control"
        placeholder="Enter reason"
      />
    </div>

    <div class="d-flex align-items-center gap-2">
      <button (click)="closeDialog()" class="btn-confirm fw-700 font-13">
        Cancel
      </button>
      <button
        [disabled]="selectedReasons.length === 0"
        class="btn-confirm fw-700 font-13 btn-delete"
        (click)="cancelPlan()"
      >
        Change to Free
      </button>
    </div>
  </div>
</swap-modal>
