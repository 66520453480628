import { Injectable } from '@angular/core';
import {
  CountriesList,
  KYC,
  KYCLog,
  Ratings_Reviews,
  Swap_User,
  Token,
  Transaction,
} from '../../core/IApp';
import { GlobalsService } from '../../core/globals';
import { RequestService } from '../../core/request';

@Injectable({
  providedIn: 'root',
})
export class SwapUserService {
  // Data Variables
  swapUser: Swap_User | any = {};
  swapUsers: Swap_User[] = [];
  swapPals: Swap_User[] = [];
  deals: Transaction[] = [];
  transactions: Transaction[] = [];
  ratingsAndReviews: Ratings_Reviews[] | any[] = [];
  countryListings: CountriesList[] = [];
  verifications: Swap_User[] = [];
  offer: Transaction | any;
  ratingsReview: Ratings_Reviews | any;
  kycs: KYC[] = [];
  kyc: KYC | any;
  kycLogs: KYCLog[] = [];
  singleKYCLog: KYCLog[] = [];
  tokenHistory: Token[] = [];
  tokenAverage = {
    earned: 0,
    purchased: 0,
    spent: 0,
  };
  newSignUpBy: string = 'daily';

  // Pagination
  swapUsersPagination = {
    itemsPerPage: 5,
    totalItemsCount: 0,
    page: 1,
    search: '',
    mode: {
      value: '',
      name: '',
    },
    sort: {
      status: 'createdAt',
      order: 'desc',
    },
  };

  verificationPagination = {
    itemsPerPage: 5,
    totalItemsCount: 0,
    page: 1,
    verificationStatus: '',
    search: '',
    sort: {
      status: 'createdAt',
      order: 'desc',
    },
  };

  ratingsReviewPagination = {
    itemsPerPage: 5,
    totalItemsCount: 0,
    filter: '',
    page: 1,
    verificationStatus: '',
    ratings: '',
    search: '',
    sort: {
      status: 'createdAt',
      order: 'desc',
    },
  };

  swapPalPagination = {
    itemsPerPage: 5,
    totalItemsCount: 0,
    page: 1,
    search: '',
  };

  swapUserOffersPagination = {
    itemsPerPage: 5,
    totalItemsCount: 0,
    page: 1,
    sort: {
      status: '',
      order: 'desc',
    },
  };

  swapUserRatingsReviewPagination = {
    itemsPerPage: 5,
    totalItemsCount: 0,
    page: 1,
  };

  swapUserKycPagination = {
    itemsPerPage: 5,
    totalItemsCount: 0,
    page: 1,
  };

  swapUserKycLogsPagination = {
    itemsPerPage: 5,
    totalItemsCount: 0,
    page: 1,
    mode: 'verification-tier',
  };

  swapUserTokenHistoryPagination = {
    itemsPerPage: 5,
    totalItemsCount: 0,
    page: 1,
    type: 'spent',
  };

  swapUserTokenAveragePagination = {
    period: 'daily',
  };

  // Sorting
  totalNoOfVerifications: number = 0;
  totalNoOfUsers: number = 0;

  // Dashboard Variables
  totalNoOfSwapUsers: number = 0;
  totalNoOfActiveSwapUsers: number = 0;
  totalNoOfReferrals: number = 0;
  totalAvgNewSignUps: number = 0;

  // Ratings and Reviews
  ratingsReviewTotals = {
    average: 0,
    countAll: 0,
    countRatingsEq1: 0,
    countRatingsEq2: 0,
    countRatingsEq3: 0,
    countRatingsEq4: 0,
    countRatingsEq5: 0,
  };

  constructor(private api: RequestService, private globals: GlobalsService) {}

  async getSwapUsers() {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get(
          `users?page=${this.swapUsersPagination.page}&itemsPerPage=${
            this.swapUsersPagination.itemsPerPage
          }&search=${this.swapUsersPagination.search}&sort=${
            this.swapUsersPagination.sort.status
          }&order=${this.swapUsersPagination.sort.order}&${
            this.swapUsersPagination.mode
              ? `mode=${this.swapUsersPagination.mode.value}`
              : ''
          }`
        );
        this.swapUsers = resp.data.users as Swap_User[];
        this.swapUsersPagination.totalItemsCount = resp.data.totalItemsCount;
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.toast.error(err || 'Error: Internal Server Error');
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async getSwapUser(id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(`users/profile/${id}?field=id`);
        this.swapUser = resp.data as Swap_User;
        resolve(resp);
      } catch (err: any) {
        this.globals.toast.error(err);
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async getSwapUserByUsername(username: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(`users/profile/${username}?field=username`);
        this.swapUser = resp.data as Swap_User;
        resolve(resp.data);
      } catch (err: any) {
        this.globals.toast.error(err);
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async getSwapUsersCount() {
    return await new Promise(async (resolve, reject) => {
      try {
        const { data }: any = await this.api.get(`users/count`);
        this.totalNoOfSwapUsers = data.count as number;
        resolve(data);
      } catch (err: any) {
        this.globals.toast.error(err);
        reject(err);
      }
    });
  }

  async getActiveSwapUsersCount() {
    return await new Promise(async (resolve, reject) => {
      try {
        const { data }: any = await this.api.get(`users/count/active`);
        this.totalNoOfActiveSwapUsers = data.count as number;
        resolve(data);
      } catch (err: any) {
        this.globals.toast.error(err || 'Error: Internal Server Error');
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async getSwapUserDeals({ id }: { id: string }) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(
          `offers/user/${id}?page=${this.swapUserOffersPagination.page}&itemsPerPage=${this.swapUserOffersPagination.itemsPerPage}&sort=${this.swapUserOffersPagination.sort.status}&order=${this.swapUserOffersPagination.sort.order}`
        );
        this.deals = resp.data.offers as Transaction[];
        this.swapUserOffersPagination.totalItemsCount =
          resp.data.totalItemsCount;
        resolve(resp.data.totalItemsCount);
      } catch (err: any) {
        this.globals.toast.error(err || 'Error: Internal Server Error');
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async getSwapUserRatingReviewByUserId({ id }: { id: string }) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(
          `ratings-reviews/user/${id}?page=${this.swapUserRatingsReviewPagination.page}&itemsPerPage=${this.swapUserRatingsReviewPagination.itemsPerPage}&order=desc`
        );
        this.ratingsAndReviews = resp.data.ratings as Ratings_Reviews[];
        this.swapUserRatingsReviewPagination.totalItemsCount =
          resp.data.totalItemsCount;
        resolve(resp.data.length);
      } catch (err: any) {
        this.globals.toast.error(err);
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async getSwapUserRatingReviewById({ id }: { id: string }) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(`ratings-reviews/review/${id}`);
        this.ratingsAndReviews = resp.data.ratings as Ratings_Reviews[];
        resolve(resp.data);
      } catch (err: any) {
        this.globals.toast.error(err);
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async deactivateSwapUser(swap_user: Swap_User, deactivatedReason: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.patch(`users/${swap_user._id}`, {
          deactivatedReason,
        });
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.toast.error(err);
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async freezeSwapUser(swap_user: Swap_User, freeze: boolean) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.patch(`users/${swap_user._id}`, {
          freeze,
        });
        this.globals.toast.success(
          `User's account has been ${
            freeze ? 'frozen' : 'unfrozen'
          } successfully`
        );
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.toast.error(err);
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async getSwapUserVerification() {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get(
          `users?page=${this.verificationPagination.page}&itemsPerPage=${this.verificationPagination.itemsPerPage}&verificationStatus=${this.verificationPagination.verificationStatus}&sort=${this.verificationPagination.sort.status}&order=${this.verificationPagination.sort.order}&search=${this.verificationPagination.search}`
        );
        this.verifications = resp.data.users;
        this.verificationPagination.totalItemsCount = resp.data.totalItemsCount;
        this.globals.spinner.hide();
        resolve(resp.data.length);
      } catch (err: any) {
        this.globals.toast.error(err);
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async updateTierLevel({
    verificationStatus,
    user_id,
  }: {
    verificationStatus: { rank: number; name: string };
    user_id: string;
  }) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.patch(`users/${user_id}`, {
          verificationStatus,
        });
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.toast.error(err);
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async updateBadge({
    verificationBadge,
    user_id,
  }: {
    verificationBadge: { rank: number; name: string };
    user_id: string;
  }) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.patch(`users/${user_id}`, {
          verificationBadge,
        });
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.toast.error(err);
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async updateSwapUserTier1({
    user_id,
    email,
    phone,
  }: {
    user_id: string;
    email: string;
    phone: string;
  }) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.patch(`users/${user_id}`, {
          email,
          phone,
        });
        this.globals.spinner.hide();
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (err: any) {
        this.globals.toast.error(err);
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async updateSwapUserTier2({
    user_id,
    facebook,
    instagram,
  }: {
    user_id: string;
    facebook: string;
    instagram: string;
  }) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.patch(`users/${user_id}`, {
          facebook,
          instagram,
        });
        this.globals.spinner.hide();
        if (resp.message) this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (err: any) {
        this.globals.toast.error(err || 'Error: Internal Server Error');
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async updateSwapUserProfile(swap_user: Swap_User) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.update(
          `swap_users/update_user_profile/${swap_user._id}`,
          swap_user
        );
        this.globals.spinner.hide();
        if (resp.message) this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (err: any) {
        this.globals.toast.error(err || 'Error: Internal Server Error');
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async getSwapUserSwapPals(
    id: string,
    page: number = this.swapPalPagination.page,
    itemsPerPage: number = this.swapPalPagination.itemsPerPage,
    search: string = this.swapPalPagination.search
  ) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(
          `swap-pals/${id}?page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`
        );
        this.swapPals = resp.data.pals as Swap_User[];
        this.swapPalPagination.totalItemsCount = resp.data.totalItemsCount;
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        reject(ex);
      }
    });
  }

  async getSwapUserRatingReview(
    page: number = this.ratingsReviewPagination.page,
    itemsPerPage: number = this.ratingsReviewPagination.itemsPerPage,
    search: string = this.ratingsReviewPagination.search
  ) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(
          `ratings-reviews/all?page=${page}&itemsPerPage=${itemsPerPage}&search=${search}&order=${
            this.ratingsReviewPagination.sort.order
          }&sort=${this.ratingsReviewPagination.sort.status}${
            this.ratingsReviewPagination.ratings
              ? `&ratings=${this.ratingsReviewPagination.ratings}`
              : ''
          }`
        );
        this.ratingsAndReviews = resp.data.ratingsReviews;
        this.ratingsReviewPagination.totalItemsCount =
          resp.data.totalItemsCount;
        resolve(resp);
      } catch (ex: any) {
        this.globals.toast.error(ex || 'Error: Internal Server Error');
        this.globals.spinner.hide();
        reject(ex);
      }
    });
  }

  async getTotalRefferalCount() {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(`users/count/referrals`);
        this.totalNoOfReferrals = resp.data.count;
        resolve(resp);
      } catch (ex: any) {
        this.globals.toast.error(ex || 'Error: Internal Server Error');
        this.globals.spinner.hide();
        reject(ex);
      }
    });
  }

  async getNewSignUpsCount() {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(`users/count/${this.newSignUpBy}`);
        this.totalAvgNewSignUps = resp.data.count;
        resolve(resp);
      } catch (ex: any) {
        this.globals.toast.error(ex || 'Error: Internal Server Error');
        this.globals.spinner.hide();
        reject(ex);
      }
    });
  }

  async getUsersKYC(username: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(
          `users/kyc/${username}?page=${this.swapUserKycPagination.page}&itemsPerPage=${this.swapUserKycPagination.itemsPerPage}`
        );
        this.kycs = resp.data.kyc;
        this.swapUserKycPagination.totalItemsCount = resp.data.totalItemsCount;
        resolve(resp);
      } catch (ex: any) {
        this.globals.toast.error(ex || 'Error: Internal Server Error');
        this.globals.spinner.hide();
        reject(ex);
      }
    });
  }

  async getSwapUserKYCLogs(
    username: string,
    mode: string = this.swapUserKycLogsPagination.mode,
    verificationId: string = ''
  ) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(
          `users/kyc/${username}?mode=${mode}&page=${
            this.swapUserKycLogsPagination.page
          }&itemsPerPage=${this.swapUserKycLogsPagination.itemsPerPage}${
            verificationId ? `&verificationId=${verificationId}` : ''
          }`
        );
        this.kycLogs = resp.data.kyc;
        this.swapUserKycLogsPagination.totalItemsCount =
          resp.data.totalItemsCount;
        resolve(resp);
      } catch (ex: any) {
        this.globals.toast.error(ex);
        this.globals.spinner.hide();
        reject(ex);
      }
    });
  }

  async updateKYCStatus({
    _id,
    status,
    field,
    username,
  }: {
    _id: string;
    status: string;
    field: string;
    username: string;
  }) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.patch(`users/kyc/${_id}`, {
          field,
          status,
        });
        this.kyc[field] = status;
        await this.getUsersKYC(username);
        this.kyc = this.kycs.find((kyc) => kyc._id === _id);
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (ex: any) {
        this.globals.toast.error(ex);
        this.globals.spinner.hide();
        reject(ex);
      }
    });
  }

  async getTokenHistory(id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(
          `tokens/history?id=${id}&page=${this.swapUserTokenHistoryPagination.page}&itemsPerPage=${this.swapUserTokenHistoryPagination.itemsPerPage}&type=${this.swapUserTokenHistoryPagination.type}`
        );
        this.tokenHistory = resp.data.tokens;
        this.swapUserTokenHistoryPagination.totalItemsCount =
          resp.data.totalItemsCount;
        resolve(resp);
      } catch (ex: any) {
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  async getTokensAverage(
    id: string,
    period: string = this.swapUserTokenAveragePagination.period
  ) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(
          `tokens/average?id=${id}&period=${period}`
        );
        this.tokenAverage = resp.data;
        resolve(resp);
      } catch (ex: any) {
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  async uploadProofOfAddress(id: string, formData: FormData) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        this.api.headers = {
          'content-type': 'multipart/form-data',
        };
        const resp: any = await this.api.post(
          `users/kyc/upload/${id}`,
          formData
        );
        this.globals.spinner.hide();
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  async getAllReviewsCount() {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(`ratings-reviews/count`);
        this.ratingsReviewTotals.average = resp.data.average;
        this.ratingsReviewTotals.countAll = resp.data.countAll;
        this.ratingsReviewTotals.countRatingsEq1 = resp.data.countRatingsEq1;
        this.ratingsReviewTotals.countRatingsEq2 = resp.data.countRatingsEq2;
        this.ratingsReviewTotals.countRatingsEq3 = resp.data.countRatingsEq3;
        this.ratingsReviewTotals.countRatingsEq4 = resp.data.countRatingsEq4;
        this.ratingsReviewTotals.countRatingsEq5 = resp.data.countRatingsEq5;
        resolve(resp);
      } catch (ex: any) {
        this.globals.toast.error(ex || 'Error: Internal Server Error');
        this.globals.spinner.hide();
        reject(ex);
      }
    });
  }

  async hideRatingReview(id: string, hide: boolean = true) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.patch(`ratings-reviews/${id}`, {
          hide,
        });
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (ex: any) {
        this.globals.toast.error(ex || 'Error: Internal Server Error');
        this.globals.spinner.hide();
        reject(ex);
      }
    });
  }

  resetParams() {
    this.verificationPagination = {
      itemsPerPage: 5,
      totalItemsCount: 0,
      page: 1,
      verificationStatus: '',
      search: '',
      sort: {
        status: 'createdAt',
        order: 'desc',
      },
    };
    this.verifications = [];

    this.swapUsersPagination = {
      itemsPerPage: 5,
      totalItemsCount: 0,
      page: 1,
      mode: {
        value: '',
        name: '',
      },
      search: '',
      sort: {
        status: 'createdAt',
        order: 'desc',
      },
    };
    this.swapUsers = [];

    this.ratingsReviewPagination = {
      itemsPerPage: 5,
      totalItemsCount: 0,
      page: 1,
      filter: '',
      verificationStatus: '',
      ratings: '',
      search: '',
      sort: {
        status: 'createdAt',
        order: 'desc',
      },
    };
    this.ratingsAndReviews = [];

    this.ratingsReviewTotals = {
      average: 0,
      countAll: 0,
      countRatingsEq1: 0,
      countRatingsEq2: 0,
      countRatingsEq3: 0,
      countRatingsEq4: 0,
      countRatingsEq5: 0,
    };
  }
}
