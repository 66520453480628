<div class="row g-0">
  <div class="col-12">
    <div class="p-4 px-5 mx-5">
      <img
        src="assets/images/logo.svg"
        alt="Expatswap Logo"
        class="pointer"
        routerLink="/"
      />
    </div>
  </div>
</div>
