import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Country, State, Swap_User } from 'src/app/services/core/IApp';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'user-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.scss'],
})
export class UserProfileFormComponent {
  @Input() swapUser: Swap_User | undefined;

  @Output() emitUserProfile: EventEmitter<Swap_User> =
    new EventEmitter<Swap_User>();

  @Output() emitChangeCountry: EventEmitter<string> =
    new EventEmitter<string>();

  profileForm: FormGroup = new FormGroup({
    user_id: new FormControl('', Validators.compose([Validators.required])),
    first_name: new FormControl('', Validators.compose([Validators.required])),
    last_name: new FormControl('', Validators.compose([Validators.required])),
    email: new FormControl('', Validators.compose([Validators.required])),
    country: new FormControl('', Validators.compose([Validators.required])),
    city: new FormControl('', Validators.compose([Validators.required])),
    state: new FormControl('', Validators.compose([Validators.required])),
    about_me: new FormControl('', Validators.compose([Validators.required])),
  });

  cities: Array<any> = [];

  selectedCountry: Country | undefined;

  constructor(public globals: GlobalsService) {}

  ngOnChanges() {
    if (this.swapUser) {
      this.selectedCountry =
        this.globals.countries.find(
          (country: Country) => country.name === this.swapUser?.address?.country
        ) || undefined;
      this.profileForm.patchValue({
        ...this.swapUser,
        first_name: this.swapUser.name.first,
        last_name: this.swapUser.name.last,
        country: this.swapUser.address.country,
        city: this.swapUser.address.city,
        state: this.swapUser.address.state,
        about_me: this.swapUser.aboutMe,
      });
      this.selectedCountry && this.setCountry(this.selectedCountry);
    }
  }

  onSubmit() {
    if (this.profileForm.invalid) {
      return;
    }
    this.emitUserProfile.emit(this.profileForm.value);
    this.profileForm.reset();
  }

  selectDropdown(event: any) {
    // close other dropdown options
    event.preventDefault();
    let mainElement = event.target;
    const element = document.querySelectorAll('.dropdown-options');
    element.forEach((el) => {
      if (
        el.classList.contains('show') &&
        el !== mainElement.nextElementSibling
      ) {
        el.classList.remove('show');
      }
    });

    if (mainElement.nodeName !== 'BUTTON') mainElement = mainElement.parentNode;

    // add show class to dropdown options
    for (let i = 0; i < mainElement.children.length; i++) {
      if (mainElement.children[i].classList.contains('dropdown-options')) {
        mainElement.children[i].classList.toggle('show');
      }
    }
    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target?.classList.contains('dropdown-option')) {
        element.forEach((el) => {
          if (el.classList.contains('show')) {
            el.classList.remove('show');
          }
        });
      }
    });
  }

  setCountry(country: Country) {
    this.emitChangeCountry.emit(country._id);
    this.selectedCountry = country;
    this.profileForm.patchValue({ country: country.name });
  }

  setCity(city: any) {
    this.profileForm.patchValue({ city: city.name });
  }

  setState(state: State) {
    this.profileForm.patchValue({ state: state.name });
    this.cities = state.cities;
  }
}
