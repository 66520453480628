<div class="row g-0 profile-form justify-content-center">
  <div class="col-12 border-bottom mb-4">
    <h6 class="fw-700 p-4 mb-0">Basic Information</h6>
  </div>
  <form
    class="col-12 row g-4"
    [formGroup]="profileForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="col-6">
      <label for="first-name" class="form-label fw-700 font-14"
        >First Name</label
      >
      <input
        type="text"
        class="form-control"
        id="first-name"
        placeholder="First Name"
        formControlName="first_name"
      />
    </div>
    <div class="col-6">
      <label for="last-name" class="form-label fw-700 font-14">Last Name</label>
      <input
        type="text"
        class="form-control"
        id="last-name"
        placeholder="Last Name"
        formControlName="last_name"
      />
    </div>
    <div class="col-6">
      <label for="email" class="form-label fw-700 font-14">Email</label>
      <input
        type="text"
        class="form-control"
        id="email"
        placeholder="Email"
        formControlName="email"
      />
    </div>
    <div class="col-6">
      <label class="form-label fw-700 font-14">Country</label>
      <button
        class="input-border dropdown text-start"
        (click)="selectDropdown($event)"
        [ngClass]="{ 'text-muted': !profileForm.value.country }"
      >
        {{ profileForm.value.country || "Select Country" }}
        <img [src]="selectedCountry?.flag" alt="country" />
        <div class="dropdown-options">
          <div
            class="dropdown-option"
            *ngFor="let country of globals.countries"
            (click)="setCountry(country)"
          >
            {{ country.name }}
            <img [src]="country.flag" alt="country" class="me-2" />
          </div>
        </div>
      </button>
    </div>
    <div class="col-6">
      <label class="form-label fw-700 font-14">State/Province</label>
      <button
        class="input-border dropdown text-start"
        (click)="selectDropdown($event)"
        [ngClass]="{ 'text-muted': !profileForm.value.state }"
      >
        {{ profileForm.value.state || "Select State" }}
        <div class="dropdown-options">
          <div
            class="dropdown-option"
            *ngFor="let state of globals.states"
            (click)="setState(state)"
          >
            {{ state.name }}
          </div>
        </div>
      </button>
    </div>
    <div class="col-6">
      <label class="form-label fw-700 font-14">City</label>
      <button
        class="input-border dropdown text-start"
        (click)="selectDropdown($event)"
        [ngClass]="{ 'text-muted': !profileForm.value.city }"
      >
        {{ profileForm.value.city || "Select City" }}
        <div class="dropdown-options">
          <div
            class="dropdown-option"
            *ngFor="let city of cities"
            (click)="setCity(city)"
          >
            {{ city.name }}
          </div>
        </div>
      </button>
    </div>
    <div class="col-12">
      <label for="aboutme" class="form-label fw-700 font-14">About me</label>
      <textarea
        class="form-control"
        name="about_me"
        formControlName="about_me"
        id="aboutme"
        placeholder="Enter description"
        rows="5"
      ></textarea>
    </div>
    <div class="col-12 text-end mb-4">
      <swap-button
        type="submit"
        text="Save Changes"
        [ngStyle]="{
          width: '100%',
          height: '50px',
          'max-width': '159px'
        }"
      ></swap-button>
    </div>
  </form>
</div>
