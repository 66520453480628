<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header border-0 mx-3">
    <h2 class="modal-title fs-5 fw-bolder" id="staticBackdropLabel">Filter</h2>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="filterForm.reset()"
      #closeModal
    ></button>
  </div>
  <div class="modal-body mx-3">
    <form [formGroup]="filterForm" (ngSubmit)="$event.preventDefault()">
      <div class="mb-3">
        <label for="" class="form-label">Rate</label>
        <div class="row g-0 gx-3">
          <div class="col-6">
            <input
              type="number"
              name="minRate"
              class="form-control"
              placeholder="min"
              formControlName="minRate"
            />
          </div>
          <div class="col-6">
            <input
              type="number"
              name="maxRate"
              class="form-control"
              placeholder="max"
              formControlName="maxRate"
            />
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label for="" class="form-label">Date Posted</label>
        <div class="d-flex align-items-center gap-3">
          <custom-calendar
            label="Date"
            buttonLabel="Apply"
            [style]="{
              width: '100%',
              'font-size': '0.75rem'
            }"
            [value]="filterForm.value.dateFrom || ''"
            (emitValue)="setDateValue($event, 'start')"
            (emitDate)="setDate($event, 'start')"
            placeholder="From"
          ></custom-calendar>
          <custom-calendar
            label="Date"
            buttonLabel="Apply"
            (emitValue)="setDateValue($event, 'end')"
            (emitDate)="setDate($event, 'end')"
            [style]="{
              width: '100%',
              'font-size': '0.75rem'
            }"
            [value]="filterForm.value.dateTo || ''"
            placeholder="To"
            calendarStyle="right: 0; left: auto;"
          ></custom-calendar>
        </div>
      </div>

      <div class="mb-3">
        <label for="" class="form-label">Location</label>
        <div
          class="form-input select-dropdown"
          (click)="toggleSelectDropdown($event)"
        >
          <span>
            {{ filterForm.value.country || "-select-" }}
          </span>
          <div class="select-dropdown-options">
            <div
              class="select-dropdown-option"
              *ngFor="let country of globals.countries"
              (click)="setFilterData($event, country.name, 'location')"
            >
              <img [src]="country.flag" class="flags" />
              {{ country.name }}
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label">Payment Method</label>
        <div
          class="form-input select-dropdown"
          (click)="toggleSelectDropdown($event)"
        >
          <span>
            {{ filterForm.value.paymentMethod || "-select-" }}
          </span>
          <div class="select-dropdown-options">
            <div
              class="select-dropdown-option"
              *ngFor="let payment of paymentOptions"
              (click)="setFilterData($event, payment, 'payment_method')"
            >
              {{ payment }}
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label">Online Status</label>
        <div
          class="form-input select-dropdown"
          (click)="toggleSelectDropdown($event)"
        >
          <span>
            {{
              filterForm.value.online == true
                ? onlineOptions[0]
                : onlineOptions[1] || "-select-"
            }}
          </span>
          <div class="select-dropdown-options">
            <div
              class="select-dropdown-option"
              *ngFor="let online of onlineOptions"
              (click)="setFilterData($event, online, 'online_status')"
            >
              {{ online }}
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label">Ratings</label>
        <div
          class="form-input select-dropdown"
          (click)="toggleSelectDropdown($event)"
        >
          <span>
            {{
              filterForm.value.ratings
                ? filterForm.value.ratings + " star"
                : "select"
            }}
          </span>
          <div class="select-dropdown-options">
            <div
              class="select-dropdown-option"
              *ngFor="let rate of ratingOptions"
              (click)="setFilterData($event, rate, 'rating')"
            >
              {{ rate }}
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label">Verification Status</label>
        <div
          class="form-input select-dropdown"
          (click)="toggleSelectDropdown($event)"
        >
          <span>
            {{ filterForm.value.verificationStatus || "-select-" }}
          </span>
          <div class="select-dropdown-options">
            <div
              class="select-dropdown-option"
              *ngFor="let status of verificationOptions"
              (click)="setFilterData($event, status, 'verification_status')"
            >
              {{ status }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer border-0 p-0 py-2 mx-3 justify-content-center">
    <button
      type="button"
      class="btn btn-primary py-3"
      (click)="onSubmit()"
      [disabled]="!filterForm.value || checkIfEmpty()"
    >
      Apply filter
    </button>
    <a
      href="#"
      class="text-decoration-none reset my-3"
      (click)="$event.preventDefault(); resetForm()"
      >Reset <img src="assets/icons/reset.svg" class="ms-2"
    /></a>
  </div>
</swap-modal>
