<swap-modal
  [modalId]="modalId"
  [scrollable]="true"
  [right]="true"
  [size]="'lg'"
>
  <div class="modal-header border-bottom py-4">
    <h6 class="modal-title fw-bolder" id="staticBackdropLabel">Activity log</h6>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeModal
    ></button>
  </div>
  <div class="modal-body">
    <ng-container *ngFor="let log of kycLogs">
      <p class="mb-0 text-center text-muted mb-3">{{ log.date }}</p>
      <div class="row g-0 gy-3 mb-5">
        <div
          class="col-12 d-flex align-items-center justify-content-between gap-5 border-bottom pb-2"
          *ngFor="let item of log.items"
        >
          <p
            class="mb-0 fw-500"
            [innerHTML]="globals.replaceCurlies(item.log)"
          ></p>
          <small class="mb-0 text-end" style="white-space: nowrap">
            {{globals.formatDateAndTimestamp (item.createdAt)}}</small
          >
        </div>
      </div>
    </ng-container>
    <no-data *ngIf="kycLogs.length == 0" message="No KYC Activity"></no-data>
  </div>
</swap-modal>
