<div class="row g-0 p-2 px-4 bg-white">
  <div
    class="col-12 border-bottom d-flex justify-content-center align-items-center p-4 logo-sticky"
  >
    <img
      src="assets/images/logo.svg"
      alt="Expatswap Logo"
      class="img-fluid logo"
      *ngIf="!collapsible"
      (click)="toggleSidebar()"
    />
    <img
      src="assets/images/logoIcon.svg"
      alt="Expatswap Logo"
      class="icon logo"
      *ngIf="collapsible"
      (click)="toggleSidebar()"
    />
  </div>
  <div class="col-12">
    <nav class="container-fluid g-0 py-3">
      <li
        class="nav-item my-2"
        *ngFor="let item of navigations | slice : 0 : -2"
      >
        <a
          class="nav-link py-3"
          [routerLink]="item.link"
          [routerLinkActive]="['active']"
          data-bs-tooltip="tooltip"
          data-bs-placement="right"
          title="{{ item.name }}"
          (click)="emitCollapsed.emit(true)"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            [innerHTML]="item.icon"
          ></svg>
          <p class="mb-0">
            {{ item.name }}
          </p>
          <span class="badge rounded-circle ms-auto" *ngIf="item.notifications">
            {{ item.notifications }}
          </span>
        </a>
      </li>

      <div class="mt-5 pt-5">
        <li
          class="nav-item my-2"
          *ngFor="let item of navigations | slice : -2 : -1"
        >
          <a
            class="nav-link py-3"
            [routerLink]="item.link"
            [routerLinkActive]="['active']"
            data-bs-tooltip="tooltip"
            data-bs-placement="right"
            title="{{ item.name }}"
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              [innerHTML]="item.icon"
            ></svg>
            <p class="mb-0">
              {{ item.name }}
            </p>
          </a>
        </li>
        <li class="nav-item my-2" *ngFor="let item of navigations | slice : -1">
          <a
            class="nav-link py-3"
            (click)="logout()"
            data-bs-tooltip="tooltip"
            data-bs-placement="right"
            title="{{ item.name }}"
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              [innerHTML]="item.icon"
            ></svg>
            <p class="mb-0">
              {{ item.name }}
            </p>
          </a>
        </li>
      </div>
    </nav>
  </div>
</div>
