<swap-modal [modalId]="modalId" [centered]="true">
  <div class="row m-3">
    <div class="col-12 d-flex align-items-center justify-content-between mb-3">
      <h5 class="modal-title mb-0 fw-bolder" id="exampleModalLabel">
        Choose time
      </h5>
      <button
        type="button"
        class="closeBtn"
        data-bs-dismiss="modal"
        aria-label="Close"
        #closeBtn
      >
        <img loading="lazy" src="assets/icons/close-icon.svg" alt="" />
      </button>
    </div>
    <div class="col-12 mb-2">
      <p class="mb-2">Choose from the time below to extend negotiation</p>
      <div class="d-flex align-items-center gap-2 justify-content-center">
        <button
          class="timerBtn"
          *ngFor="let time of timers"
          [ngClass]="{ active: selectedTimer == time }"
          (click)="selectedTimer = time"
        >
          <img loading="lazy" [src]="time.img" alt="" />
          <p class="mb-0">
            {{ time.value }}
            mins
          </p>
        </button>
      </div>
      <p class="mb-0 text-center my-2">Or</p>
      <div class="col-9 mx-auto">
        <label for="timeExtention" class="mb-1 fw-700">
          Enter time in minutes
        </label>
        <input
          type="number"
          id="timeExtention"
          class="input-border"
          max="1440"
          min="0"
          [(ngModel)]="timeExtention"
        />
      </div>
      <div class="d-flex align-items-center justify-content-end gap-2 mt-4">
        <button
          (click)="selectedTimer = null; closeBtn.click()"
          class="submit-btn fw-700 font-13"
        >
          Cancel
        </button>
        <button
          class="submit-btn update fw-700 font-13"
          (click)="chooseTimer()"
          [disabled]="!selectedTimer && timeExtention <= 0"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</swap-modal>
