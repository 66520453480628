import { Component, Input } from '@angular/core';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'kyc-activity-modal',
  templateUrl: './kyc-activity-modal.component.html',
  styleUrls: ['./kyc-activity-modal.component.scss'],
})
export class KycActivityModalComponent {
  @Input() modalId: string = '';

  @Input() kycLogs: any[] = [];

  constructor(
    public globals: GlobalsService
  ){}

  ngOnChanges() {
    this.kycLogs = this.groupItemsByDate(this.kycLogs);
  }

  groupItemsByDate(items: any[]) {
    const groupedItems: any = {};

    items.forEach((item) => {
      // Extract the date part (ignoring the time)
      let date = new Date(item.createdAt).toISOString().split('T')[0];

      if (date == new Date().toISOString().split('T')[0]) {
        date = 'Today';
      }

      if (date == new Date(Date.now() - 86400000).toISOString().split('T')[0]) {
        date = 'Yesterday';
      }

      if (!groupedItems[date]) {
        groupedItems[date] = [];
      }

      groupedItems[date].push(item);
    });

    const result = [];

    for (const date in groupedItems) {
      if (groupedItems.hasOwnProperty(date)) {
        result.push({
          date: date,
          items: groupedItems[date],
        });
      }
    }

    return result;
  }
}
