<app-navbar></app-navbar>

<div class="py-3 w-100" id="carousel">
  <owl-carousel-o [options]="customOptions" class="card-container">
    <ng-template carouselSlide>
      <div class="card total_cards" routerLink="/main/users">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="icon-container">
              <img
                src="assets/icons/users.svg"
                class="card-img-top"
                alt="..."
              />
            </div>
          </div>
          <h6 class="card-title text-muted fw-light mb-3">Total Users</h6>
          <h5 class="card-text">
            <strong>
              {{ swapUserService.totalNoOfSwapUsers }}
            </strong>
          </h5>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="card total_cards" routerLink="/main/offers">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="icon-container">
              <img
                src="assets/icons/ticket.svg"
                class="card-img-top"
                alt="..."
              />
            </div>
          </div>
          <h6 class="card-title text-muted fw-light mb-3">Total Offers</h6>
          <h5 class="card-text">
            <strong>{{ transactionsService.totalNoOfOffers | number }}</strong>
          </h5>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="card total_cards">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="icon-container">
              <img
                src="assets/icons/add-user.svg"
                class="card-img-top"
                alt="..."
              />
            </div>
            <button class="card-dropdown me-3" (click)="selectDropdown($event)">
              <img src="assets/icons/three-dots.svg" alt="" />
              <div class="card-dropdown-options">
                <a
                  (click)="getNewSignUpsCount('daily')"
                  class="card-dropdown-option text-decoration-none text-muted"
                  >Daily</a
                >
                <a
                  (click)="getNewSignUpsCount('weekly')"
                  class="card-dropdown-option text-decoration-none text-muted"
                  >Weekly</a
                >
                <a
                  (click)="getNewSignUpsCount('monthly')"
                  class="card-dropdown-option text-decoration-none text-muted"
                  >Monthly</a
                >
                <a
                  href="#"
                  class="card-dropdown-option text-decoration-none text-muted"
                  >Yearly</a
                >
              </div>
            </button>
          </div>
          <h6 class="card-title text-muted fw-light mb-3">New signups</h6>
          <h5 class="card-text">
            <strong>
              {{ swapUserService.totalAvgNewSignUps }}
            </strong>
          </h5>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="card total_cards">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="icon-container">
              <img
                src="assets/icons/add-user.svg"
                class="card-img-top"
                alt="..."
              />
            </div>
            <a
              href="#"
              class="card-dropdown me-3"
              (click)="selectDropdown($event)"
            >
              <img src="assets/icons/three-dots.svg" alt="" />
              <div class="card-dropdown-options">
                <a
                  href="#"
                  class="card-dropdown-option text-decoration-none text-muted"
                  >Daily</a
                >
                <a
                  href="#"
                  class="card-dropdown-option text-decoration-none text-muted"
                  >Weekly</a
                >
                <a
                  href="#"
                  class="card-dropdown-option text-decoration-none text-muted"
                  >Monthly</a
                >
                <a
                  href="#"
                  class="card-dropdown-option text-decoration-none text-muted"
                  >Yearly</a
                >
              </div>
            </a>
          </div>
          <h6 class="card-title text-muted fw-light mb-3">Total referrals</h6>
          <h5 class="card-text">
            <strong>
              {{ swapUserService.totalNoOfReferrals }}
            </strong>
          </h5>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div
        class="card total_cards"
        routerLink="/main/users"
        [queryParams]="{ status: 'online' }"
      >
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="icon-container">
              <img src="assets/icons/exit.svg" class="card-img-top" alt="..." />
            </div>
          </div>
          <h6 class="card-title text-muted fw-light mb-3">Active users</h6>
          <h5 class="card-text">
            <strong>{{ swapUserService.totalNoOfActiveSwapUsers }}</strong>
          </h5>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="card total_cards" routerLink="/main/transactions">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="icon-container">
              <img
                src="assets/icons/wallet.svg"
                class="card-img-top"
                alt="..."
              />
            </div>
            <a
              href="#"
              class="card-dropdown me-3"
              (click)="selectDropdown($event)"
            >
              <img src="assets/icons/three-dots.svg" alt="" />
              <div class="card-dropdown-options">
                <a
                  href="#"
                  class="card-dropdown-option text-decoration-none text-muted"
                  >Daily</a
                >
                <a
                  href="#"
                  class="card-dropdown-option text-decoration-none text-muted"
                  >Weekly</a
                >
                <a
                  href="#"
                  class="card-dropdown-option text-decoration-none text-muted"
                  >Monthly</a
                >
                <a
                  href="#"
                  class="card-dropdown-option text-decoration-none text-muted"
                  >Yearly</a
                >
              </div>
            </a>
          </div>
          <h6 class="card-title text-muted fw-light mb-3">
            Total Transactions
          </h6>
          <h5 class="card-text">
            <strong>
              {{ transactionsService.totalNoOfTransactions }}
            </strong>
          </h5>
        </div>
      </div>
    </ng-template>
  </owl-carousel-o>
</div>

<div class="row g-4">
  <div class="col-8 d-flex justify-content-center flex-column gap-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <h5 class="fw-bold">Statistics</h5>
          <div class="d-flex justify-content-between align-items-center gap-4">
            <div class="date-dropdown">
              <h6 class="fw-bold mb-0">Income</h6>
              <img src="assets/icons/chevron-down.svg" alt="..." />
            </div>
            <div class="date-dropdown">
              <h6 class="fw-bold mb-0">Jan 10 - Jan 16</h6>
              <img src="assets/icons/chevron-down.svg" alt="..." />
            </div>
          </div>
        </div>
        <canvas id="lineChart" width="100%" height="50"></canvas>
      </div>
    </div>
    <div class="col-7">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <h5 class="fw-bold">Traffic Sources</h5>
            <div
              class="d-flex justify-content-between align-items-center gap-4"
            >
              <div class="date-dropdown">
                <h6 class="fw-bold mb-0">This month</h6>
                <img
                  src="assets/icons/chevron-down.svg"
                  alt="..."
                  style="rotate: 270deg"
                />
              </div>
            </div>
          </div>
          <div class="bg-muted row g-0 p-4 justify-content-between">
            <div class="col-5 d-flex justify-content-center align-items-center">
              <canvas
                id="doughnutChart"
                style="height: 50vh; width: 80vw"
              ></canvas>
            </div>
            <div class="col-5">
              <div class="d-flex align-items-center gap-2 mb-4">
                <span class="dot dark"></span>
                <div>
                  <p class="mb-0 fw-bolder">725</p>
                  <p class="mb-0 text-muted">Organic Search</p>
                </div>
              </div>
              <div class="d-flex align-items-center gap-2 mb-4">
                <span class="dot purple"></span>
                <div>
                  <p class="mb-0 fw-bolder">725</p>
                  <p class="mb-0 text-muted">Affiliate</p>
                </div>
              </div>
              <div class="d-flex align-items-center gap-2 mb-4">
                <span class="dot pink"></span>
                <div>
                  <p class="mb-0 fw-bolder">725</p>
                  <p class="mb-0 text-muted">Social Media</p>
                </div>
              </div>
              <div class="d-flex align-items-center gap-2 mb-4">
                <span class="dot torquise"></span>
                <div>
                  <p class="mb-0 fw-bolder">725</p>
                  <p class="mb-0 text-muted">Others</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="col-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center mb-4">
          <h5 class="fw-bold">Top Seller</h5>
          <div class="d-flex justify-content-end align-items-center gap-4">
            <div class="date-dropdown">
              <h6 class="fw-bold mb-0">Weekly</h6>
              <img src="assets/icons/chevron-down.svg" alt="..." />
            </div>
          </div>
        </div>
        <div class="row gap-4 mb-5">
          <div class="col-12 d-flex align-items-center gap-3">
            <div class="img-container">
              <img
                src="assets/images/gitkingimg5.svg"
                class="img-fluid rounded-circle"
                width="60"
                alt=""
              />
            </div>
            <div class="row g-0 w-100">
              <div
                class="col-12 d-flex align-items-center justify-content-between w-100"
              >
                <h6><b> Githubking1 </b></h6>
                <h6><b> $1,243.00 </b></h6>
              </div>
              <div
                class="col-12 d-flex align-items-center justify-content-between w-100"
              >
                <p class="mb-0">
                  <span class="text-muted d-flex align-items-center gap-1">
                    <b class="text-warning d-flex align-items-center gap-1">
                      <img src="assets/images/star.svg" alt="..." />
                      4.5</b
                    >
                    (21)</span
                  >
                </p>
                <p class="mb-0 text-muted">25 deals</p>
              </div>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center gap-3">
            <div class="img-container">
              <img
                src="assets/images/gitkingimg5.svg"
                class="img-fluid rounded-circle"
                width="60"
                alt=""
              />
            </div>
            <div class="row g-0 w-100">
              <div
                class="col-12 d-flex align-items-center justify-content-between w-100"
              >
                <h6><b> Githubking1 </b></h6>
                <h6><b> $1,243.00 </b></h6>
              </div>
              <div
                class="col-12 d-flex align-items-center justify-content-between w-100"
              >
                <p class="mb-0">
                  <span class="text-muted d-flex align-items-center gap-1">
                    <b class="text-warning d-flex align-items-center gap-1">
                      <img src="assets/images/star.svg" alt="..." />
                      4.5</b
                    >
                    (21)</span
                  >
                </p>
                <p class="mb-0 text-muted">25 deals</p>
              </div>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center gap-3">
            <div class="img-container">
              <img
                src="assets/images/gitkingimg5.svg"
                class="img-fluid rounded-circle"
                width="60"
                alt=""
              />
            </div>
            <div class="row g-0 w-100">
              <div
                class="col-12 d-flex align-items-center justify-content-between w-100"
              >
                <h6><b> Githubking1 </b></h6>
                <h6><b> $1,243.00 </b></h6>
              </div>
              <div
                class="col-12 d-flex align-items-center justify-content-between w-100"
              >
                <p class="mb-0">
                  <span class="text-muted d-flex align-items-center gap-1">
                    <b class="text-warning d-flex align-items-center gap-1">
                      <img src="assets/images/star.svg" alt="..." />
                      4.5</b
                    >
                    (21)</span
                  >
                </p>
                <p class="mb-0 text-muted">25 deals</p>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center mb-4">
          <h5 class="fw-bold">Top Buyer</h5>
          <div class="d-flex justify-content-end align-items-center gap-4">
            <div class="date-dropdown">
              <h6 class="fw-bold mb-0">Weekly</h6>
              <img src="assets/icons/chevron-down.svg" alt="..." />
            </div>
          </div>
        </div>
        <div class="row gap-4">
          <div class="col-12 d-flex align-items-center gap-3">
            <div class="img-container">
              <img
                src="assets/images/gitkingimg5.svg"
                class="img-fluid rounded-circle"
                width="60"
                alt=""
              />
            </div>
            <div class="row g-0 w-100">
              <div
                class="col-12 d-flex align-items-center justify-content-between w-100"
              >
                <h6><b> Githubking1 </b></h6>
                <h6><b> $1,243.00 </b></h6>
              </div>
              <div
                class="col-12 d-flex align-items-center justify-content-between w-100"
              >
                <p class="mb-0">
                  <span class="text-muted d-flex align-items-center gap-1">
                    <b class="text-warning d-flex align-items-center gap-1">
                      <img src="assets/images/star.svg" alt="..." />
                      4.5</b
                    >
                    (21)</span
                  >
                </p>
                <p class="mb-0 text-muted">25 deals</p>
              </div>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center gap-3">
            <div class="img-container">
              <img
                src="assets/images/gitkingimg5.svg"
                class="img-fluid rounded-circle"
                width="60"
                alt=""
              />
            </div>
            <div class="row g-0 w-100">
              <div
                class="col-12 d-flex align-items-center justify-content-between w-100"
              >
                <h6><b> Githubking1 </b></h6>
                <h6><b> $1,243.00 </b></h6>
              </div>
              <div
                class="col-12 d-flex align-items-center justify-content-between w-100"
              >
                <p class="mb-0">
                  <span class="text-muted d-flex align-items-center gap-1">
                    <b class="text-warning d-flex align-items-center gap-1">
                      <img src="assets/images/star.svg" alt="..." />
                      4.5</b
                    >
                    (21)</span
                  >
                </p>
                <p class="mb-0 text-muted">25 deals</p>
              </div>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center gap-3">
            <div class="img-container">
              <img
                src="assets/images/gitkingimg5.svg"
                class="img-fluid rounded-circle"
                width="60"
                alt=""
              />
            </div>
            <div class="row g-0 w-100">
              <div
                class="col-12 d-flex align-items-center justify-content-between w-100"
              >
                <h6><b> Githubking1 </b></h6>
                <h6><b> $1,243.00 </b></h6>
              </div>
              <div
                class="col-12 d-flex align-items-center justify-content-between w-100"
              >
                <p class="mb-0">
                  <span class="text-muted d-flex align-items-center gap-1">
                    <b class="text-warning d-flex align-items-center gap-1">
                      <img src="assets/images/star.svg" alt="..." />
                      4.5</b
                    >
                    (21)</span
                  >
                </p>
                <p class="mb-0 text-muted">25 deals</p>
              </div>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center gap-3">
            <div class="img-container">
              <img
                src="assets/images/gitkingimg5.svg"
                class="img-fluid rounded-circle"
                width="60"
                alt=""
              />
            </div>
            <div class="row g-0 w-100">
              <div
                class="col-12 d-flex align-items-center justify-content-between w-100"
              >
                <h6><b> Githubking1 </b></h6>
                <h6><b> $1,243.00 </b></h6>
              </div>
              <div
                class="col-12 d-flex align-items-center justify-content-between w-100"
              >
                <p class="mb-0">
                  <span class="text-muted d-flex align-items-center gap-1">
                    <b class="text-warning d-flex align-items-center gap-1">
                      <img src="assets/images/star.svg" alt="..." />
                      4.5</b
                    >
                    (21)</span
                  >
                </p>
                <p class="mb-0 text-muted">25 deals</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




<div class="row g-4 mt-5">
  <div class="col-lg-8">
    <div class="card">
      <div class="mb-2">
        <h5 class="fw-bold ps-3 pt-4">Active Users</h5>
      </div>
      <div class="card-body">
        <div class="chart-s bg-muted">
          <iframe width="100%" height="450" src="https://lookerstudio.google.com/embed/reporting/4898f909-e772-45ff-be99-2d7850286739/page/KZI5D" frameborder="0" style="border:0" allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-4 d-flex flex-column">
    <div class="card mb-4 flex-grow-1">
      
      <div class="mb-2">
        <h5 class="fw-bold ps-3 pt-4">Top Countries</h5>
      </div>
      <div class="card-body">
        <div style="height: 100%;">
          <iframe width="100%" height="100%" src="https://lookerstudio.google.com/embed/reporting/3298509f-f85c-4d27-b67c-fd905a083c13/page/M2I5D" frameborder="0" style="border:0" allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        </div>
      
      </div>
    </div>

    <div class="card flex-grow-1">
      <div class="mb-2">
        <h5 class="fw-bold ps-3 pt-4">Operating System</h5>
      </div>
      <div class="card-body">
        <div style="height: 100%;">
          <iframe width="100%" height="100%" src="https://lookerstudio.google.com/embed/reporting/f0078466-9774-404e-8eb4-27d874242959/page/ujI5D" frameborder="0" style="border:0" allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        </div>
       
      </div>
    </div>
  </div>
</div>




<!-- <div class="col-7 mt-5" style="width: 500px;">
 
  <div class="card">
    <div class="mb-2">
    <h5 class="fw-bold ps-3 pt-4">
      Device Category</h5>
    </div>
    <div class="card-body">
<div class="chart-s bg-muted ">
  <div *ngIf="isLoading" class="spinner-overlay">
  </div>
  
  <iframe 
    width="100%" 
    height="380" 
    src="https://lookerstudio.google.com/embed/reporting/01d18c69-9cf2-42a3-8d23-f0f86e9882f9/page/kIV1C" 
    frameborder="0" 
    style="border:0" 
    allowfullscreen 
    sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
    (load)="onIframeLoad()">
  </iframe>
</div>
</div>
</div>
</div>

<div class="col-7 mt-5" style="width: 500px;">
 
  <div class="card">
    <div class="mb-2">
    <h5 class="fw-bold ps-3 pt-4">
      Top Pages</h5>
    </div>
    <div class="card-body">
<div class="chart-s bg-muted ">
  <div *ngIf="isLoading" class="spinner-overlay">
  </div>
  
  <iframe width="100%" height="380" src="https://lookerstudio.google.com/embed/reporting/a60baa19-89e0-438b-a259-94b0552ac63d/page/uBJ5D" frameborder="0" style="border:0" allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>


</div>
</div>
</div>
</div> -->

<div class="row g-4 mt-5" style="height: 500px;">
  <!-- Device Category Card -->
  <div class="col-lg-6" >
    <div class="card">
      <div class="mb-2">
        <h5 class="fw-bold ps-3 pt-4">Device Category</h5>
      </div>
      <div class="card-body">
        <div class="chart-s bg-muted">
          <div *ngIf="isLoading" class="spinner-overlay"></div>
          <iframe 
            width="100%" 
            height="380" 
            src="https://lookerstudio.google.com/embed/reporting/01d18c69-9cf2-42a3-8d23-f0f86e9882f9/page/kIV1C" 
            frameborder="0" 
            style="border:0" 
            allowfullscreen 
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
            (load)="onIframeLoad()">
          </iframe>
        </div>
      </div>
    </div>
  </div>

  <!-- Top Pages Card -->
  <div class="col-lg-6">
    <div class="card">
      <div class="mb-2">
        <h5 class="fw-bold ps-3 pt-4">Top Pages</h5>
      </div>
      <div class="card-body">
        <div class="chart-s bg-muted">
          <div *ngIf="isLoading" class="spinner-overlay"></div>
          <iframe 
            width="100%" 
            height="380" 
            src="https://lookerstudio.google.com/embed/reporting/a60baa19-89e0-438b-a259-94b0552ac63d/page/uBJ5D" 
            frameborder="0" 
            style="border:0" 
            allowfullscreen 
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox">
          </iframe>
        </div>
      </div>
    </div>
  </div>
</div>

