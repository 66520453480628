import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Country, State, User, Notification } from './IApp';
import { SpinnerService } from './spinner';
import { StorageService } from './storage';
import { ToasterService } from './toaster';
import { NotificationService } from './notification';
import { DatePipe } from '@angular/common';
@Injectable({
  providedIn: 'root',
})
export class GlobalsService {
  user: User | undefined;
  countries: Country[] = [];
  states: State[] = [];
  utc_offset: string = '';
  ticketTotal: number = 0;
  notifications: Notification[] = [];
  unreadMessages: boolean = false;
  numberOfUnreadMessages: number = 0;
  keywords = {
    offerRequests: [
      'ongoing-bidding',
      'offer-archived',
      'bidding-completed',
      'bidding-offer-declined',
      'bidding-offer-cancelled',
      'bidding-offer-approved',
      'bidding-offer-halted',
      'new-bidding-offer',
      'insufficient-bidding-offer-amount',
      'ticket_update'
    ],
    ratingsReviews: ['ratings-reviews'],
    ticket_update: ['ticket_update', 'ticket-updated', 'ticket_closed'],
  };
  constructor(
    public storage: StorageService,
    public router: Router,
    public toast: ToasterService,
    public spinner: SpinnerService,
    public activatedRoute: ActivatedRoute,
    public notification: NotificationService,
    private datePipe: DatePipe
  ) {
    this.user = (this.storage.getUserDetails() as User) || undefined;
    this.countries = this.storage.getCountries() || [];
    this.utc_offset = this.storage.getUserLocationDetails()?.utc_offset || '';
    this.ticketTotal = this.storage.getTicketTotal() || 0;
  }

  generateRatingSVG(ratings: number, userRatings: { id: string; stop: any }[]) {
    var gold = '#EAAC30';
    var rating_ceil = Math.ceil(ratings);
    for (let star_index = 0; star_index <= rating_ceil; star_index++) {
      var linearGrad: { id: string; stop: any } = {
        id: `star${star_index + 1}`,
        stop: [],
      };
      if (ratings - star_index >= 1) {
        linearGrad.stop = [
          { offset: '0%', stopColor: gold },
          { offset: '100%', stopColor: gold },
        ];
      } else if (ratings - star_index >= 0.5) {
        linearGrad.stop = [
          { offset: '0%', stopColor: gold },
          { offset: '50%', stopColor: gold },
          { offset: '50%', stopColor: 'transparent' },
          { offset: '100%', stopColor: 'transparent' },
        ];
      }
      userRatings.push(linearGrad);
    }
  }

  loggedOut() {
    this.storage.logOutUser();
  }

  isLoggedIn() {
    return this.storage.isAuthenticated();
  }

  searchCountryBy(search: string | any, type: string): any {
    switch (type) {
      case 'name':
        return search
          ? this.countries.find(
              (country) =>
                country.name.toLocaleLowerCase() === search.toLocaleLowerCase()
            )
          : null;
      case 'code':
        return this.countries.find(
          (country) =>
            country.currency.toLocaleLowerCase() == search.toLocaleLowerCase()
        );
      default:
        return null;
    }
  }

  closeModal(modalId?: string) {
    if (modalId) {
      let closeBtn = document.querySelector(
        `${modalId} .closeBtn`
      ) as HTMLElement;
      if (closeBtn) closeBtn.click();
    } else {
      // remove modal backdrop
      const element = document.querySelector('.modal-backdrop');
      if (element) element.remove();

      // remove modal open class
      const element2 = document.querySelector('.modal-open');

      if (element2) {
        element2.classList.remove('modal-open');
        element2.removeAttribute('style');
      }
    }
  }

  replaceCurlies(string: string): string {
    return string
      .replace(/{{/g, '<span class="text-primary">')
      .replace(/}}/g, '</span>');
  }

  openModal(modalId: string) {
    const button = document.createElement('button');
    button.setAttribute('type', 'button');
    button.setAttribute('style', 'display: none;');
    button.setAttribute('data-bs-toggle', 'modal');
    button.setAttribute('data-bs-target', `${modalId}`);
    document.body.appendChild(button);
    button.click();
  }

  scrollToBottom() {
    const element = document.getElementById('messages') as HTMLElement;
    setTimeout(() => {
      element.scrollTop = element.scrollHeight;
    }, 100);
  }



  navigateByTrigger(trigger: string | undefined) {
    switch (trigger) {
      case 'admin_notification':
        this.router.navigateByUrl('/main/notifications');
        break;
        case 'ticket_update':
          this.router.navigateByUrl(
            '/main/transaction/'
          );
          break;
      default:
        break;
    }
  }
  
  getWindowFocus(): boolean {
    return window.document.hasFocus();
  }

  checkForNotifications(settings: any, notification: Notification | any) {
    // Object.keys(settings).forEach((key) => {
      // if (!settings[key].push) return;
      // if (key == 'offerRequests' || key == 'ratingsReviews' || key == 'ticket_update') {
        // if (this.includesAll(notification.trigger, this.keywords[key])) {
          this.notification.send('toast', notification);
          if (!this.getWindowFocus()) {
            this.notification.send('browser', notification);
          } 
        // }
      // } 
      // else if (key == 'messages') {
      //   if (notification == 'message') {
      //     if (!this.getWindowFocus()) {
      //       this.notification.send('sound', 'message');
      //     }
      //   }
      // }
    // });
  }

  private includesAll(trigger: string, keywords: Array<string>) {
    return keywords.filter((keyword) => trigger?.includes(keyword)).length > 0;
  }



  formatTimestamp(timestamp: string | Date | null): string {
    if (!timestamp) {
      return 'N/A';
    }
  
    let dateObj: Date;
    
    if (typeof timestamp === 'string') {
      dateObj = new Date(timestamp);
    } else {
      dateObj = timestamp;
    }
  
    if (isNaN(dateObj.getTime())) {
      return 'Invalid Date';
    }
    const localTime = this.datePipe.transform(dateObj, 'HH:mm:ss');
    const gmtOffset = dateObj.getTimezoneOffset();
    const sign = gmtOffset <= 0 ? '+' : '-';
    const hours = Math.abs(Math.floor(gmtOffset / 60)).toString().padStart(2, '0');
    const minutes = Math.abs(gmtOffset % 60).toString().padStart(2, '0');
    const gmtOffsetFormatted = `GMT${sign}${hours}:${minutes}`;
  
    return localTime + ' ' + gmtOffsetFormatted;
  }



  formatDateAndTimestamp(timestamp: string | Date | null): string {
    if (!timestamp) {
    return 'N/A';
    }
    let dateObj: Date;
    
    if (typeof timestamp === 'string') {
      dateObj = new Date(timestamp);
    } else {
      dateObj = timestamp;
    }
    
    if (isNaN(dateObj.getTime())) {
      return 'Invalid Date';
    }
    
    const localTime = this.datePipe.transform(dateObj, 'MMM d, y HH:mm:ss');
    const gmtOffset = dateObj.getTimezoneOffset();  
    const sign = gmtOffset <= 0 ? '+' : '-';
    const hours = Math.abs(Math.floor(gmtOffset / 60)).toString().padStart(2, '0');
    const minutes = Math.abs(gmtOffset % 60).toString().padStart(2, '0');
    const gmtOffsetFormatted =  `GMT${sign}${hours}:${minutes}`;
    
    return localTime + ' ' + gmtOffsetFormatted;
  
}


}