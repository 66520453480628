import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CountriesList, Transaction } from '../../core/IApp';
import { GlobalsService } from '../../core/globals';
import { RequestService } from '../../core/request';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  // Data Variables
  transaction: Transaction | any;
  transactions: Transaction[] = [];
  transactionHistory: Transaction[] = [];
  countryListings: CountriesList[] = [];
  offers: Array<Transaction> = [];
  offer: Transaction | any;
  messages: Array<any> = [];
  transactionMessageId: string = '';

  // Pagination
  paymentMethod: string = '';
  rating: string = '';
  currentResultPerPage = 5;
  page: any = 1;
  totalPages: Array<number | string> = [];
  resultPerPage = [5, 10, 15, 20, 25];

  offersPagination: any = {
    itemsPerPage: 5,
    totalItemsCount: 0,
    page: 1,
    dateFrom: '',
    dateTo: '',
    minRate: '',
    maxRate: '',
    paymentMethod: '',
    haveCurrency: '',
    wantCurrency: '',
    haveAmount: '',
    wantAmount: '',
    transactionStatus: '',
    myRate: '',
    country: '',
    search: '',
    ratings: 0,
    online: '',
    verificationStatus: '',
    sort: {
      field: '',
      order: 'desc',
    },
  };

  transactionPagination: any = {
    itemsPerPage: 5,
    totalItemsCount: 0,
    page: 1,
    search: '',
    biddingStatus: '',
    sort: {
      field: '',
      order: 'desc',
    },
  };

  historyPagination: any = {
    itemsPerPage: 5,
    totalItemsCount: 0,
    page: 1,
    search: '',
    biddingStatus: 'completed',
    sort: {
      field: '',
      order: 'desc',
    },
  };

  messagePagination = {
    page: 1,
    totalItemsCount: 0,
  };

  // Dashboard Variables
  totalNoOfTransactions: number = 0;
  totalNoOfOffers: number = 0;

  constructor(
    private api: RequestService,
    private globals: GlobalsService,
    private http: HttpClient
  ) {}

  async getAllTransactions() {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        let params = '';
        Object.keys(this.transactionPagination).forEach((key) => {
          if (key === 'sort') {
            params += `${key}=${this.transactionPagination[key].field}&order=${this.transactionPagination[key].order}&`;
          } else if (this.transactionPagination[key] !== ('' || 0)) {
            params += `${key}=${this.transactionPagination[key]}&`;
          }
        });
        const resp: any = await this.api.get(`biddings/all?${params}`);
        this.transactions = resp.data.biddings as Transaction[];
        this.transactionPagination.totalItemsCount = resp.data.totalItemsCount;
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async getAllTranxHistories(user_id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        let params = '';
        Object.keys(this.historyPagination).forEach((key) => {
          if (key === 'sort') {
            params += `${key}=${this.historyPagination[key].field}&order=${this.historyPagination[key].order}&`;
          } else if (this.historyPagination[key] !== ('' || 0)) {
            params += `${key}=${this.historyPagination[key]}&`;
          }
        });
        const resp: any = await this.api.get(
          `biddings/all/${user_id}?${params}`
        );
        this.transactionHistory = resp.data.biddings as Transaction[];
        this.historyPagination.totalItemsCount = resp.data.totalItemsCount;
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async getTransactionsCount() {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(`biddings/count`);
        this.totalNoOfTransactions = resp.data.count as number;
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async getAllOffersCount() {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(`offers/count`);
        this.totalNoOfOffers = resp.data.count as number;
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async getAllOffers() {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        let params = '';
        Object.keys(this.offersPagination).forEach((key) => {
          if (key === 'sort') {
            params += `${key}=${this.offersPagination[key].field}&order=${this.offersPagination[key].order}&`;
          } else if (this.offersPagination[key] !== ('' || 0)) {
            params += `${key}=${this.offersPagination[key]}&`;
          }
        });
        const resp: any = await this.api.get(`offers/all?${params}`);
        if (resp.message.toLowerCase() === 'no offers found') {
          this.globals.spinner.hide();
          this.globals.toast.error(resp.message);
          reject(resp);
        }
        this.offers = resp.data.offers as Transaction[];
        this.offersPagination.totalItemsCount = resp.data.totalItemsCount;
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async getOfferById(id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get(`offers/offer/${id}`);
        if (resp.message.toLowerCase() === 'no offers found') {
          this.globals.spinner.hide();
          this.globals.toast.error(resp.message);
          reject(resp);
          return;
        }
        resp.data.offer.sellerUser = resp.data.offer.sellerUserId;
        delete resp.data.offer.sellerUserId;
        this.offers = [resp.data.offer] as Transaction[];
        this.offersPagination.totalItemsCount = resp.data.totalItemsCount;
        this.globals.spinner.hide();
        resolve(resp.data.offer);
      } catch (err: any) {
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async updateOffer(id: string, offer: Transaction) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.patch(`offers/${id}`, {
          ...offer,
        });
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.toast.error(err);
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async getUploadProof(id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get(`messages/upload/${id}`);
        this.globals.spinner.hide();
        resolve(resp.data);
      } catch (err: any) {
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async getMessagesByBiddingId(id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get(
          `messages/${id}?page=${this.messagePagination.page}`
        );
        this.messages = resp.data.messages.sort((a: any, b: any) => {
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        });
        this.transaction = resp.data.offer;
        this.messagePagination.totalItemsCount = resp.data.messageslength;
        this.transactionMessageId = resp.data._id;
        this.globals.spinner.hide();
        resolve(resp.data);
      } catch (err: any) {
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async getTransactionById(id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get(`biddings/${id}`);
        this.transaction = resp.data;
        this.globals.spinner.hide();
        resolve(resp.data);
      } catch (err: any) {
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async getGlobalRate(want: string, have: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.http
          .get(`${environment.currencyApiBaseURL}${have}/${want}`)
          .toPromise();
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        reject(ex);
      }
    });
  }

  async extendNegotiationTime(
    messageId: string,
    biddingId: string,
    time: number
  ) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.post(
          `messages/extend-negotiation-time/${biddingId}`,
          {
            extendTimeMinutes: time,
            messageId,
          }
        );
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex.message || ex);
        reject(ex);
      }
    });
  }

  resetParams() {
    this.offersPagination = {
      itemsPerPage: 5,
      totalItemsCount: 0,
      page: 1,
      dateFrom: '',
      dateTo: '',
      minRate: '',
      maxRate: '',
      paymentMethod: '',
      haveCurrency: '',
      wantCurrency: '',
      haveAmount: '',
      wantAmount: '',
      transactionStatus: '',
      myRate: '',
      country: '',
      search: '',
      ratings: 0,
      online: '',
      verificationStatus: '',
      sort: {
        field: '',
        order: 'desc',
      },
    };

    this.transactionPagination = {
      itemsPerPage: 5,
      totalItemsCount: 0,
      page: 1,
      search: '',
      biddingStatus: '',
      sort: {
        field: '',
        order: 'desc',
      },
    };

    this.historyPagination = {
      itemsPerPage: 5,
      totalItemsCount: 0,
      page: 1,
      search: '',
      biddingStatus: 'completed',
      sort: {
        field: '',
        order: 'desc',
      },
    };

    this.offers = [];
    this.transactions = [];
    this.transactionHistory = [];
  }
}
