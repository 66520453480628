import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SwapUserService } from 'src/app/services/api/swap_user/swap-user.service';
import { Payment, Swap_User } from 'src/app/services/core/IApp';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'plan-history-modal',
  templateUrl: './plan-history-modal.component.html',
  styleUrls: ['./plan-history-modal.component.scss'],
})
export class PlanHistoryModalComponent {
  @Input() modalId: string = '';

  @Input() utc_offset: string = '';

  @Input() userPlanDetails: Swap_User | any;

  @Output() openPlanUpgradeModal: EventEmitter<string> =
    new EventEmitter<string>();

  filterOptions: string[] = [
    'All',
    'Payment',
    'Upgrade',
    'Cancellation',
    'Downgrade',
  ];

  modalId1: string = '#cancelPlanModal';

  modalId2: string = '#cancelPlanReasonModal';

  paymentHistory: any[] = [];

  activityLog: any[] = [];

  activityTerm: string = '';

  constructor(
    public globals: GlobalsService,
    public swapUserService: SwapUserService
  ) {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.paymentHistory = this.groupItemsByDate(
      this.userPlanDetails?.payments || []
    );
    this.activityLog = this.groupItemsByDate(
      this.userPlanDetails?.activityLog || [],
      'activityLog'
    );
  }

  selectDropdownFilter(event: any) {
    // close other dropdown options
    event.preventDefault();
    let mainElement = event.target;
    const element = document.querySelectorAll('.dropdown-filters');
    element.forEach((el) => {
      if (
        el.classList.contains('show') &&
        el !== event.target.nextElementSibling
      ) {
        el.classList.remove('show');
      }
    });

    if (mainElement.nodeName !== 'BUTTON') mainElement = mainElement.parentNode;

    // add show class to dropdown options
    for (let i = 0; i < mainElement.children.length; i++) {
      if (mainElement.children[i].classList.contains('dropdown-filters')) {
        mainElement.children[i].classList.toggle('show');
      }
    }
    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target?.classList.contains('dropdown-filter')) {
        element.forEach((el) => {
          if (el.classList.contains('show')) {
            el.classList.remove('show');
          }
        });
      }
    });
  }

  groupItemsByDate(items: any[], arrayName = 'payments') {
    const groupedItems: Payment | any = {};

    items.forEach((item) => {
      // Extract the date part (ignoring the time)
      const date = new Date(item.createdAt).toISOString().split('T')[0];

      if (!groupedItems[date]) {
        groupedItems[date] = [];
      }

      groupedItems[date].push(item);
    });

    const result = [];

    for (const date in groupedItems) {
      if (groupedItems.hasOwnProperty(date)) {
        result.push({
          date:
            date === new Date().toISOString().split('T')[0] ? 'Today' : date,
          [arrayName]: groupedItems[date],
        });
      }
    }

    return result;
  }

  filterActivityByStatus(status: string) {
    if (status === 'All') {
      this.activityTerm = '';
      return;
    }
    this.activityTerm = status;
  }

  cancelPlan(event: any) {}
}
