<div class="form-border p-5">
  <form [formGroup]="personalUserDetailsForm" (ngSubmit)="submitProfile()">
    <div class="row mb-4">
      <div class="col-4">
        <label for="firstName" class="input-label">FIRST NAME</label>
        <input
          type="text"
          formControlName="firstName"
          id="firstName"
          class="input-border"
          required
        />
        <small
          class="text-danger"
          *ngIf="f['firstName'].errors?.['firstName'] && f['firstName'].touched"
        >
          First name is required
        </small>
      </div>

      <div class="col-4">
        <label for="lastName" class="input-label"
          >LASTNAME <span class="text-danger">*</span></label
        >
        <input
          type="text"
          formControlName="lastName"
          id="lastName"
          class="input-border"
        />
        <small
          class="text-danger"
          *ngIf="f['lastName'].errors?.['lastName'] && f['lastName'].touched"
        >
          Last name is required
        </small>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-4">
        <label for="email" class="input-label"
          >EMAIL <span class="text-danger">*</span></label
        >
        <input
          type="text"
          formControlName="email"
          id="email"
          class="input-border"
        />
      </div>

      <div class="col-4">
        <label for="role" class="input-label">ROLE</label>
        <div class="input-border dropdown" (click)="selectDropdown($event)">
          {{ personalUserDetailsForm.value.role || "Select Role" }}
          <div class="dropdown-options">
            <div
              class="dropdown-option"
              *ngFor="let role of roles"
              (click)="setRoles(role)"
            >
              {{ role }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row mb-4">
      <div class="col-4 position-relative">
        <label for="password" class="input-label">PASSWORD</label>
        <input
          [type]="showPassword ? 'text' : 'password'"
          formControlName="password"
          id="password"
          class="input-border pe-5"
        />
        <div class="toggle-password">
          <a
            href="javascript:void(0);"
            (click)="showPassword = !showPassword"
            style="text-decoration: none"
          >
            <i [ngClass]="showPassword ? 'bi bi-eye-slash' : 'bi bi-eye'"></i>
          </a>
        </div>
      </div>
    </div> -->
    <div class="col-8 d-flex justify-content-end mb-4 p">
      <button class="btn-submit me-2" type="button" (click)="resetPassword()">
        Reset Password
      </button>
    </div>

    <div class="row mb-4">
      <div
        class="col-8 mx-3 input-dash d-flex justify-content-center"
        style="width: 65%"
      >
        <div class="p-4 text-center">
          <img src="assets/images/empty-image.svg" alt="dash" class="" />
          <div class="fw-500 font-14 my-2">Drop your image here, or browse</div>
          <div class="font-12 fw-400">Supports JPG, PNG, and JPEG</div>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="mb-4 d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <input type="checkbox" id="remember" name="remember" />
          <label class="checkbox" for="remember"></label>
          <label for="remember" class="fw-400 font-16"
            >Send the new user an email about their account.</label
          >
        </div>
      </div>
    </div>

    <div class="d-flex align-item-center gap-3">
      <button class="btn-submit" type="submit">
        {{ personalUserDetailsForm.value.id ? "Update User" : "Submit" }}
      </button>
      <button
        class="btn-delete"
        type="button"
        (click)="deleteUser()"
        *ngIf="
          (loggedInUser?.role?.toLocaleLowerCase()?.replace(' ', '') ===
            'admin' &&
            user?.role?.toLocaleLowerCase()?.replace(' ', '') !==
              'superadmin') ||
          (loggedInUser?.role?.toLocaleLowerCase()?.replace(' ', '') ===
            'admin' &&
            user?.role?.toLocaleLowerCase()?.replace(' ', '') === 'admin') ||
          (loggedInUser?.role?.toLocaleLowerCase()?.replace(' ', '') ===
            'superadmin' &&
            user?.role?.toLocaleLowerCase()?.replace(' ', '') !== 'superadmin')
        "
      >
        Delete User
      </button>
    </div>
  </form>
</div>
