import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Swap_User } from 'src/app/services/core/IApp';

@Component({
  selector: 'tier2-form',
  templateUrl: './tier2-form.component.html',
  styleUrls: ['./tier2-form.component.scss'],
})
export class Tier2FormComponent {
  @Input() swapUser: Swap_User | undefined;
  @Output() emitTier2: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closeBtn') closeBtn: any;

  tier2Form: FormGroup = new FormGroup({
    _id: new FormControl('', Validators.compose([Validators.required])),
    facebook: new FormControl(''),
    instagram: new FormControl(''),
  });

  ngOnChanges() {
    this.tier2Form.patchValue({
      _id: this.swapUser?._id,
      facebook: this.swapUser?.socials?.facebook,
      instagram: this.swapUser?.socials?.instagram,
    });
  }

  ngOnDestroy() {
    this.tier2Form.reset();
  }

  deleteUrl(control_name: string) {
    this.tier2Form.controls[control_name].setValue('');
  }

  onSubmit() {
    this.emitTier2.emit(this.tier2Form.value);
    this.tier2Form.reset();
    this.closeBtn.nativeElement.click();
  }
}
