<form [formGroup]="planForm" (ngSubmit)="submit()">
  <div class="row g-0 gx-4 mb-4">
    <div class="col-4">
      <div class="form-group">
        <label for="name" class="mb-2 fw-bold"
          >Subscription Plan <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          id="name"
          formControlName="name"
          placeholder="Enter Subscription Plan"
          (input)="onChanges.emit(planForm.value)"
        />
      </div>
    </div>
    <div class="col-4">
      <div class="form-group">
        <label for="monthly" class="mb-2 fw-bold"
          >Monthly <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="price_per_month"
          placeholder="Enter Amount"
        />
      </div>
    </div>
    <div class="col-4">
      <div class="form-group">
        <label for="amount" class="mb-2 fw-bold"
          >Yearly <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          id="amount"
          formControlName="price_per_year"
          placeholder="Enter Amount"
        />
      </div>
    </div>
  </div>
  <div class="row g-0 gx-4 mb-4">
    <div class="col-4">
      <div class="form-group">
        <label for="user_type" class="mb-2 fw-bold"
          >User Type <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          id="user_type"
          formControlName="user_type"
          placeholder="Enter User Type"
          (input)="onChanges.emit(planForm.value)"
        />
      </div>
    </div>
    <div class="col-4">
      <div class="form-group">
        <label for="stripe_price_monthly_id" class="mb-2 fw-bold"
          >Monthly Stripe ID <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          id="stripe_price_monthly_id"
          formControlName="stripe_price_monthly_id"
          placeholder="Enter Stripe ID"
        />
      </div>
    </div>
    <div class="col-4">
      <div class="form-group">
        <label for="stripe_price_yearly_id" class="mb-2 fw-bold"
          >Yearly Stripe ID <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          id="stripe_price_yearly_id"
          formControlName="stripe_price_yearly_id"
          placeholder="Enter Stripe ID"
        />
      </div>
    </div>

    <div class="col-4 mt-4">
      <div class="form-group">
        <label for="cta" class="mb-2 fw-bold"
          >CTA <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          id="cta"
          formControlName="cta"
          placeholder="Enter CTA"
          (input)="onChanges.emit(planForm.value)"
        />
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-between gap-2 mb-2">
    <p class="mb-0 fw-bold">Offers</p>
    <a
      href="javascript:;"
      class="text-primary text-decoration-none fw-500"
      (click)="addNewOffer()"
      >Add New</a
    >
  </div>
  <div class="row g-0 gx-4 mb-4 pe-0">
    <div
      class="col-12 row g-0 gx-4 pe-0"
      *ngFor="let offer of planForm.value.offers; let i = index"
    >
      <div class="col-6">
        <div class="input-form">
          <input
            type="text"
            class="w-100"
            placeholder="Enter Offer Name"
            value="{{ offer.name }}"
            (input)="updateOfferName($event, i)"
          />
          <button type="button" class="btn-delete" (click)="deleteOffer(i)">
            <i class="bi bi-x"></i>
          </button>
        </div>
      </div>
      <div class="col-6 pe-0">
        <input
          type="text"
          class="form-control"
          id="name"
          placeholder="Enter Offer Value"
          value="{{ offer.value }}"
          (input)="updateOfferValue($event, i)"
        />
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-between gap-2 mb-2">
    <p class="mb-0 fw-bold">Support</p>
    <a
      href="javascript:;"
      class="text-primary text-decoration-none fw-500"
      (click)="addNewSupport()"
      >Add New</a
    >
  </div>
  <div class="row g-0 gx-4 mb-4 pe-0">
    <div
      class="col-12 row g-0 gx-4 pe-0"
      *ngFor="let support of planForm.value.support; let i = index"
    >
      <div class="col-6">
        <div class="input-form">
          <input
            type="text"
            class="w-100"
            placeholder="Enter Support Name"
            value="{{ support.name }}"
            (input)="updateSupportName($event, i)"
          />
          <button type="button" class="btn-delete" (click)="deleteSupport(i)">
            <i class="bi bi-x"></i>
          </button>
        </div>
      </div>
      <div class="col-6 pe-0">
        <div
          class="d-flex align-items-center justify-content-between gap-2 border border-primary rounded-3 p-3"
        >
          <p class="mb-0">{{ support.value ? "Active" : "Inactive" }}</p>
          <div class="custom-switch">
            <input
              type="checkbox"
              id="{{ 'supportSwitch' + i }}"
              value="{{ support.value }}"
              [checked]="support.value"
              (input)="updateSupportActive($event, i)"
            />
            <label for="{{ 'supportSwitch' + i }}"></label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-between gap-2 mb-2">
    <p class="mb-0 fw-bold">Badge Eligibility</p>
    <a
      href="javascript:;"
      class="text-primary text-decoration-none fw-500"
      (click)="addNewBadgeEligibility()"
      >Add New</a
    >
  </div>
  <div class="row g-0 gx-4 mb-4 pe-0">
    <div
      class="col-12 row g-0 gx-4 pe-0"
      *ngFor="let badge of planForm.value.user; let i = index"
    >
      <div class="col-6">
        <div class="input-form">
          <input
            type="text"
            class="w-100"
            placeholder="Enter Badge Name"
            value="{{ badge.name }}"
            (input)="updateBadgeEligibilityName($event, i)"
          />
          <button
            type="button"
            class="btn-delete"
            (click)="deleteBadgeEligibility(i)"
          >
            <i class="bi bi-x"></i>
          </button>
        </div>
      </div>
      <div class="col-6 pe-0">
        <div
          class="d-flex align-items-center justify-content-between gap-2 border border-primary rounded-3 p-3"
        >
          <p class="mb-0">
            {{ badge.value ? "Active" : "Inactive" }}
          </p>
          <div class="custom-switch">
            <input
              type="checkbox"
              id="{{ 'customSwitch' + i }}"
              value="{{ badge.value }}"
              [checked]="badge.value"
              (input)="updateBadgeEligibilityActive($event, i)"
            />
            <label for="{{ 'customSwitch' + i }}"></label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center gap-2">
    <button type="submit" class="btn btn-secondary">
      {{ plan?._id ? "Update" : "Create" }}
    </button>
    <button
      *ngIf="plan?._id"
      type="button"
      class="btn btn-link text-decoration-none text-danger fw-bold d-none"
      (click)="onDelete.emit()"
    >
      Delete Plan
    </button>
  </div>
</form>
