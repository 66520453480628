<div class="row m-3">
  <div class="col-12 d-flex justify-content-end mb-5">
    <button
      type="button"
      class="closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
      (click)="closeModal = true"
    >
      <img src="assets/icons/close-icon-pink.svg" alt="" />
    </button>
  </div>
  <div class="col-12 mb-5">
    <h5 class="fw-700 text-center mb-4">Edit Tier 1</h5>
    <form
      class="row gy-4 justify-content-center"
      [formGroup]="tier1Form"
      (ngSubmit)="onSubmit()"
    >
      <div class="col-9">
        <label for="email" class="mb-1 fw-700">Email</label>
        <input
          type="text"
          id="email"
          class="input-border"
          formControlName="email"
        />
      </div>
      <div class="col-9">
        <label for="phone" class="mb-1 fw-700">Phone Number</label>
        <phone-input
          [closeModal]="closeModal"
          [phoneNumber]="tier1Form.value.phone"
          (emitPhoneNumber)="setPhone($event)"
        ></phone-input>
      </div>
      <div class="col-9 text-end">
        <button
          class="swapBtn"
          (click)="tier1Form.patchValue({ phone: '' })"
          type="button"
        >
          Reset Phone Number
        </button>
        <button class="swapBtn update ms-2" type="submit">Update</button>
      </div>
    </form>
  </div>
</div>
