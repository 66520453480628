import { Pipe, PipeTransform } from '@angular/core';
import { Ticket } from '../core/IApp';

@Pipe({
  name: 'ticketFilter',
})
export class TicketFilterPipe implements PipeTransform {
  transform(tickets: Ticket[], searchTerm: string): Ticket[] {
    if (!tickets || !searchTerm) return tickets;

    return tickets.filter(
      (ticket) =>
        ticket.user.name.first
          .toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase()) ||
        ticket.user.name.last
          .toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase()) ||
        ticket.subject
          .toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase()) ||
        ticket.category
          .toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase()) ||
        ticket._id.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
  }
}
