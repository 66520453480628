<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header border-0 mx-3">
    <h5 class="modal-title fw-bolder" id="staticBackdropLabel">Review</h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeModal
    ></button>
  </div>
  <div class="modal-body mx-3">
    <div
      class="d-flex justify-content-center align-items-center flex-column gap-1 pointer mb-3"
    >
      <img
        [src]="review?.reviewee?.avatar || 'assets/icons/user.svg'"
        class="rounded-circle"
        alt="avatar"
        width="83"
        height="83"
      />
      <p class="mb-0 fw-700">
        {{ review?.reviewee?.username || "User" }}
      </p>
      <p class="mb-0 fw-500 text-muted">
        {{
          review?.offer?.biddings?.buyer?.userId === review?.reviewee?._id
            ? "Buyer"
            : "Seller"
        }}
      </p>
    </div>

    <div class="row">
      <div class="col-12">
        <p class="text-muted">Comment</p>
        <p class="fw-500 fw-bolder">{{ review?.comment }}</p>
      </div>
      <div class="col-12 d-flex justify-content-between">
        <p class="text-muted">Rating</p>
        <ngb-rating
          [rate]="review?.rating || 0"
          [max]="5"
          [readonly]="true"
          class="font-16"
          [ngStyle]="{
            color: review?.rating > 0 ? '#FFC107' : '#C4C4C4'
          }"
        >
          <ng-template let-fill="fill" let-index="index">
            <i
              class="bi bi-star{{ fill === 100 ? '-fill' : '' }} ms-1"
              [class.filled]="fill === 100"
            ></i>
          </ng-template>
        </ngb-rating>
      </div>
      <div class="col-12 d-flex align-items-center justify-content-between">
        <p class="text-muted">Transaction ID</p>
        <p
          class="fw-500 fw-bolder text-primary"
          (click)="closeModal.click()"
          routerLink="/main/transactions/{{ review?.biddingId }}"
        >
          {{ "#" + review?.biddingId }}
        </p>
      </div>
      <div class="col-12 d-flex align-items-center justify-content-between">
        <p class="text-muted">Category</p>
        <p class="fw-500 fw-bolder">Bank Transfer</p>
      </div>
      <div class="col-12 d-flex align-items-center justify-content-between">
        <p class="text-muted">Date</p>
        <p class="fw-500 fw-bolder" *ngIf="review?.createdAt">
          {{ review?.createdAt | date : "mediumDate" : utc_offset }} at
          {{ globals.formatTimestamp(review.createdAt) }}
        </p>
        <p class="fw-500 fw-bolder" *ngIf="!review?.createdAt">
          Date not available
        </p>
      </div>
    </div>
  </div>
</swap-modal>
