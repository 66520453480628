<div class="row m-3">
  <div
    class="col-12 d-flex justify-content-between align-item-center mt-2 mb-5"
  >
    <h5 class="fw-700 mb-0">Send Email</h5>
    <button
      type="button"
      class="closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
    >
      <img src="assets/icons/close-icon-pink.svg" alt="" />
    </button>
  </div>
  <div class="col-12 mb-5">
    <form
      class="row gy-4 justify-content-center"
      [formGroup]="emailForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="col-12">
        <div class="mb-3">
          <label class="form-label fw-700 text-dark">To</label>
          <div class="row g-0 gap-2 receipients-container">
            <div
              class="d-flex align-items-center gap-1 receipient"
              *ngIf="swapUser"
            >
              <p class="mb-0 font-12">
                {{ "@" + swapUser.username }}
              </p>
              <button type="button" (click)="deleteReceipient()">
                <i class="bi bi-x"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row g-0 gx-2">
          <div class="col-6">
            <div class="mb-3">
              <label class="form-label fw-700 text-dark">Subject</label>
              <input
                type="text"
                class="input-border"
                formControlName="subject"
                placeholder="Enter title"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label class="form-label fw-700 text-dark">Category</label>
              <div
                class="input-border dropdown"
                (click)="selectDropdown($event)"
                [ngClass]="{ 'text-muted': !emailForm.value.category }"
              >
                {{ emailForm.value.category || "Select Category" }}
                <div class="dropdown-options">
                  <div
                    class="dropdown-option"
                    *ngFor="let cat of categories"
                    (click)="setCategory(cat)"
                  >
                    {{ cat }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label fw-700 text-dark">Message</label>
          <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
            </ngx-editor-menu>
            <ngx-editor
              [editor]="editor"
              formControlName="content"
              [placeholder]="'Type here...'"
            ></ngx-editor>
          </div>
        </div>
      </div>
      <div class="col-11 text-end">
        <button class="swapBtn" (click)="closeBtn.click()" type="button">
          Go back
        </button>
        <button class="swapBtn update ms-2" type="submit">Send</button>
      </div>
    </form>
  </div>
</div>
