import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Country, State, User } from '../../core/IApp';
import { GlobalsService } from '../../core/globals';
import { RequestService } from '../../core/request';

const routes = {
  register: 'auth/sign-up',
  resendOTP: 'auth/resend-otp',
  verifyOTP: 'auth/verify-otp',
  login: 'auth/login',
  forgotPassword: 'auth/forget-password',
  resetPassword: 'auth/reset-password',
  changePassword: 'auth/change-password',
  user: 'profile',
  updateSocialsUrl: 'profile/socials',
  updateCurrency: 'profile/currency',
  notifications: 'notifications',
  swapPals: 'swap-pals',
  updateAvatar: 'profile/avatar',
  countries: 'countries',
  verifyPhone: 'auth/add-phone',
  kyc: 'kyc/latest',
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private api: RequestService,
    private globals: GlobalsService,
    private http: HttpClient
  ) {}

  async login(user: User) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post('auth/login', user);
        if (resp.trigger.includes('verify-qr-code')) {
          this.globals.storage.setQRCodeSecret(resp.data);
          this.globals.router.navigate(['auth/qrcode']);
        }
        if (resp.trigger.includes('verify-otp')) {
          this.globals.storage.setUserDetails(resp.data);
          this.globals.router.navigate(['auth/verify']);
        }
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(err.message);
        reject(err);
      }
    });
  }

  async getLocation(): Promise<any> {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.http
          .get('https://ipapi.co/json/')
          .toPromise();
        this.globals.storage.setUserLocationDetails(resp);
        resolve(resp);
      } catch (ex) {
        reject(ex);
      }
    });
  }

  async changePassword({
    password,
    token,
  }: {
    password: string;
    token: string;
  }) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post('auth/reset-password', {
          password,
          token,
        });
        this.globals.spinner.hide();
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(err.message);
        reject(err);
      }
    });
  }

  async validateQrCode(email: string, token: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post('auth/verify-qrcode', {
          email,
          otp: token,
        });
        // await this.getUserLocation();
        this.globals.user = resp.data as User;
        this.globals.storage.setUserDetails(resp.data);
        this.globals.storage.setAccessToken(resp.token);
        await this.getAllCountriesData();
        this.globals.toast.success('Successfully Logged In!!');
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(err.message || err);
        reject(err);
      }
    });
  }

  async directChangePassword(password: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post('auth/direct-reset-password', {
          password,
        });
        this.globals.spinner.hide();
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(err.message);
        reject(err);
      }
    });
  }

  async logout() {
    return await new Promise(async (resolve, reject) => {
      try {
        // const resp: any = await this.api.get('logout');
        this.globals.storage.logOutUser();
        resolve(true);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllCountriesData() {
    return await new Promise(async (resolve, reject) => {
      try {
        const countries: any = await this.api.get('countries');
        this.globals.storage.setCountries(countries.data);
        this.globals.countries = countries.data as Country[];
        resolve(countries.data);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getUserLocation() {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.http
          .get('https://ipapi.co/json/')
          .toPromise();
        this.globals.storage.setUserLocationDetails(resp);
        resolve(resp);
      } catch (ex) {
        reject(ex);
      }
    });
  }

  async getAllStatesData(countryId: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(`countries/${countryId}`);
        this.globals.states = resp.data.states as State[];
        resolve(resp);
      } catch (ex: any) {
        reject(ex);
      }
    });
  }

  async getNotifications(page: number) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(`notifications?page=${page}`);
        this.globals.notifications = resp.data as any[];
        this.globals.storage.setNotifications(this.globals.notifications);
        this.globals.unreadMessages = false;
        this.globals.numberOfUnreadMessages = 0;
        for (let i = 0; i < this.globals.notifications.length; i++) {
          const element = this.globals.notifications[i];
          if (!element.markedAsRead) {
            this.globals.numberOfUnreadMessages++;
            if (this.globals.numberOfUnreadMessages > 0)
              this.globals.unreadMessages = true;
          }
        }
        resolve(resp);
      } catch (ex: any) {
        reject(ex);
      }
    });
  }

  async markNotificationAsRead(_id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.patch(`notifications/${_id}`, {});
        this.globals.notifications = this.globals.notifications.map(
          (notification) => {
            if (notification._id === _id) {
              notification.markedAsRead = true;
              this.globals.numberOfUnreadMessages--;
              if (this.globals.numberOfUnreadMessages <= 0)
                this.globals.numberOfUnreadMessages = 0;
            }

            if (_id === '') {
              notification.markedAsRead = true;
            }
            return notification;
          }
        );
        this.globals.storage.setNotifications(this.globals.notifications);
        this.globals.unreadMessages = false;
        for (let i = 0; i < this.globals.notifications.length; i++) {
          const element = this.globals.notifications[i];
          if (!element.markedAsRead) {
            this.globals.unreadMessages = true;
            break;
          }
        }
        resolve(resp);
      } catch (ex: any) {
        reject(ex);
      }
    });
  }

  async markAllAsRead() {
    try {
      for (const notification of this.globals.notifications) {
        const resp: any = await this.api.patch(
          `notifications/${notification._id}`,
          {}
        );
        notification.markedAsRead = true;
      }
      this.globals.numberOfUnreadMessages = 0;
      this.globals.storage.setNotifications(this.globals.notifications);
      this.globals.unreadMessages = false;

      return 'All notifications marked as read';
    } catch (ex: any) {
      console.error(ex);
      throw ex;
    }
  }
}
