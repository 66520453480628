import { Injectable } from '@angular/core';
import { TokenConfiguration, TokenHistory, UserToken } from '../../core/IApp';
import { GlobalsService } from '../../core/globals';
import { RequestService } from '../../core/request';

@Injectable({
  providedIn: 'root',
})
export class TokensService {
  tokens: UserToken[] = [];
  token: UserToken | null = null;
  tokensPagination = {
    itemsPerPage: 5,
    totalItemsCount: 0,
    page: 1,
    search: '',
    sort: 'createdAt',
    order: 'desc',
  };
  tokenHistories: TokenHistory[] = [];
  tokenHistoryPagination = {
    itemsPerPage: 5,
    totalItemsCount: 0,
    page: 1,
    type: '',
  };

  tokenConfiguration: TokenConfiguration | null = null;
  tokenConfigurations: TokenConfiguration[] = [];
  tokenConfigurationPagination = {
    itemsPerPage: 5,
    totalItemsCount: 0,
    page: 1,
    search: '',
    type: 'credit',
    sort: 'createdAt',
    order: 'desc',
  };

  constructor(private api: RequestService, private globals: GlobalsService) {}

  async getAllUsersTokens() {
    try {
      this.globals.spinner.show();
      const resp: any = await this.api.get(
        `tokens/all?page=${this.tokensPagination.page}&itemsPerPage=${this.tokensPagination.itemsPerPage}&sort=${this.tokensPagination.sort}&order=${this.tokensPagination.order}&search=${this.tokensPagination.search}`
      );
      console.log('API Response:', resp); // Log API response for debugging
      this.tokensPagination.totalItemsCount = resp.data.totalItemsCount;
      this.tokens = resp.data.tokens;

      this.globals.spinner.hide();
      return resp;
    } catch (err: any) {
      this.globals.spinner.hide();
      console.error('Error fetching tokens:', err); // Log the error for debugging
      throw err; // Rethrow the error to propagate it
    }
  }

  async topupUserTokens(
    mode: string,
    {
      users,
      amount,
      allUsers,
      type,
      expiresAt,
    }: {
      users: string[];
      amount: number;
      allUsers: boolean;
      type: string;
      expiresAt: string;
    }
  ) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post(
          `tokens/manage/${mode}`,
          mode === 'add'
            ? { users, amount, allUsers, type, expireAt: expiresAt }
            : {
                users,
                amount,
                allUsers,
              }
        );
        this.globals.spinner.hide();
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(err.message);
        reject(err);
      }
    });
  }

  async getTokenHistories(id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get(
          `tokens/history?id=${id}&page=${this.tokenHistoryPagination.page}&itemsPerPage=${this.tokenHistoryPagination.itemsPerPage}&type=${this.tokenHistoryPagination.type}`
        );
        this.tokenHistoryPagination.totalItemsCount = resp.data.totalItemsCount;
        this.tokenHistories = resp.data.tokens;
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        reject(err);
      }
    });
  }

  async getTokenConfigurations() {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get(
          `tokens-configuration/all?page=${this.tokenConfigurationPagination.page}&itemsPerPage=${this.tokenConfigurationPagination.itemsPerPage}&sort=${this.tokenConfigurationPagination.sort}&order=${this.tokenConfigurationPagination.order}&search=${this.tokenConfigurationPagination.search}&type=${this.tokenConfigurationPagination.type}`
        );
        this.tokenConfigurationPagination.totalItemsCount =
          resp.data.totalItemsCount;
        this.tokenConfigurations = resp.data.tokens;
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(err);
        reject(err);
      }
    });
  }

  async getTokenConfiguration(id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get(
          `tokens-configuration/token/${id}`
        );
        this.tokenConfiguration = resp.data;
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(err);
        reject(err);
      }
    });
  }

  async createTokenConfiguration(token: TokenConfiguration) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post(
          'tokens-configuration/create',
          token
        );
        this.globals.spinner.hide();
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(err.message);
        reject(err);
      }
    });
  }

  async updateTokenConfiguration(token: TokenConfiguration) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.update(
          'tokens-configuration/update/' + token._id,
          token
        );
        this.globals.spinner.hide();
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(err.message);
        reject(err);
      }
    });
  }
}
