import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { HelpHistoryService } from '../../api/help-history/help-history.service';
import { StorageService } from '../../core/storage';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private storage: StorageService,
    private socketService: HelpHistoryService
  ) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const user = this.storage.getUserDetails();
    const exceptions = ['Technical Support', 'Admin', 'Super Admin'];

    if (!exceptions.includes(user.role)) {
      alert('You do not have permission to access this page');
      this.router.navigateByUrl('/main/dashboard');
      return false;
    }

    return true;
  }
}
