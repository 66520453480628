import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'freeze-modal',
  templateUrl: './freeze-modal.component.html',
  styleUrls: ['./freeze-modal.component.scss'],
})
export class FreezeModalComponent {
  @Input() modalId: string = '';
  @Input() username: string = '';
  @Output() emitFreezeAccount: EventEmitter<string> =
    new EventEmitter<string>();

  @ViewChild('closeModal') closeModal: HTMLButtonElement | any;

  constructor() {}

  ngOnInit(): void {}

  freezeAccount() {
    this.emitFreezeAccount.emit();
    this.closeModal.nativeElement.click();
  }

  closeDialog() {
    this.closeModal.nativeElement.click();
  }
}
