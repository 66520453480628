import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Validation from 'src/app/services/shared/validation';

@Component({
  selector: 'comment-form',
  templateUrl: './comment-form.component.html',
  styleUrls: ['./comment-form.component.scss'],
})
export class CommentFormComponent {
  @Output() emitComment: EventEmitter<any> = new EventEmitter<any>();

  commentForm: FormGroup = new FormGroup(
    {
      note: new FormControl('', Validators.compose([Validators.required])),
      attachment: new FormControl([]),
    },
    {
      validators: Validation.validateFile(
        'attachment',
        ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'],
        5000
      ),
    }
  );

  files: Array<any> = [];

  ngOnChanges() {}

  onSubmit() {
    this.emitComment.emit(this.commentForm.value);
    this.commentForm.reset({
      note: '',
      attachment: [],
    });
    this.files = [];
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.onSubmit();
    }
  }

  onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.files = [...event.dataTransfer.files];
    if (this.files.length > 0) {
      this.commentForm.patchValue({
        attachment: this.files,
      });
    }
  }

  onFileChange(event: any) {
    this.files = [...event.target.files];
    if (this.files.length > 0) {
      this.commentForm.patchValue({
        attachment: this.files,
      });
    }
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.commentForm.patchValue({
      attachment: this.files,
    });
  }

  get attachment() {
    return this.commentForm.get('attachment') as FormControl;
  }
}
