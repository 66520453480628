<form [formGroup]="tokenForm" (ngSubmit)="submit()">
  <div class="row g-0 gx-4 mb-4">
    <div class="col-6">
      <div class="form-group">
        <label for="name" class="mb-2 fw-bold"
          >Token Type <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          id="name"
          formControlName="name"
          placeholder="Enter Token Type"
        />
        <small class="text-danger" *ngIf="name.dirty || name.touched">
          {{ name.errors?.['required'] ? "Token Type is required" : "" }}
        </small>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label for="monthly" class="mb-2 fw-bold"
          >Token price (EXT) <span class="text-danger">*</span></label
        >
        <input
          type="number"
          class="form-control"
          formControlName="amount"
          placeholder="Enter Amount"
        />
        <small class="text-danger" *ngIf="amount.dirty || amount.touched">
          {{ amount.errors?.['required'] ? "Amount is required" : "" }}
        </small>
      </div>
    </div>
  </div>
  <div class="row g-0 gx-4 mb-4" *ngIf="tokenType === 'credit'">
    <div class="col-4">
      <div class="form-group">
        <label for="duration" class="mb-2 fw-bold"
          >Expiry Duration <span class="text-danger">*</span></label
        >
        <input
          type="number"
          class="form-control"
          id="duration"
          formControlName="duration"
          placeholder="Enter Expiry Duration"
        />

        <small class="text-danger" *ngIf="duration.dirty || duration.touched">
          {{ duration.errors?.['required'] ? "Expiry Duration is required" : "" }}
        </small>
      </div>
    </div>
    <div class="col-4">
      <div class="form-group">
        <label for="stripe_price_monthly_id" class="mb-2 fw-bold"
          >Expiry Duration Unit<span class="text-danger">*</span></label
        >
        <div
          class="input-border dropdown"
          (click)="selectDropdown($event)"
          [ngClass]="{ 'text-muted': !tokenForm.value.unit }"
        >
          {{ (tokenForm.value.unit | titlecase) || "Select Unit" }}
          <div class="dropdown-options">
            <div
              class="dropdown-option"
              *ngFor="let unit of units"
              (click)="setUnit(unit)"
            >
              {{ unit | titlecase }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex align-items-center gap-2">
    <button type="submit" class="btn btn-secondary">
      {{ token?._id ? "Update" : "Create" }}
    </button>
    <button
      *ngIf="token?._id"
      type="button"
      class="btn btn-link text-decoration-none text-danger fw-bold d-none"
      (click)="onDelete.emit()"
    >
      Delete Token
    </button>
  </div>
</form>
