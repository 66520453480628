import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'signin-form',
  templateUrl: './signin-form.component.html',
  styleUrls: ['./signin-form.component.scss'],
})
export class SigninFormComponent {
  @Output() sendSigninForm: EventEmitter<any> = new EventEmitter<any>();

  signinForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.pattern(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
      ),
    ]),
    remember: new FormControl(false),
  });

  constructor(private globalsService: GlobalsService) {}

  ngOnInit() {
    const rememberMeDetails =
      this.globalsService.storage.getRememberMeDetails();
    if (rememberMeDetails) {
      this.signinForm.patchValue({
        email: rememberMeDetails.email,
        password: rememberMeDetails.password,
        remember: true,
      });
    }
  }

  onSubmit() {
    this.rememberMe();
    this.sendSigninForm.emit(this.signinForm.value);
  }

  rememberMe() {
    if (this.signinForm.value.remember) {
      this.globalsService.storage.setRememberMeDetails({
        email: this.signinForm.value.email,
        password: this.signinForm.value.password,
      });
    } else {
      this.globalsService.storage.removeRememberMeDetails();
    }
  }

  get email() {
    return this.signinForm.get('email');
  }

  get password() {
    return this.signinForm.get('password');
  }
}
