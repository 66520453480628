import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'cancel-plan',
  templateUrl: './cancel-plan.component.html',
  styleUrls: ['./cancel-plan.component.scss'],
})
export class CancelPlanComponent {
  @Input() modalId: string = '';
  @Input() username: string = '';
  @Output() emitCancelPlan: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('closeModal') closeModal: HTMLButtonElement | any;

  constructor() {}

  ngOnInit(): void {}

  cancelPlan() {
    this.emitCancelPlan.emit();
    this.closeModal.nativeElement.click();
  }

  closeDialog() {
    this.closeModal.nativeElement.click();
  }
}
