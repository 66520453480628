import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Swap_User } from 'src/app/services/core/IApp';

@Component({
  selector: 'tier3-form',
  templateUrl: './tier3-form.component.html',
  styleUrls: ['./tier3-form.component.scss'],
})
export class Tier3FormComponent {
  @Input() swapUser: Swap_User | undefined;
  @Output() emitTier3: EventEmitter<Swap_User> = new EventEmitter();
  @ViewChild('closeBtn') closeBtn: any;

  tier3Form: FormGroup = new FormGroup({
    fb_url: new FormControl(null, Validators.compose([Validators.required])),
    instagram: new FormControl(
      null,
      Validators.compose([Validators.required, Validators.email])
    ),
  });

  ngOnChanges() {
    this.tier3Form.patchValue(this.swapUser as Swap_User);
  }

  ngOnDestroy() {
    this.tier3Form.reset();
  }

  deleteUrl(control_name: string) {
    this.tier3Form.controls[control_name].setValue(null);
  }

  onSubmit() {
    this.emitTier3.emit(this.tier3Form.value);
    this.tier3Form.reset();
    this.closeBtn.nativeElement.click();
  }
}
