import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Swap_User } from 'src/app/services/core/IApp';

@Component({
  selector: 'tier1-form',
  templateUrl: './tier1-form.component.html',
  styleUrls: ['./tier1-form.component.scss'],
})
export class Tier1FormComponent {
  @Input() swapUser: Swap_User | undefined;
  @Output() emitTier1: EventEmitter<Swap_User> = new EventEmitter();
  @ViewChild('closeBtn') closeBtn: any;

  closeModal: boolean = false;

  tier1Form: FormGroup = new FormGroup({
    _id: new FormControl('', Validators.compose([Validators.required])),
    email: new FormControl(
      '',
      Validators.compose([Validators.required, Validators.email])
    ),
    phone: new FormControl('', Validators.compose([Validators.required])),
  });

  ngOnChanges() {
    this.closeModal = false;
    this.tier1Form.patchValue(this.swapUser as Swap_User);
  }

  onSubmit() {
    this.emitTier1.emit(this.tier1Form.value);
    this.tier1Form.reset({
      _id: '',
      email: '',
      phone: '',
    });
    this.closeBtn.nativeElement.click();
  }

  setPhone(phonenumber: string) {
    this.tier1Form.patchValue({
      phone: phonenumber,
    });
  }
}
