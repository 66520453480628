import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from 'src/app/services/guards/admin/admin.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MainComponent } from './main.component';
import { UsersComponent } from './users/users.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: { title: 'Dashboard' },
      },
      {
        path: 'users',
        data: { title: 'Users' },
        loadChildren: () =>
          import('../main/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'help-history',
        data: { title: 'Help History' },
        loadChildren: () =>
          import('../main/help-history/help-history.module').then(
            (m) => m.HelpHistoryModule
          ),
      },
      {
        path: 'token-management',
        data: { title: 'Tokens Management' },
        loadChildren: () =>
          import('../main/token-management/token-management.module').then(
            (m) => m.TokenManagementModule
          ),
      },
      {
        path: 'plan-management',
        data: { title: 'Plan Management' },
        loadChildren: () =>
          import('../main/plan-management/plan-management.module').then(
            (m) => m.PlanManagementModule
          ),
      },
      {
        path: 'reviews-rating',
        data: { title: 'Reviews & Rating' },
        loadChildren: () =>
          import('../main/reviews-rating/reviews-rating.module').then(
            (m) => m.ReviewsRatingModule
          ),
      },
      {
        path: 'users-verification',
        data: { title: 'Users Verification' },
        loadChildren: () =>
          import('../main/users-verification/users-verification.module').then(
            (m) => m.UsersVerificationModule
          ),
      },
      {
        path: 'admin-management',
        data: { title: 'Admin Management' },
        loadChildren: () =>
          import('../main/admin-management/admin-management.module').then(
            (m) => m.AdminManagementModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'configuration',
        loadChildren: () =>
          import('./configuration/configuration.module').then(
            (m) => m.ConfigurationModule
          ),
        data: { title: 'Configuration' },
      },
      {
        path: 'transactions',
        data: { title: 'Transactions' },
        loadChildren: () =>
          import('../main/transactions/transactions.module').then(
            (m) => m.TransactionsModule
          ),
      },
      {
        path: 'offers',
        data: { title: 'Offers' },
        loadChildren: () =>
          import('../main/offers/offers.module').then((m) => m.OffersModule),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingsModule),
        data: { title: 'Settings' },
      },
      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
  {
    path: 'users',
    component: UsersComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}
