<div class="row m-3">
  <div class="col-12 d-flex justify-content-end mb-5">
    <button
      type="button"
      class="closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
    >
      <img src="assets/icons/close-icon-pink.svg" alt="" />
    </button>
  </div>
  <div class="col-12 mb-5">
    <h5 class="fw-700 text-center mb-4">Edit Ticket</h5>
    <form
      class="row gy-4 justify-content-center"
      [formGroup]="ticketForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="col-9">
        <label for="subject" class="mb-1 fw-700">Subject</label>
        <input
          type="text"
          id="subject"
          class="input-border"
          formControlName="subject"
        />
      </div>
      <div class="col-9">
        <label for="category" class="mb-1 fw-700">Category</label>
        <div
          class="input-border dropdown"
          (click)="selectDropdown($event)"
          [ngClass]="{ 'text-muted': !ticketForm.value.category }"
        >
          {{ ticketForm.value.category || "Select Category" }}
          <div class="dropdown-options">
            <div
              class="dropdown-option"
              *ngFor="let cat of categories"
              (click)="setCategory(cat)"
            >
              {{ cat }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-9">
        <label for="status" class="mb-1 fw-700">Status</label>
        <div class="input-border dropdown" (click)="selectDropdown($event)">
          {{ displayStatus(ticketForm.value.status) || "Select Status" }}
          <div class="dropdown-options">
            <div
              class="dropdown-option"
              *ngFor="let status of statuses"
              (click)="setStatus(status.value)"
            >
              {{ status.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 text-end">
        <button class="swapBtn" (click)="closeBtn.click()" type="button">
          Cancel
        </button>
        <button
          class="swapBtn update ms-2"
          type="submit"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          Update
        </button>
      </div>
    </form>
  </div>
</div>
