import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], searchTerm: string, keys: string[]): any[] {
    if (!items) return [];
    if (!searchTerm) return items;

    searchTerm = searchTerm.toLowerCase();

    return items.filter((item) => {
      for (let i = 0; i < keys.length; i++) {
        const keyPath = keys[i].split('.');
        let currentObj = item;
        for (let j = 0; j < keyPath.length; j++) {
          if (!currentObj) {
            break;
          }
          currentObj = currentObj[keyPath[j]];
        }
        if (
          currentObj
            .toString()
            .toLowerCase()
            .includes(searchTerm)
        ) {
          return true;
        }
      }
      return false;
    });
  }
}

