import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Plan } from 'src/app/services/core/IApp';

@Component({
  selector: 'plan-upgrade-form',
  templateUrl: './plan-upgrade-form.component.html',
  styleUrls: ['./plan-upgrade-form.component.scss'],
})
export class PlanUpgradeFormComponent {
  @Input() type: string = 'upgrade';

  @Input() plans: Plan[] = [];

  @Input() currentPlan: string = '';

  @Input() period: string = '';

  @Output() submitPlan: EventEmitter<any> = new EventEmitter<any>();

  planForm: FormGroup = new FormGroup({
    currentPlan: new FormControl('', Validators.required),
    newPlan: new FormControl('', Validators.required),
    period: new FormControl('', Validators.required),
  });

  periods: string[] = ['monthly', 'yearly'];

  constructor() {}

  ngOnChanges() {
    this.planForm.controls['currentPlan'].setValue(this.currentPlan);
    this.period !== 'cancel' &&
      this.planForm.controls['period'].setValue(this.period);
  }

  onSubmit() {
    if (this.planForm.valid) {
      this.submitPlan.emit(this.planForm.value);
    }
  }

  selectDropdown(event: any) {
    // close other dropdown options
    event.preventDefault();
    const element = document.querySelectorAll('.dropdown-options');
    element.forEach((el) => {
      if (
        el.classList.contains('show') &&
        el !== event.target.nextElementSibling
      ) {
        el.classList.remove('show');
      }
    });

    // add show class to dropdown options
    event.target.children[0]?.classList.toggle('show');

    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target?.classList.contains('dropdown-option')) {
        element.forEach((el) => {
          if (el.classList.contains('show')) {
            el.classList.remove('show');
          }
        });
      }
    });
  }

  setPlan(plan: string) {
    this.planForm.controls['newPlan'].setValue(plan);
  }

  setPeriod(period: string) {
    this.planForm.controls['period'].setValue(period);
  }
}
