import { AbstractControl, ValidatorFn } from '@angular/forms';

export default class Validation {
  static passwordValidate = {
    firstCharacterInUpperCase: /^[A-Z]/,
    hasDigit: /[0-9]/,
    hasSymbol: /[@!><~|%#&*_-]/,
    minLen: /.{8}/,
  };

  static match(controlName: string, checkControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);

      if (checkControl?.errors && !checkControl?.errors['matching']) {
        return null;
      }

      if (control?.value !== checkControl?.value) {
        controls.get(checkControlName)?.setErrors({ matching: true });
        return { matching: true };
      } else {
        return null;
      }
    };
  }

  static password_firstCharacterUpperCaseError(): ValidatorFn {
    return (controls: AbstractControl) => {
      if (controls?.errors && !controls?.errors['firstCharacterUppercase']) {
        return null;
      }

      if (
        controls.value &&
        !this.passwordValidate.firstCharacterInUpperCase.test(controls.value)
      ) {
        return { firstCharacterUppercase: true };
      } else {
        return null;
      }
    };
  }

  static password_noDigitError(): ValidatorFn {
    return (controls: AbstractControl) => {
      if (controls?.errors && !controls?.errors['hasDigit']) {
        return null;
      }

      if (!this.passwordValidate.hasDigit.test(controls.value)) {
        return { hasDigit: true };
      } else {
        return null;
      }
    };
  }

  static password_minLenError(): ValidatorFn {
    return (controls: AbstractControl) => {
      if (controls?.errors && !controls?.errors['minLen']) {
        return null;
      }

      if (!this.passwordValidate.minLen.test(controls.value)) {
        return { minLen: true };
      } else {
        return null;
      }
    };
  }

  static password_noSymbolError(): ValidatorFn {
    return (controls: AbstractControl) => {
      if (controls?.errors && !controls?.errors['symbol']) {
        return null;
      }

      if (!this.passwordValidate.hasSymbol.test(controls.value)) {
        return { symbol: true };
      } else {
        return null;
      }
    };
  }

  static validateFile(
    controlName: string,
    types: Array<string>,
    size: number
  ): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);

      if (control?.errors && !control?.errors['file']) {
        return null;
      }

      if (control?.value.length > 0) {
        const files = control?.value;
        files.forEach((file: any) => {
          const fileType = file.type;
          const fileSize = file.size / 1024 / 1024;

          if (!types.includes(fileType)) {
            controls.get(controlName)?.setErrors({ file: true });
            return { file: true };
          }

          if (fileSize > size) {
            controls.get(controlName)?.setErrors({ file: true });
            return { file: true };
          }

          return null;
        });
      }

      return null;
    };
  }
}
