<form
  [formGroup]="changePasswordForm"
  (ngSubmit)="onSubmit()"
  class="d-flex flex-column justify-content-center px-3 px-md-0"
>
  <div class="mb-3">
    <div class="form-group p-2 bg-white">
      <label for="password" class="mb-1">Password</label>
      <input
        type="password"
        id="password"
        formControlName="password"
        placeholder="Enter your password"
      />
      <img
        src="assets/icons/closepassword-icon.svg"
        alt="wrong"
        *ngIf="password?.invalid && password?.touched"
      />
      <img
        src="assets/icons/verification-checked-pink.svg"
        alt="correct"
        *ngIf="password?.valid"
      />
    </div>
    <div *ngIf="password?.invalid && password?.touched && password?.dirty">
      <div *ngIf="password?.errors?.['required']">
        <small class="text-danger">Password is required</small>
      </div>

      <div *ngIf="password?.errors?.['minlength']">
        <small class="text-danger"
          >Password must be at least 6 characters</small
        >
      </div>
      <div *ngIf="password?.errors?.['pattern']">
        <small class="text-danger">
          Password must contain at least one uppercase letter, one lowercase
          letter, one number, and one special character</small
        >
      </div>
    </div>
  </div>
  <div class="mb-3">
    <div class="form-group p-2 bg-white">
      <label for="confirmPassword" class="mb-1">Confirm Password</label>
      <input
        type="password"
        id="confirmPassword"
        formControlName="confirmPassword"
        placeholder="Enter your password"
      />
      <img
        src="assets/icons/closepassword-icon.svg"
        alt="wrong"
        *ngIf="confirmPassword?.invalid && confirmPassword?.touched"
      />
      <img
        src="assets/icons/verification-checked-pink.svg"
        alt="correct"
        *ngIf="confirmPassword?.valid"
      />
    </div>
    <div
      *ngIf="
        confirmPassword?.invalid &&
        (confirmPassword?.touched || confirmPassword?.dirty)
      "
    >
      <div *ngIf="confirmPassword?.errors?.['required']">
        <small class="text-danger">Confirm Password is required</small>
      </div>
      <div *ngIf="confirmPassword?.errors?.['matching']">
        <small class="text-danger">Confirm Password must match</small>
      </div>
    </div>
  </div>
  <button
    class="'w-100'"
    type="submit"
    [disabled]="!changePasswordForm.valid"
  >
    Submit
  </button>

  <!-- <swap-button
    [ngClass]="'w-100'"
    [type]="'submit'"
    [text]="'Submit'"
  ></swap-button> -->
</form>
