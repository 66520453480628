<swap-modal
  [modalId]="modalId"
  [centered]="true"
  [keyboard]="false"
  [backdrop]="'static'"
>
  <div class="row m-3">
    <div
      class="col-12 d-flex justify-content-between align-item-center mt-2 mb-5"
    >
      <h5 class="fw-700 mb-0">
        {{ faq ? "Edit FAQ" : "Create FAQ" }}
      </h5>
      <button
        type="button"
        class="closeBtn"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="faq = null"
        #closeBtn
      >
        <img src="assets/icons/close-icon-pink.svg" alt="" />
      </button>
    </div>
    <div class="col-12 mb-5">
      <form
        class="row gy-4 justify-content-center"
        [formGroup]="faqForm"
        (ngSubmit)="onSubmit()"
      >
        <div class="col-12 mb-3">
          <label class="form-label fw-700 text-dark">Category</label>
          <input
            type="text"
            class="input-border"
            formControlName="category"
            placeholder="Enter category"
          />
          <div
            class="text-danger"
            *ngIf="category?.invalid && (category?.touched || category?.dirty)"
          >
            <small *ngIf="category?.errors?.['required']"
              >Category is required</small
            >
          </div>
        </div>
        <div class="col-12 mb-3">
          <label class="form-label fw-700 text-dark">Question</label>
          <input
            type="text"
            class="input-border"
            formControlName="question"
            placeholder="Enter question"
          />
          <div
            class="text-danger"
            *ngIf="question?.invalid && (question?.touched || question?.dirty)"
          >
            <small *ngIf="question?.errors?.['required']"
              >Question is required</small
            >
          </div>
        </div>
        <div class="col-12 mb-3">
          <label class="form-label fw-700 text-dark">Answer</label>
          <textarea
            class="input-border"
            formControlName="answer"
            placeholder="Enter answer"
            rows="7"
            style="resize: none"
          >
          </textarea>
          <div
            class="text-danger"
            *ngIf="answer?.invalid && (answer?.touched || answer?.dirty)"
          >
            <small *ngIf="answer?.errors?.['required']"
              >Answer is required</small
            >
          </div>
        </div>

        <div class="col-11 text-end">
          <button
            class="swapBtn"
            (click)="closeBtn.click(); faq = null"
            type="button"
          >
            Cancel
          </button>
          <button
            class="swapBtn update ms-2"
            type="submit"
            data-bs-dismiss="modal"
            aria-label="Close"
            [disabled]="faqForm.invalid"
          >
            {{ faq ? "Update" : "Create" }}
          </button>
        </div>
      </form>
    </div>
  </div>
</swap-modal>
