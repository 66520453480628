<swap-modal [modalId]="modalId" [right]="true">
  <div class="row m-3 mx-0">
    <div class="col-12 d-flex justify-content-between py-3 mb-5 border-bottom">
      <h5 class="fw-700 text-center mb-0">User Detail</h5>
      <button
        type="button"
        class="closeBtn"
        data-bs-dismiss="modal"
        aria-label="Close"
        #closeBtn
      >
        <img src="assets/icons/close-icon.svg" alt="" />
      </button>
    </div>
    <div class="col-12 mb-5">
      <div class="text-center mb-3">
        <img
          [src]="userPlanDetails?.avatar || 'assets/icons/user.svg'"
          alt=""
          loading="lazy"
          width="80"
          height="80"
          class="img-fluid rounded-circle"
        />
        <h5 class="fw-700 mt-3">
          {{
            userPlanDetails?.name
              ? userPlanDetails?.name?.first + " " + userPlanDetails?.name?.last
              : "N/A"
          }}
        </h5>
        <div
          class="d-flex align-items-center justify-content-center gap-2 mb-3"
        >
          <p class="mb-0 text-muted">
            {{ userPlanDetails?.username || "N/A" }}
          </p>
          <span class="dot"></span>
          <p class="mb-0 text-muted">
            Joined {{ userPlanDetails?.createdAt | date : "MMMM, y" }}
          </p>
        </div>
        <!-- Nav tabs -->
        <ul
          class="nav nav-pills justify-content-center"
          id="myTab"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              Details
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              Activity
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="messages-tab"
              data-bs-toggle="tab"
              data-bs-target="#messages"
              type="button"
              role="tab"
              aria-controls="messages"
              aria-selected="false"
            >
              Payment History
            </button>
          </li>
        </ul>
      </div>
      <!-- Tab panes -->
      <div class="tab-content">
        <div
          class="tab-pane active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <div
            class="d-flex align-items-center justify-content-between mb-3 pb-4"
          >
            <p class="mb-0">Status</p>
            <p
              class="mb-0 status"
              [class.danger]="
                userPlanDetails?.tokens?.subscription?.period === 'cancel'
              "
            >
              {{
                userPlanDetails?.tokens?.subscription?.period !== "cancel"
                  ? "Active"
                  : "Inactive"
              }}
            </p>
          </div>
          <div class="row g-0 gy-3 detail-row pb-3">
            <div class="col-6">
              <div class="d-flex align-items-center gap-3">
                <span class="dot lg"></span>
                <div>
                  <p class="mb-0 fw-700">Plan Name</p>
                  <p class="mb-0">
                    {{
                      userPlanDetails?.tokens?.subscription?.plan?.name
                        | titlecase
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="d-flex align-items-center gap-3">
                <span class="dot lg"></span>
                <div>
                  <p class="mb-0 fw-700">Billing Frequency</p>
                  <p class="mb-0">
                    {{
                      userPlanDetails?.tokens?.subscription?.period | titlecase
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="d-flex align-items-center gap-3">
                <span class="dot lg"></span>
                <div>
                  <p class="mb-0 fw-700">Subscribed on</p>
                  <p class="mb-0">
                    {{
                      (userPlanDetails?.tokens?.subscription?.plan?.name ===
                      "Free"
                        ? userPlanDetails?.createdAt
                        : userPlanDetails?.payment?.createdAt
                      ) | date : "mediumDate"
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="d-flex align-items-center gap-3">
                <span class="dot lg"></span>
                <div>
                  <p class="mb-0 fw-700">Renews on</p>
                  <p class="mb-0">
                    {{
                      userPlanDetails?.tokens?.subscription?.plan?.name ===
                      "Free"
                        ? "Never expires"
                        : (userPlanDetails?.tokens?.subscription?.endDate
                          | date : "mediumDate")
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="d-flex align-items-center gap-3">
                <span class="dot lg"></span>
                <div>
                  <p class="mb-0 fw-700">Payment Method</p>
                  <p class="mb-0">
                    {{
                      (userPlanDetails?.payment?.paymentGateway | titlecase) ||
                        "N/A"
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center gap-3 mt-3">
            <button
              class="btn-grade"
              (click)="openPlanUpgradeModal.emit('upgrade')"
            >
              <img src="assets/icons/arrow-up.svg" alt="" />
              Upgrade
            </button>
            <button
              class="btn-grade"
              (click)="openPlanUpgradeModal.emit('downgrade')"
            >
              <img src="assets/icons/arrow-down.svg" alt="" /> Downgrade
            </button>
            <button
              class="btn-grade danger"
              data-bs-toggle="modal"
              attr.data-bs-target="{{ modalId1 }}"
            >
              Cancel Plan
            </button>
          </div>
        </div>
        <div
          class="tab-pane"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <div
            class="d-flex align-items-center justify-content-between mb-3 pb-4"
          >
            <p class="mb-0 fw-700">Log</p>
            <button class="btn-vw" (click)="selectDropdownFilter($event)">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.66685 11.9229H1.41602C1.07102 11.9229 0.791016 11.6429 0.791016 11.2979C0.791016 10.9529 1.07102 10.6729 1.41602 10.6729H6.66685C7.01185 10.6729 7.29185 10.9529 7.29185 11.2979C7.29185 11.6429 7.01185 11.9229 6.66685 11.9229Z"
                  fill="#383A47"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.2588 3.8457H9.00879C8.66379 3.8457 8.38379 3.5657 8.38379 3.2207C8.38379 2.8757 8.66379 2.5957 9.00879 2.5957H14.2588C14.6038 2.5957 14.8838 2.8757 14.8838 3.2207C14.8838 3.5657 14.6038 3.8457 14.2588 3.8457Z"
                  fill="#383A47"
                />
                <mask
                  id="mask0_8080_30529"
                  style="mask-type: luminance"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="6"
                  height="6"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.766602 0.595703H5.95477V5.75537H0.766602V0.595703Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_8080_30529)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.36077 1.84521C2.61993 1.84521 2.0166 2.44188 2.0166 3.17605C2.0166 3.90938 2.61993 4.50522 3.36077 4.50522C4.10243 4.50522 4.70493 3.90938 4.70493 3.17605C4.70493 2.44188 4.10243 1.84521 3.36077 1.84521ZM3.36077 5.75522C1.93077 5.75522 0.766602 4.59855 0.766602 3.17605C0.766602 1.75355 1.93077 0.595215 3.36077 0.595215C4.7916 0.595215 5.95494 1.75355 5.95494 3.17605C5.95494 4.59855 4.7916 5.75522 3.36077 5.75522Z"
                    fill="#383A47"
                  />
                </g>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.7561 9.93555C12.0145 9.93555 11.4111 10.5322 11.4111 11.2655C11.4111 11.9997 12.0145 12.5955 12.7561 12.5955C13.497 12.5955 14.0995 11.9997 14.0995 11.2655C14.0995 10.5322 13.497 9.93555 12.7561 9.93555ZM12.7561 13.8455C11.3253 13.8455 10.1611 12.688 10.1611 11.2655C10.1611 9.84305 11.3253 8.68555 12.7561 8.68555C14.1861 8.68555 15.3495 9.84305 15.3495 11.2655C15.3495 12.688 14.1861 13.8455 12.7561 13.8455Z"
                  fill="#383A47"
                />
              </svg>
              Filters
              <div class="dropdown-filters">
                <a
                  *ngFor="let option of filterOptions"
                  class="dropdown-filter text-decoration-none text-muted"
                  (click)="filterActivityByStatus(option)"
                  >{{ option }}</a
                >
              </div>
            </button>
          </div>
          <ng-container *ngFor="let activity of activityLog">
            <p class="mb-0 text-center text-muted mb-3">
              {{
                activity.date === "Today"
                  ? activity.date
                  : (activity.date | date : "dd MMMM yyyy" : utc_offset)
              }}
            </p>
            <div class="row g-0 gy-3 mb-5">
              <div
                class="col-12 pb-2"
                *ngFor="
                  let log of activity.activityLog
                    | search : activityTerm : ['type']
                "
              >
                <div class="d-flex align-items-center justify-content-between">
                  <p
                    class="mb-0 fw-500"
                    [innerHTML]="globals.replaceCurlies(log.log)"
                  ></p>
                  <p class="mb-0">
                    {{ log.type | titlecase }}
                  </p>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <small class="mb-0 text-end" style="white-space: nowrap">
                    {{ log.createdAt | date : "yyyy-MM-dd" : utc_offset }}
                    {{ log.createdAt | date : "HH:mm" : utc_offset }}
                  </small>
                  <small
                    class="mb-0 text-end"
                    style="white-space: nowrap"
                    [class.text-success]="log.status === 'successful'"
                    [class.text-warning]="log.status === 'pending'"
                    [class.text-danger]="log.status === 'failed'"
                  >
                    {{ log.status | titlecase }}</small
                  >
                </div>
              </div>
              <!-- <div class="col-12 pb-2">
                <div class="d-flex align-items-center justify-content-between">
                  <p class="mb-0 fw-500">
                    Upgraded from Monthly Classic to Yearly Premium by
                    <span class="text-primary">Damilola A.</span>
                  </p>
                  <p class="mb-0">Upgrade</p>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <small class="mb-0 text-end" style="white-space: nowrap">
                    2023-11-23 17:44</small
                  >
                  <small
                    class="mb-0 text-end text-warning"
                    style="white-space: nowrap"
                  >
                    Pending</small
                  >
                </div>
              </div> -->
            </div>
          </ng-container>
        </div>
        <div
          class="tab-pane"
          id="messages"
          role="tabpanel"
          aria-labelledby="messages-tab"
        >
          <ng-container *ngFor="let payments of paymentHistory">
            <p class="mb-0 text-center text-muted mb-3">
              {{
                payments.date === "Today"
                  ? payments.date
                  : (payments.date | date : "dd MMMM yyyy" : utc_offset)
              }}
            </p>
            <div class="row g-0 gy-3 mb-5">
              <div
                class="col-12 hoverable p-2"
                (click)="globals.closeModal()"
                [routerLink]="
                  '/main/plan-management/plan/' +
                  globals.router.url.split('/')[4] +
                  '/receipt'
                "
                *ngFor="let payment of payments.payments"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <p class="mb-0 fw-500">
                    {{ payment.planId.name | titlecase }} plan:
                    {{
                      "$" + payment.amount === payment.planId.price_per_month
                        ? "Monthly"
                        : "Yearly"
                    }}
                  </p>
                  <p class="text-primary mb-0">{{ payment.amount }} USD</p>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <small class="mb-0 text-end" style="white-space: nowrap">
                    {{ payment.createdAt | date : "yyyy-MM-dd" : utc_offset }}
                    {{ payment.createdAt | date : "HH:mm" : utc_offset }}
                  </small>
                  <small class="mb-0 text-end" style="white-space: nowrap">
                    {{ payment.paymentGateway | titlecase }}</small
                  >
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</swap-modal>

<cancel-plan
  [modalId]="modalId1"
  [username]="swapUserService.swapUser?.username || ''"
  (emitCancelPlan)="globals.openModal(modalId2)"
></cancel-plan>
<cancel-plan-reason
  [modalId]="modalId2"
  (emitCancelPlan)="cancelPlan($event)"
></cancel-plan-reason>
