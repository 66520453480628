<swap-modal [modalId]="modalId" [right]="true" [noPadding]="true">
  <div class="modal-header mt-2">
    <h5 class="modal-title font-18 fw-700" id="staticBackdropLabel">
      Preview Plan
    </h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body px-4" style="background: #f8f8fa">
    <div
      class="card plan p-4 shadow-sm rounded-4 d-flex flex-column justify-content-between h-100"
    >
      <div>
        <p
          class="mb-0 font-14 fw-bolder mb-3"
          [ngClass]="{
            classic: plan?.name === 'Classic',
            premium: plan?.name === 'Premium',
            plus: plan?.name === 'Plus',
            enterprise: plan?.name === 'Enterprise'
          }"
        >
          {{ plan?.name }}
        </p>
        <div class="d-flex gap-3">
          <h1
            class="mb-3"
            [ngClass]="{
              classic: plan?.name === 'Classic',
              premium: plan?.name === 'Premium',
              plus: plan?.name === 'Plus',
              enterprise: plan?.name === 'Enterprise'
            }"
          >
            {{ plan?.price_per_month }}
          </h1>
          <p
            class="mb-0"
            *ngIf="plan?.name !== 'Free' && plan?.name !== 'Enterprise'"
          >
            /month<br />
            ({{ plan?.price_per_year }} per year)
          </p>
        </div>
        <p class="mb-3"><b>User Type: </b> {{ plan?.user_type }}</p>
        <div class="row g-0 gy-3">
          <div
            class="col-12 d-flex gap-2"
            *ngFor="
              let feature of plan?.offers
                ? plan?.offers
                : plan?.privileges?.offers
            "
          >
            <img [src]="'assets/icons/check-success.svg'" alt="" />
            <p class="mb-0">
              {{ feature.name }} {{ feature.value && "-" }}
              <span class="text-primary">{{ feature.value }}</span>
            </p>
          </div>

          <h6 class="fw-bolder mb-0 mt-4">Support</h6>
          <div
            class="col-12 d-flex gap-2"
            *ngFor="
              let sup of plan?.support
                ? plan?.support
                : plan?.privileges?.support
            "
          >
            <img
              [src]="
                sup?.value
                  ? 'assets/icons/check-success.svg'
                  : 'assets/icons/check-danger.svg'
              "
              alt=""
            />
            <p class="mb-0">
              {{ sup.name }}
            </p>
          </div>
          <h6 class="fw-bolder mb-0 mt-4">Badge Preference</h6>
          <div
            class="col-12 d-flex gap-2"
            *ngFor="
              let badge of plan?.user ? plan?.user : plan?.privileges?.user
            "
          >
            <img
              [src]="
                badge.value
                  ? 'assets/icons/check-success.svg'
                  : 'assets/icons/check-danger.svg'
              "
              alt=""
            />
            <p class="mb-0">
              {{ badge.name }}
            </p>
          </div>
        </div>
      </div>
      <button class="btn btn-primary w-100 mt-4">
        {{ plan?.cta }}
      </button>
    </div>
  </div>
</swap-modal>
