import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import io from 'socket.io-client';
import { environment } from '../../../../../src/environments/environment';
import { EmailToUser, Ticket } from '../../core/IApp';
import { GlobalsService } from '../../core/globals';
import { RequestService } from '../../core/request';

@Injectable({
  providedIn: 'root',
})
export class HelpHistoryService {
  tickets: Ticket[] = [];
  ticket: Ticket | any;
  ticketConversations: Ticket[] = [];
  ticketConversation: Ticket | any;
  emailThread: Array<any> = [];
  notificationListener: any;
  // Pagination
  ticketsPagination: any = {
    itemsPerPage: 5,
    totalItemsCount: 0,
    myTicketsTotal: 0,
    page: 1,
    search: '',
    category: '',
    ticketStatus: '',
    sort: {
      field: 'createdAt',
      order: 'desc',
    },
  };
  ticketType: string = 'all';

  messagesPagination = {
    page: 1,
  };

  currentResultPerPage = 5;
  page: any = 1;
  resultPerPage = [5, 10, 15, 20, 25];
  totalPages: number[] = [];

  // Sorting
  sortBy: string = 'createdAt';
  orderBy: string = 'desc';
  search: string = '';
  status: string = 'all';

  // Socket io
  socket: any;
  constructor(private globals: GlobalsService, private api: RequestService) {}

  ngOnDestroy(): void {
    this.notificationListener?.unsubscribe();
  }

  initializeSocket(token: string) {
    try {
      this.socket = io(environment.BASE_URL.replace('/api/', ''), {
        transportOptions: {
          polling: {
            extraHeaders: {
              Authorization: 'Bearer ' + token,
            },
          },
        },
      });
      
      this.notificationListener = this.getNewNotifications().subscribe(
        (data: any) => {
          this.globals.notifications.unshift(data);
          this.globals.unreadMessages = true;
          this.globals.numberOfUnreadMessages++;
          this.globals.checkForNotifications(this.globals.user?.settings, data);
        }
      );
    } catch (ex) {
      console.log(ex);
    }
  }

  disconnectSocket() {
    this.socket && this.socket.disconnect();
  }


  // This function is used to listen to notifications
  getNewNotifications(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('notifications', async (data: any) => {
        observer.next(data);
      });
    });
  }

  handleTicketUpdate(updatedTicket: any) {
    const index = this.tickets.findIndex(ticket => ticket._id === updatedTicket._id);
    if (index !== -1) {
      this.tickets[index] = updatedTicket;
    } else {
      this.tickets.push(updatedTicket);
    }
  }
  
  async getHelpHistory() {
    return await new Promise(async (resolve, reject) => {
      try {
        let params = '';
        Object.keys(this.ticketsPagination).forEach((key) => {
          if (key === 'sort') {
            params += `&sort=${this.ticketsPagination.sort.field}&order=${this.ticketsPagination.sort.order}`;
          } else {
            params += `&${key}=${this.ticketsPagination[key]}`;
          }
        });
        const resp: any = (await this.api.get(
          `tickets/all${
            this.ticketType != 'all' ? `/${this.ticketType}` : ''
          }?${params}`
        )) as Ticket[];
        this.tickets = resp.data.tickets;
        this.ticketsPagination.totalItemsCount = resp.data.totalItemsCount;
        await this.getHelpHistoryCount();
        resolve(true);
      } catch (ex) {
        reject(ex);
      }
    });
  }





  
  
  

  async getUserHelpHistory(userId: string) {
    try {
      let params = `userId=${userId}`;
      Object.keys(this.ticketsPagination).forEach((key) => {
        if (key === 'sort') {
          params += `&sort=${this.ticketsPagination.sort.field}&order=${this.ticketsPagination.sort.order}`;
        } else {
          params += `&${key}=${this.ticketsPagination[key]}`;
        }
      });
  
      const resp: any = await this.api.get(`tickets/user?${params}`);
      this.tickets = resp.data.tickets;
      this.ticketsPagination.totalItemsCount = resp.data.totalItemsCount;
      await this.getHelpHistoryCount();
      return true;
    } catch (ex) {
      console.error('Error fetching user tickets:', ex);
      throw ex; 
    }
  }
  
  
  
  getTicketsByUserId(userId: string): Ticket[] {
    return this.tickets.filter(ticket => ticket.user._id === userId);
  }


  async getTicketsByMode(mode: string, ticketId: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        let params = '';
        Object.keys(this.ticketsPagination).forEach((key) => {
          if (key === 'sort') {
            params += `&sort=${this.ticketsPagination.sort.field}&order=${this.ticketsPagination.sort.order}`;
          } else {
            params += `&${key}=${this.ticketsPagination[key]}`;
          }
        });
        const resp: any = (await this.api.get(
          `tickets/related/${mode}/${ticketId}?${params}`
        )) as Ticket[];
        this.tickets = resp.data.tickets;
        await this.getHelpHistoryCount();
        resolve(true);
      } catch (ex: any) {
        this.globals.toast.error(ex.message);
        reject(ex);
      }
    });
  }

  async getHelpHistoryCount() {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(`tickets/count`);
        this.ticketsPagination.totalItemsCount = resp.data.countAll;
        this.ticketsPagination.myTicketsTotal = resp.data.countMine;
        this.globals.ticketTotal = resp.data.countNew;
        this.globals.storage.setTicketTotal(resp.data.countNew);
        resolve(true);
      } catch (ex) {
        reject(ex);
      }
    });
  }

  getNewTickets(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('triggers', (data: any) => {
        observer.next(data);
      });
    });
  }

  async sendMessage(message: any) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.socket.emit(
          'ticket-messages',
          {
            content: message.content,
            ticketId: message.ticketId,
          },
          (res: any) => {
            if (res.status !== 200) {
              this.globals.toast.error(res.message);
              throw new Error(res.message);
            }
          }
        );
        resolve(true);
      } catch (ex) {
        reject(ex);
      }
    });
  }

  receiveMessage(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('ticket-messages', (data: any) => {
        observer.next(data);
      });
    });
  }

  async updateTicket(ticket: any) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.patch(`tickets/${ticket._id}`, ticket);
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex.message);
        reject(ex);
      }
    });
  }

  async uploadAttachment({ note, attachment, _id }: any) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        let formData = new FormData();
        for (let i = 0; i < attachment.length; i++) {
          formData.append('notes', attachment[i]);
        }
        formData.append('note', note);
        this.api.headers = {
          'content-type': 'multipart/form-data',
        };
        const resp: any = await this.api.post(
          `tickets/notes/upload/${_id}`,
          formData
        );
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex.message);
        reject(ex);
      }
    });
  }

  async getAttachment(ticketId: string, noteId: string, uploadId: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get(
          `tickets/notes/upload/${ticketId}/${noteId}/${uploadId}`
        );
        this.globals.spinner.hide();
        resolve(resp.data.upload.signedUrl);
      } catch (ex) {
        reject(ex);
      }
    });
  }

  async createTicket(subject: string, category: string, userId: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post(`tickets`, {
          subject,
          category,
          userId,
        });
        this.globals.spinner.hide();
        resolve(resp.data);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  async getTicketMessagesById(id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get(
          `tickets/messages/${id}?page=${this.messagesPagination.page}`
        );
        this.ticketConversation = resp.data;
        this.globals.spinner.hide();
        resolve(resp.data);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex.message);
        if (ex.message.toLocaleLowerCase().includes('authorize')) {
          window.open(
            ex.message.split('Authorize this app by visiting this URL:')[1],
            '_blank'
          );
          return;
        }
        reject(ex.message);
      }
    });
  }

  async sendEmailToUser({
    to,
    subject,
    category,
    content,
    ticketId,
    mailId,
  }: EmailToUser) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post(`tickets/mail/${ticketId}`, {
          mailId,
          content,
        });
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex.message);
        if (ex.message.toLocaleLowerCase().includes('authorize')) {
          window.open(
            ex.message.split('Authorize this app by visiting this URL:')[1],
            '_blank'
          );
          return;
        }
        reject(ex);
      }
    });
  }

  async getEmailByMailId(mailId: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get(`tickets/mail/${mailId}`);
        this.emailThread = resp.data.messages;
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  resetParams() {
    this.tickets = [];
    this.ticket = {} as Ticket;
    this.ticketConversations = [];
    this.ticketConversation = {} as Ticket;
    this.ticketType = 'all';
    this.emailThread = [];

    // Pagination
    this.ticketsPagination = {
      itemsPerPage: 5,
      totalItemsCount: 0,
      myTicketsTotal: 0,
      page: 1,
      category: '',
      search: '',
      ticketStatus: '',
      sort: {
        field: '',
        order: 'desc',
      },
    };
  }
}
