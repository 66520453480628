import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgOtpInputConfig } from 'ng-otp-input/public_api';

@Component({
  selector: 'otp-form',
  templateUrl: './otp-form.component.html',
  styleUrls: ['./otp-form.component.scss'],
})
export class OtpFormComponent {
  @Output() otpFormSubmit: EventEmitter<any> = new EventEmitter();

  otpForm: FormGroup = new FormGroup({
    otp: new FormControl('', [Validators.required, Validators.minLength(6)]),
  });
  time: string = '1:00';

  otpConfig: NgOtpInputConfig = {
    length: 6,
    inputClass: 'each_input',
    inputStyles: {
      border: '2px solid #e0e0e0',
      borderRadius: '12px',
      fontFamily: 'U8',
      fontWeight: 'bold',
      fontSize: '1.5rem',
      transition: 'all 0.3s ease',
    },
  };

  constructor() {}

  ngOnInit() {
    // this.startTimer();
  }

  onOtpChange(otp: any) {
    this.otpForm.patchValue({ otp });
    if (otp.length === 6) {
      this.otpFormSubmit.emit(this.otpForm.value);
    }
  }

  onSubmit() {
    this.otpFormSubmit.emit(this.otpForm.value);
  }

  startTimer() {
    let time = 60;
    const interval = setInterval(() => {
      time--;
      // Add leading zero
      const minutes = Math.floor(time / 60);
      const seconds = time - minutes * 60;
      this.time = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      if (time === 0) {
        clearInterval(interval);
      }
    }, 1000);
  }
}
