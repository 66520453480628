import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'notifications-form',
  templateUrl: './notifications-form.component.html',
  styleUrls: ['./notifications-form.component.scss'],
})
export class NotificationsFormComponent {
  @Input() notifications: any = {};
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

  notificationsForm: FormGroup = new FormGroup({});

  constructor() { }
  
  submit() {
    this.onSubmit.emit(this.notificationsForm.value);
  }
}
