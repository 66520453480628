import { Component, Input } from '@angular/core';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'view-image',
  templateUrl: './view-image.component.html',
  styleUrls: ['./view-image.component.scss'],
})
export class ViewImageComponent {
  @Input() modalId: string = '';

  @Input() previousModal: string = '';

  @Input() image: string = '';

  zoom: boolean = false;

  constructor(
    public globals: GlobalsService
  ) { }
  
}
