<app-navbar></app-navbar>
<div class="mt-5">
  <section class="d-flex justify-content-between align-items-center mb-4">
    <expat-search-bar
      [placeholder]="'Search for users..'"
      (search)="search($event)"
    ></expat-search-bar>
    <div class="d-flex align-items-center justify-content-end gap-4">
      <button
        class="input-border dropdown"
        (click)="selectDropdown($event)"
        [ngClass]="{
          'text-muted': !swapUserService.swapUsersPagination.mode.name
        }"
      >
        {{ swapUserService.swapUsersPagination.mode.name || "Filter" }}
        <div class="dropdown-options">
          <div
            *ngFor="let filter of filterTabs"
            class="dropdown-option text-muted"
            [hidden]="filter.value == ''"
            (click)="
              swapUserService.swapUsersPagination.mode = filter;
              this.swapUserService.getSwapUsers()
            "
          >
            {{ filter.name }}
          </div>
        </div>
      </button>
      <button
        class="reset-btn"
        (click)="resetFilters()"
        [disabled]="!swapUserService.swapUsersPagination.mode"
      >
        Reset filter
      </button>
      <p
        class="mb-0 font-16"
        *ngIf="
          globals.user?.role?.toLocaleLowerCase()?.replaceAll(' ', '') !==
            'technicalsupport' &&
          globals.user?.role?.toLocaleLowerCase()?.replaceAll(' ', '') !==
            'customersupport'
        "
      >
        <b>All</b>
        ({{ swapUserService.swapUsersPagination.totalItemsCount }})
      </p>
    </div>
  </section>
  <ng-container *ngIf="swapUserService.swapUsers.length != 0">
    <table class="table table-hover">
      <thead class="">
        <tr class="font-16 fw-700">
          <td scope="col" (click)="sortUsersBy('username')">
            Users
            <img src="assets/icons/arrows-down-up.svg" alt="..." class="ms-2" />
          </td>
          <td scope="col" (click)="sortUsersBy('country')">
            Location/Currency
            <img src="assets/icons/arrows-down-up.svg" alt="..." class="ms-2" />
          </td>
          <td scope="col" (click)="sortUsersBy('email')">
            Email/Phone number
            <img src="assets/icons/arrows-down-up.svg" alt="..." class="ms-2" />
          </td>
          <td scope="col">Verification Status</td>
          <td scope="col">Plans</td>
          <td scope="col" (click)="sortUsersBy('transactions')">
            Transactions
            <img src="assets/icons/arrows-down-up.svg" alt="..." class="ms-2" />
          </td>
          <td scope="col">Actions</td>
        </tr>
      </thead>
      <tbody>
        <tr class="font-14" *ngFor="let user of swapUserService.swapUsers">
          <td
            class="fw-700 font-14 pointer"
            class="pointer"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navigateToUserProfile(user._id, 'overview')"
          >
            <div class="gap-3 d-flex align-items-center">
              <img
                class="mr-btn-20 cir"
                [src]="user.avatar"
                alt="user-image"
                class="profileImg"
              />
              <div class="">
                <h6>
                  <b>
                    {{ user.username }}
                    <img
                      loading="lazy"
                      [src]="
                        user?.verificationBadge?.rank == 0
                          ? 'assets/icons/trusted-partner.svg'
                          : user?.verificationBadge?.rank == 1
                          ? 'assets/icons/verified-business.svg'
                          : 'assets/icons/peoples-partner.svg'
                      "
                      *ngIf="user?.verificationBadge"
                      alt=""
                      style="
                        width: 1.5rem;
                        height: 1.5rem;
                        object-fit: contain;
                        margin-left: 5px;
                      "
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      [title]="user?.verificationBadge?.name"
                    />
                  </b>
                </h6>
                <p class="mb-0">
                  <span class="text-muted d-flex align-items-center gap-1">
                    <b class="text-warning d-flex align-items-center gap-1">
                      <img src="assets/images/star.svg" alt="..." />
                      {{
                        user.metrics.reviews.averageRatings | number : "1.0-0"
                      }}
                    </b>
                    ({{ user.metrics.reviews.total || 0 }})

                    <span
                      *ngIf="user.freeze"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="User is frozen"
                      >🥶</span
                    >
                    <span
                      *ngIf="user.deactivated?.active"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="User is deactivated"
                    >
                      <img src="assets/icons/deleted-user.svg" alt="..." />
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </td>
          <td class="pointer" routerLink="/main/users/profile/{{ user._id }}">
            <ng-container *ngIf="user.address.country; else showNothing">
              <div class="d-flex align-items-center">
                <img
                  [src]="
                    globals.searchCountryBy(user.address.country, 'name')?.flag
                  "
                  alt="Country Flag"
                  class="flags"
                />
                <h6 class="mb-0">
                  {{ user.address.country }}
                </h6>
              </div>
              <div class="text-muted">
                {{
                  globals.searchCountryBy(user.address.country, "name")
                    ?.currency_name
                }}
                ({{
                  globals.searchCountryBy(user.address.country, "name")
                    ?.currency
                }})
              </div>
            </ng-container>
            <ng-template #showNothing>
              <p class="fw-bold mb-0 text-muted">Location not set</p>
            </ng-template>
          </td>
          <td class="pointer" (click)="navigateToUserProfile(user._id, 'kyc')">
            <div class="fw-400 font-16">
              {{ user.email }}
            </div>
            <div class="text-muted">
              {{ user.phone }}
            </div>
          </td>
          <td
            class="pointer"
            routerLink="/main/users/profile/{{ user._id }}"
            [queryParams]="{ tab: 'kyc' }"
          >
            <div class="d-flex align-items-center">
              <h6
                class="mb-0"
                [ngClass]="{ 'text-danger': user.verificationStatus.rank == 0 }"
              >
                <b>
                  {{
                    user.verificationStatus.rank != 0
                      ? "Verified"
                      : "Unverified"
                  }}
                </b>
              </h6>
              <img
                [src]="
                  'assets/images/' +
                  user.verificationStatus.name.toLowerCase() +
                  '-' +
                  user.verificationStatus.rank.toString() +
                  '.svg'
                "
                *ngIf="user.verificationStatus.rank != 0"
                alt="..."
                class="verified-image"
              />
            </div>
            <p class="text-muted m-0" *ngIf="user.verificationStatus.rank != 0">
              {{
                user.verificationStatus.name +
                  " " +
                  user.verificationStatus.rank | titlecase
              }}
            </p>
          </td>
          <td
            class="pointer"
            routerLink="/main/users/profile/{{ user._id }}"
            [queryParams]="{ tab: 'kyc' }"
          >
            <div class="fw-400 font-16">Free</div>
          </td>
          <td
            class="fw-400 font-16 pointer"
            routerLink="/main/users/profile/{{ user._id }}"
            [queryParams]="{ tab: 'kyc' }"
          >
            {{ user.metrics.biddings.total || 0 }}
          </td>
          <td>
            <button class="btn-view" (click)="selectDropdown($event)">
              <svg
                width="4"
                height="16"
                viewBox="0 0 4 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.90169 8.83268C2.36193 8.83268 2.73503 8.45959 2.73503 7.99935C2.73503 7.53911 2.36193 7.16602 1.90169 7.16602C1.44146 7.16602 1.06836 7.53911 1.06836 7.99935C1.06836 8.45959 1.44146 8.83268 1.90169 8.83268Z"
                  stroke="#3d1560"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.90169 14.6667C2.36193 14.6667 2.73503 14.2936 2.73503 13.8333C2.73503 13.3731 2.36193 13 1.90169 13C1.44146 13 1.06836 13.3731 1.06836 13.8333C1.06836 14.2936 1.44146 14.6667 1.90169 14.6667Z"
                  stroke="#3d1560"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.90169 2.99967C2.36193 2.99967 2.73503 2.62658 2.73503 2.16634C2.73503 1.7061 2.36193 1.33301 1.90169 1.33301C1.44146 1.33301 1.06836 1.7061 1.06836 2.16634C1.06836 2.62658 1.44146 2.99967 1.90169 2.99967Z"
                  stroke="#3d1560"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div class="dropdown-options">
                <a
                  href="#"
                  class="dropdown-option text-decoration-none text-muted"
                  routerLink="/main/users/profile/{{ user._id }}"
                  >View Profile</a
                >
                <a
                  data-bs-toggle="modal"
                  [attr.data-bs-target]="modalId4"
                  (click)="swapUserService.swapUser = user"
                  *ngIf="
                    !user.freeze &&
                    globals.user?.role
                      ?.toLocaleLowerCase()
                      ?.replaceAll(' ', '') !== 'customersupport'
                  "
                  class="dropdown-option text-decoration-none text-muted"
                  >Freeze User</a
                >
                <a
                  (click)="swapUserService.swapUser = user; unfreezeAccount()"
                  *ngIf="
                    user.freeze &&
                    globals.user?.role
                      ?.toLocaleLowerCase()
                      ?.replaceAll(' ', '') !== 'customersupport'
                  "
                  class="dropdown-option text-decoration-none text-muted text-nowrap"
                  >Unfreeze User</a
                >
                <a
                  data-bs-toggle="modal"
                  [attr.data-bs-target]="modalId3"
                  (click)="swapUserService.swapUser = user"
                  *ngIf="
                    !user.deactivated?.active &&
                    globals.user?.role
                      ?.toLocaleLowerCase()
                      ?.replaceAll(' ', '') !== 'customersupport'
                  "
                  class="dropdown-option text-decoration-none text-muted"
                  >Deactivate</a
                >
                <a
                  (click)="swapUserService.swapUser = user; activateAccount()"
                  *ngIf="
                    user.deactivated?.active &&
                    globals.user?.role
                      ?.toLocaleLowerCase()
                      ?.replaceAll(' ', '') !== 'customersupport'
                  "
                  class="dropdown-option text-decoration-none text-muted"
                  >Activate</a
                >
                <a
                  data-bs-toggle="modal"
                  [attr.data-bs-target]="modalId"
                  (click)="swapUserService.swapUser = user"
                  class="dropdown-option text-decoration-none text-muted"
                  >Contact User</a
                >
              </div>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="swapUserService.swapUsers.length > 0" class="px-4">
      <swap-pagination
        [totalItems]="swapUserService.swapUsersPagination.totalItemsCount"
        [itemsPerPage]="swapUserService.swapUsersPagination.itemsPerPage"
        (itemsPerPageChanged)="itemsPerPageChanged($event)"
        (pageChanged)="changePage($event)"
      ></swap-pagination>
    </div>
  </ng-container>
  <no-data
    *ngIf="swapUserService.swapUsers.length == 0"
    message="No Swap User Found"
  ></no-data>
</div>
