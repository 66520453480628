import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HelpHistoryService } from 'src/app/services/api/help-history/help-history.service';
import { Ticket, User } from 'src/app/services/core/IApp';

@Component({
  selector: 'assignee-form',
  templateUrl: './assignee-form.component.html',
  styleUrls: ['./assignee-form.component.scss'],
})
export class AssigneeFormComponent {
  @Input() ticket: Ticket | any;
  @Input() users: User[] = [];
  @Output() emitTicket: EventEmitter<Ticket> = new EventEmitter<Ticket>();
  @ViewChild('closeBtn') closeBtn: any;

  assigneeForm: FormGroup = new FormGroup({
    assignee: new FormControl('', Validators.compose([Validators.required])),
  });

  isSubmitting: boolean = false;

  constructor(private helpHistoryService: HelpHistoryService) {}

  ngOnDestroy() {
    this.assigneeForm.reset();
  }

  async onSubmit() {
    if (this.isSubmitting) return;

    this.isSubmitting = true;
    const selectedUserId = this.assigneeForm.value.assignee;

    if (!selectedUserId) {
      this.isSubmitting = false;
      return;
    }

    try {
      await this.assignToAdmin(this.ticket, selectedUserId);
      this.emitTicket.emit(this.ticket);
      this.assigneeForm.reset();
      this.closeBtn.nativeElement.click();
    } catch (error) {
      console.error('Error assigning ticket:', error);
    } finally {
      this.isSubmitting = false;
    }
  }

  async assignToAdmin(ticket: any, assigneeId: string) {
    await this.helpHistoryService.updateTicket({
      assignedAdminId: assigneeId,
      status: 'open',
      _id: ticket._id,
    });
    await this.helpHistoryService.getHelpHistory();
  }
}
