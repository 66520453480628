import { Component, Input } from '@angular/core';
import { Plan } from 'src/app/services/core/IApp';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'preview-plan',
  templateUrl: './preview-plan.component.html',
  styleUrls: ['./preview-plan.component.scss'],
})
export class PreviewPlanComponent {
  @Input() modalId: string = '';


  @Input() plan: any = null;

  constructor(public globals: GlobalsService) {}
}
