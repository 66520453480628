<form
  [formGroup]="signinForm"
  (ngSubmit)="onSubmit()"
  class="d-flex flex-column justify-content-center px-3 px-md-0"
>
  <div class="mb-3">
    <div class="form-group p-2 bg-white">
      <label for="email" class="mb-1">Email</label>
      <input
        type="email"
        id="email"
        formControlName="email"
        placeholder="Enter your email"
      />
      <img src="assets/icons/email-icon.svg" alt="email" />
    </div>
    <div *ngIf="email?.invalid && email?.touched">
      <small class="text-danger">Email is required</small>
      <small class="text-danger" *ngIf="email?.errors?.['email']"
        >Email is invalid</small
      >
    </div>
  </div>
  <div class="mb-3">
    <div class="form-group p-2 bg-white">
      <label for="password" class="mb-1">Password</label>
      <input
        type="password"
        id="password"
        formControlName="password"
        placeholder="Enter your password"
      />
      <img
        src="assets/icons/closepassword-icon.svg"
        alt="wrong"
        *ngIf="password?.invalid && password?.touched"
      />
      <img
        src="assets/icons/verification-checked-pink.svg"
        alt="correct"
        *ngIf="password?.valid"
      />
    </div>
    <div *ngIf="password?.invalid && password?.touched && password?.dirty">
      <div *ngIf="password?.errors?.['required']">
        <small class="text-danger">Password is required</small>
      </div>

      <div *ngIf="password?.errors?.['minlength']">
        <small class="text-danger"
          >Password must be at least 6 characters</small
        >
      </div>
      <div *ngIf="password?.errors?.['pattern']">
        <small class="text-danger">
          Password must contain at least one uppercase letter, one lowercase
          letter, one number, and one special character</small
        >
      </div>
    </div>
  </div>
  <div class="mb-4 d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
      <input
        type="checkbox"
        id="remember"
        name="remember"
        formControlName="remember"
      />
      <label class="checkbox" for="remember"></label>
      <label for="remember" class="fw-bolder remember-text">Remember me</label>
    </div>
    <!-- <a href="#" class="text-decoration-none forgot-password">Forgot password</a> -->
  </div>
  <swap-button
    [ngClass]="'w-100'"
    [type]="'submit'"
    [text]="'Submit'"
    [disabled]="!signinForm.valid"
  ></swap-button>
</form>
