import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TransactionsService } from 'src/app/services/api/transaction/transactions.service';
import { GlobalsService } from 'src/app/services/core/globals';
import { Country, Transaction } from 'src/app/services/core/IApp';

@Component({
  selector: 'create-offer',
  templateUrl: './create-offer.component.html',
  styleUrls: ['./create-offer.component.scss'],
})
export class CreateOfferComponent implements OnInit {
  maxAmount!: any;
  @Input() modalId: string = '';

  @Input() offer: Transaction | any;

  @Input() countries: Country[] = [];

  @Output() createOffer: EventEmitter<Transaction> =
    new EventEmitter<Transaction>();

  @ViewChild('closeModal') closeModal: any;

  iHave: Country | any;

  iWant: Country | any;

  createOfferForm: FormGroup = new FormGroup({
    amount: new FormControl('', Validators.compose([Validators.required])),
    minAmount: new FormControl(
      '',
      Validators.compose([Validators.max(this.maxAmount)])
    ),
    foreignCurrency: new FormControl(
      '',
      Validators.compose([Validators.required])
    ),
    localCurrency: new FormControl(
      '',
      Validators.compose([Validators.required])
    ),
    rate: new FormControl('', Validators.compose([Validators.required])),
    rating: new FormControl(0),
    preferredCountries: new FormControl([]),
    paymentSendFrom: new FormControl('Bank Transfer'),
    paymentReceiveThrough: new FormControl('Bank Transfer'),
    transactionPurpose: new FormControl(''),
    verificationStatus: new FormControl({}),
    transactionStatus: new FormControl(
      '',
      Validators.compose([Validators.required])
    ),
  });

  transactionPurposeList: Array<string> = [
    'Allowance/Maintenance',
    'Educational Support',
    'Family Support',
    'Gift',
    'Investment',
    'Loan',
    'Medical payment',
    'Real Estate',
    'Rent',
    'School Fees',
    'Tax payment',
    'Trade transaction',
    'Travel',
    'Utilities',
  ];

  paymentList: Array<string> = [
    'Bank Transfer',
    'Cash',
    'CashApp',
    'Crypto',
    'Interac e-transfer',
    'Lloyds Bank',
    'M-PESA',
    'Momo',
    'Monzo',
    'N26',
    'Payoneer',
    'Perfect Money',
    'Revolut',
    'Skrill',
    'Starling Bank',
    'Wire Transfer (SWIFT)',
    'Wise',
    'Zelle',
    'Other',
  ];

  verificationStatus: Array<{ name: string; rank: number }> = [
    { name: 'tier', rank: 3 },
    { name: 'tier', rank: 2 },
    { name: 'tier', rank: 1 },
  ];

  rateType: Array<string> = ['Any Rating', '4', '3', '2', '1', '0'];

  selectedCountries: Country[] = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    limitSelection: 3,
    allowSearchFilter: true,
  };

  // initialize variables for currency search filter
  searchCurrency: string = '';
  // initialize variables for currency conversion
  convertedRate: number = 0;

  constructor(
    private globals: GlobalsService,
    private transactionService: TransactionsService
  ) {}

  async ngOnInit() {
    this.resetForm();
  }

  async ngOnChanges() {
    if (this.offer && Object.keys(this.offer).length > 0) {
      const countryValues = this.offer?.preferredCountries.map(
        (res: { country: any }) => {
          return this.countries.find(
            (country: any) =>
              country.name.toLowerCase() === res.country.toLowerCase()
          );
        }
      );
      this.createOfferForm.patchValue({
        ...this.offer,
        preferredCountries: countryValues,
        foreignCurrency: this.offer?.foreignCurrency,
        localCurrency: this.offer?.localCurrency,
      });
      this.iHave = this.globals.countries.find((country) => {
        return country.name?.includes(this.offer?.localCurrency?.country);
      });
      this.iWant = this.globals.countries.find((country) => {
        return country.name?.includes(this.offer?.foreignCurrency?.country);
      });
      await this.getGlobalRate();
    } else {
      this.offer = undefined;
      this.resetForm();
    }
  }

  closeOpenModal() {
    this.closeModal.nativeElement.click();
  }

  setMinimumAmount(res: any) {
    this.maxAmount = parseInt(res.target.value);
    this.createOfferForm.get('minAmount')?.setValue(this.maxAmount);
    this.createOfferForm
      .get('minAmount')
      ?.setValidators([Validators.max(this.maxAmount)]);
    this.createOfferForm.get('minAmount')?.updateValueAndValidity();
  }

  selectCurrencyFilter() {
    // Get the dropdown element
    let dropDown = document.querySelector('#currency-select-3') as HTMLElement;
    // Get the search input element
    let searchbox = document.querySelector('#haveSearchBox') as HTMLElement;
    // Get the dropdown input to hide inner elements
    let dropdownInput = document.querySelector(
      '#dropdown-input-3'
    ) as HTMLElement;
    dropDown.classList.toggle('active');
    dropdownInput.classList.toggle('active');
    // Reset the search input
    this.searchCurrency = '';
    // Set focus on search input
    searchbox.focus();

    // Close the dropdown if the user clicks outside of it
    document.addEventListener('click', function (e: any) {
      if (!dropDown.contains(e.target)) {
        dropDown.classList.remove('active');
        dropdownInput.classList.remove('active');
      }
    });
  }

  selectCurrencyFilter2() {
    // Get the dropdown element
    let dropDown = document.querySelector('#currency-select-4') as HTMLElement;
    // Get the search input element
    let searchbox = document.querySelector('#wantSearchBox') as HTMLElement;
    // Get the dropdown input to hide inner elements
    let dropdownInput = document.querySelector(
      '#dropdown-input-4'
    ) as HTMLElement;
    dropDown.classList.toggle('active');
    dropdownInput.classList.toggle('active');
    // Reset the search input
    this.searchCurrency = '';
    // Set focus on search input
    searchbox.focus();

    // Close the dropdown if the user clicks outside of it
    document.addEventListener('click', function (e: any) {
      if (!dropDown.contains(e.target)) {
        dropDown.classList.remove('active');
        dropdownInput.classList.remove('active');
      }
    });
  }

  async selectCurrency(variable: any, country: any) {
    if (variable === 'have') {
      this.iHave = country;
      this.createOfferForm.patchValue({
        localCurrency: {
          country: country.name,
          code: country.currency,
          iso2: country.iso2,
        },
      });
    } else if (variable === 'want') {
      this.iWant = country;
      this.createOfferForm.patchValue({
        foreignCurrency: {
          country: country.name,
          code: country.currency,
          iso2: country.iso2,
        },
      });
    }

    await this.getGlobalRate();

    let dropDown = document.querySelector('#country-select') as HTMLElement;
    dropDown?.classList.remove(...'active');
  }

  activeDropdown(id: string) {
    let dropDown = document.querySelector(`#${id}`) as HTMLElement;
    dropDown.classList.toggle('active');
    // Close the dropdown if the user clicks outside of it
    document.addEventListener('click', function (e: any) {
      if (!dropDown.contains(e.target)) {
        dropDown.classList.remove('active');
      }
    });
  }

  selectOption(id: string, value: any) {
    this.createOfferForm.patchValue({
      [id]: value,
    });

    let dropDown = document.querySelector(`#${id}`) as HTMLElement;
    dropDown.classList.remove(...'active');
  }

  onSubmit(offerForm: Transaction) {
    offerForm.preferredCountries.map((country: any) => {
      country.country = country.name;
      country.iso2 = this.countries
        .find((countryListing: any) => countryListing.name === country.name)
        ?.iso2.toUpperCase();
    });
    this.createOffer.emit(offerForm);
    this.closeOpenModal();
    this.resetForm();
  }

  resetForm() {
    let country = this.countries.find((item) =>
      item.name.toLowerCase().includes('united kingdom')
    ) as Country;
    this.selectedCountries = [country];
    this.iHave = this.globals.countries.find((country) => {
      return country.name.toLowerCase().includes('united kingdom');
    });
    this.iWant = this.globals.countries.find((country) => {
      return country.name.toLowerCase().includes('united kingdom');
    });
    this.createOfferForm.patchValue({
      localCurrency: {
        code: this.iHave?.currency,
        country: this.iHave?.name,
        iso2: this.iHave?.iso2,
      },
      paymentSendFrom: 'Bank Transfer',
      paymentReceiveThrough: 'Bank Transfer',
      preferredCountries: this.selectedCountries,
      amount: '',
      minAmount: '',
      rate: '',
      rating: 0,
      foreignCurrency: {
        code: this.iWant?.currency,
        country: this.iWant?.name,
        iso2: this.iWant?.iso2,
      },
      transactionPurpose: '',
      verificationStatus: '',
    });
  }

  async getGlobalRate(
    want: string = this.iWant?.currency.toLowerCase(),
    have: string = this.iHave?.currency.toLowerCase()
  ) {
    let resp: any = await this.transactionService.getGlobalRate(want, have);
    console.log(resp);
    this.convertedRate = resp.conversion_rate;
  }

  async swapCurrencies() {
    let temp = this.iHave;
    this.iHave = this.iWant;
    this.iWant = temp;
    this.createOfferForm.patchValue({
      localCurrency: {
        code: this.iHave?.currency,
        country: this.iHave?.name,
        iso2: this.iHave?.iso2,
      },
      foreignCurrency: {
        code: this.iWant?.currency,
        country: this.iWant?.name,
        iso2: this.iWant?.iso2,
      },
    });
    await this.getGlobalRate();
  }
}
