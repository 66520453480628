<div class="row m-3">
  <div class="col-12 d-flex justify-content-end mb-5">
    <button
      type="button"
      class="closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
    >
      <img src="assets/icons/close-icon-pink.svg" alt="" />
    </button>
  </div>
  <div class="col-12 mb-5">
    <h5 class="fw-700 mb-2">{{currentRoute.includes('help-history') ? 'Create Ticket' : 'Contact User'}}</h5>
    <p class="mb-0 text-muted mb-5">Choose {{currentRoute.includes('help-history') ? 'ticket' : 'contact'}} route below</p>
    <div class="row g-0 gx-2 mb-3">
      <div class="col-6 form-group">
        <input
          type="radio"
          name="contact"
          value="email"
          id="email"
          [(ngModel)]="contactType"
        />
        <label for="email" class="mb-1">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M22.5847 4.00061C24.3727 4.00061 26.0927 4.70728 27.358 5.97528C28.6247 7.24061 29.3327 8.94728 29.3327 10.7339V21.2673C29.3327 24.9873 26.306 28.0006 22.5847 28.0006H9.41268C5.69135 28.0006 2.66602 24.9873 2.66602 21.2673V10.7339C2.66602 7.01394 5.67802 4.00061 9.41268 4.00061H22.5847ZM24.7059 12.7206L24.8126 12.6139C25.1313 12.2272 25.1313 11.6672 24.7979 11.2806C24.6126 11.0819 24.3579 10.9606 24.0926 10.9339C23.8126 10.9192 23.5459 11.0139 23.3446 11.2006L17.3326 16.0006C16.5593 16.6419 15.4513 16.6419 14.6659 16.0006L8.66592 11.2006C8.25126 10.8939 7.67792 10.9339 7.33259 11.2939C6.97259 11.6539 6.93259 12.2272 7.23792 12.6272L7.41259 12.8006L13.4793 17.5339C14.2259 18.1206 15.1313 18.4406 16.0793 18.4406C17.0246 18.4406 17.9459 18.1206 18.6913 17.5339L24.7059 12.7206Z"
              fill="#3D1560"
            />
          </svg>

          <p class="font-14 mb-0 mt-2">Email</p>
        </label>
      </div>
      <div class="col-6 form-group opacity-50">
        <input
          type="radio"
          name="contact"
          value="chat"
          id="chat"
          disabled
          [(ngModel)]="contactType"
        />
        <label for="chat" class="mb-1">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.66602 16.0207C2.66602 8.99685 8.27935 2.66736 16.026 2.66736C23.5993 2.66736 29.3327 8.87667 29.3327 15.9807C29.3327 24.2197 22.6127 29.334 15.9993 29.334C13.8127 29.334 11.386 28.7465 9.43935 27.5981C8.75935 27.1841 8.18602 26.877 7.45268 27.1174L4.75935 27.9186C4.07935 28.1322 3.46602 27.5981 3.66602 26.877L4.55935 23.8859C4.70602 23.4719 4.67935 23.0312 4.46602 22.6841C3.31935 20.5742 2.66602 18.2641 2.66602 16.0207ZM14.2666 16.0208C14.2666 16.9688 15.0266 17.73 15.9733 17.7433C16.9199 17.7433 17.6799 16.9688 17.6799 16.0341C17.6799 15.086 16.9199 14.3249 15.9733 14.3249C15.0399 14.3115 14.2666 15.086 14.2666 16.0208ZM20.4137 16.0341C20.4137 16.9688 21.1737 17.7433 22.1204 17.7433C23.0671 17.7433 23.8271 16.9688 23.8271 16.0341C23.8271 15.086 23.0671 14.3249 22.1204 14.3249C21.1737 14.3249 20.4137 15.086 20.4137 16.0341ZM9.82581 17.7434C8.89247 17.7434 8.11914 16.9689 8.11914 16.0341C8.11914 15.0861 8.87914 14.3249 9.82581 14.3249C10.7725 14.3249 11.5325 15.0861 11.5325 16.0341C11.5325 16.9689 10.7725 17.73 9.82581 17.7434Z"
              fill="#3D1560"
            />
          </svg>

          <p class="font-14 mb-0 mt-2">Chat</p>
        </label>
      </div>
    </div>
    <div class="col-12 text-end">
      <button class="swapBtn" (click)="closeBtn.click()" type="button">
        Cancel
      </button>
      <button
        class="swapBtn update ms-2"
        type="button"
        data-bs-dismiss="modal"
        (click)="emitContactType.emit(contactType)"
      >
        Next
      </button>
    </div>
  </div>
</div>
