<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header border-0 mx-3">
    <h5 class="modal-title fw-bolder" id="staticBackdropLabel">
      Ticket Detail
    </h5>
    <button
      type="button"
      class="closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeModal
    ></button>
  </div>
  <div class="modal-body mx-3">
    <div class="row">
      <div class="col-3 d-flex align-items-center">
        <p class="text-muted text-nowrap">Ticket ID</p>
      </div>
      <div class="col d-flex justify-content-end align-items-center">
        <p class="fw-500 fw-bolder">#{{ ticket?._id?.slice(-8) }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex align-items-center">
        <p class="text-muted">Status</p>
      </div>
      <div class="col d-flex justify-content-end align-items-center">
        <p
          class="fw-500 bg-warning status text-warning-2"
          [ngClass]="{
            'text-success': ticket?.status == 'resolved',
            'text-warning': ticket?.status == 'open'
          }"
        >
          {{ ticket?.status | titlecase }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex align-items-center">
        <p class="text-muted">Subject</p>
      </div>
      <div class="col d-flex justify-content-end align-items-center text-end">
        <p class="fw-700">
          {{ ticket?.subject }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex align-items-center">
        <p class="text-muted">Category</p>
      </div>
      <div class="col d-flex justify-content-end align-items-center">
        <p class="fw-700">
          {{ ticket?.category }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex align-items-center">
        <p class="text-muted">Time Created</p>
      </div>
      <div class="col d-flex justify-content-end align-items-center text-end">
        <p class="fw-700">
          {{ ticket?.createdAt | date : "mediumDate" : utc_offset }}
          .
          {{globals.formatTimestamp(ticket?.createdAt ) }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex align-items-center">
        <p class="text-muted">Message</p>
      </div>
      <div class="col d-flex justify-content-end align-items-center text-end">
        <p class="fw-700">
          {{ ticket?.messages?.content || 'No message' }}
        </p>
      </div>
    </div>
  </div>
</swap-modal>
