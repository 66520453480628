import { Component } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HelpHistoryService } from 'src/app/services/api/help-history/help-history.service';
import { SwapUserService } from 'src/app/services/api/swap_user/swap-user.service';
import { TransactionsService } from 'src/app/services/api/transaction/transactions.service';
import { GlobalsService } from 'src/app/services/core/globals';
Chart.register(...registerables);

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  collapsible: boolean = true;
  showIframe: boolean = false;
  isLoading: boolean = true; 
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 3,
      },
      740: {
        items: 4,
      },
      940: {
        items: 5,
      },
    },
    nav: false,
  };

  constructor(
    public transactionsService: TransactionsService,
    public swapUserService: SwapUserService,
    public helpHistoryService: HelpHistoryService,
    public globals: GlobalsService
  ) {}

  async ngOnInit() {
    // this.cardContainerDraggable();
    this.createLineChart();
    this.createDoughnutChart();
    this.globals.spinner.show();
    await this.swapUserService.getSwapUsersCount();
    await this.transactionsService.getTransactionsCount();
    await this.transactionsService.getAllOffersCount();
    await this.swapUserService.getActiveSwapUsersCount();
    await this.swapUserService.getTotalRefferalCount();
    await this.swapUserService.getNewSignUpsCount();
    await this.helpHistoryService.getHelpHistoryCount();
    this.globals.spinner.hide();
  }

  createLineChart() {
    const ctx = document.getElementById('lineChart') as HTMLCanvasElement;
    const myChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
          {
            label: 'My First Dataset',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            borderColor: '#3d1560',
            tension: 0.1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, ticks) {
                return '$' + value;
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }

  createDoughnutChart() {
    const ctx = document.getElementById('doughnutChart') as HTMLCanvasElement;
    const myChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Red', 'Blue', 'Yellow'],
        datasets: [
          {
            label: 'My First Dataset',
            data: [300, 50, 100],
            backgroundColor: [
              'rgb(255, 99, 132)',
              'rgb(54, 162, 235)',
              'rgb(255, 205, 86)',
            ],
            hoverOffset: 4,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        cutout: '70%',
      },
    });
  }

  selectDropdown(event: any) {
    event.preventDefault();
    const element = document.querySelectorAll('.card-dropdown-options');
    element.forEach((el) => {
      if (
        el.classList.contains('show') &&
        el !== event.target.nextElementSibling
      ) {
        el.classList.remove('show');
      }
    });
    event.target.nextElementSibling.classList.toggle('show');
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target?.classList.contains('card-dropdown-option')) {
        element.forEach((el) => {
          if (el.classList.contains('show')) {
            el.classList.remove('show');
          }
        });
      }
    });
  }

  cardContainerDraggable() {
    let cardContainer = document.querySelector(
      '.card-container'
    ) as HTMLDivElement;

    let isDown = false;
    let startX: number;
    let scrollLeft: number;

    cardContainer.addEventListener('mousedown', (e: any) => {
      isDown = true;
      cardContainer.classList.add('active');
      startX = e.pageX - cardContainer.offsetLeft;
      scrollLeft = cardContainer.scrollLeft;
    });
    cardContainer.addEventListener('mouseleave', () => {
      isDown = false;
      cardContainer.classList.remove('active');
    });
    cardContainer.addEventListener('mouseup', () => {
      isDown = false;
      cardContainer.classList.remove('active');
    });
    cardContainer.addEventListener('mousemove', (e: any) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - cardContainer.offsetLeft;
      const walk = (x - startX) * 2; //scroll-fast
      cardContainer.scrollLeft = scrollLeft - walk;
    });
  }

  async getNewSignUpsCount(filter: string) {
    this.globals.spinner.show();
    this.swapUserService.newSignUpBy = filter;
    await this.swapUserService.getNewSignUpsCount();
    this.globals.spinner.hide();
  }

  showSpinner() {
    this.isLoading = true;
    this.globals.spinner.show(); // Assuming globals.spinner has your spinner logic
  }
  
  // Function to hide spinner
  hideSpinner() {
    this.isLoading = false;
    this.globals.spinner.hide(); // Assuming globals.spinner has your spinner logic
  }
  
  // Function to handle iframe load event
  onIframeLoad() {
    this.hideSpinner(); // Hide spinner once iframe content is loaded
  }
}
