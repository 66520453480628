import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Validation from 'src/app/services/shared/validation';

@Component({
  selector: 'password-reset-form',
  templateUrl: './password-reset-form.component.html',
  styleUrls: ['./password-reset-form.component.scss'],
})
export class PasswordResetFormComponent {
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

  passwordForm: FormGroup = new FormGroup(
    {
      current_password: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
        ),
      ]),
      confirmPassword: new FormControl('', [Validators.required]),
    },
    {
      validators: Validation.match('password', 'confirmPassword'),
    }
  );

  showCurrentPassword: boolean = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;

  constructor() {}

  submitPassword() {
    this.onSubmit.emit(this.passwordForm.value);
    this.passwordForm.reset({
      current_password: '',
      password: '',
      confirmPassword: '',
    })
  }

  get current_password() {
    return this.passwordForm.get('current_password') as FormControl;
  }

  get password() {
    return this.passwordForm.get('password') as FormControl;
  }

  get confirmPassword() {
    return this.passwordForm.get('confirmPassword') as FormControl;
  }
}
