import { Component, EventEmitter, Output } from '@angular/core';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'contact-user',
  templateUrl: './contact-user.component.html',
  styleUrls: ['./contact-user.component.scss'],
})
export class ContactUserComponent {
  contactType: string = 'email';
  currentRoute: string = '';

  @Output() emitContactType: EventEmitter<string> = new EventEmitter<string>();

  constructor(private globals: GlobalsService) {
    this.currentRoute = this.globals.router.routerState.snapshot.url;
  }
}
