import { Component, Input } from '@angular/core';
import { Ticket } from 'src/app/services/core/IApp';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'view-ticket',
  templateUrl: './view-ticket.component.html',
  styleUrls: ['./view-ticket.component.scss'],
})
export class ViewTicketComponent {
  @Input() ticket: Ticket | any;
  @Input() modalId: string = '';
  @Input() utc_offset: string = '';

constructor(   public globals: GlobalsService,){}
}
