<div class="row transactionModal m-3">
  <div class="col-12 d-flex justify-content-end mb-5" *ngIf="!showCloseButton">
    <button
      type="button"
      class="closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeButton
    >
      <img src="assets/icons/close-icon.svg" alt="" />
    </button>
  </div>
  <div class="col-12 mb-5">
    <div class="d-flex justify-content-center align-items-center">
      <div
        class="d-flex justify-content-center align-items-center flex-column gap-1 pointer"
        (click)="navigate(transaction?.bidding?.seller?.user?._id)"
      >
        <img
          [src]="
            transaction?.bidding?.seller?.user?.avatar ||
            'assets/icons/user.svg'
          "
          class="rounded-circle"
          alt="avatar"
          width="83"
          height="83"
        />
        <p class="mb-0 fw-500 text-muted">
          {{ transaction?.bidding?.seller?.user?.username || "Seller" }}
        </p>
      </div>
      <img
        src="assets/icons/swap-row-icon.svg"
        alt=""
        class="mx-4"
        width="20"
      />
      <div
        class="d-flex justify-content-center align-items-center flex-column gap-1 pointer"
        (click)="navigate(transaction?.bidding?.buyer?.user?._id)"
      >
        <img
          [src]="
            transaction?.bidding?.buyer?.user?.avatar || 'assets/icons/user.svg'
          "
          class="rounded-circle"
          alt="avatar"
          width="83"
          height="83"
        />
        <p class="mb-0 fw-500 text-muted">
          {{ transaction?.bidding?.buyer?.user?.username || "Buyer" }}
        </p>
      </div>
    </div>
    <button
      class="viewBtn fw-500"
      *ngIf="!showCloseButton"
      (click)="closeButton?.nativeElement?.click()"
      routerLink="/main/transactions/messages/{{ transaction?._id }}"
    >
      <svg
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="me-2"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 8.01127C0 4.06034 3.1575 0.5 7.515 0.5C11.775 0.5 15 3.99274 15 7.98873C15 12.6232 11.22 15.5 7.5 15.5C6.27 15.5 4.905 15.1695 3.81 14.5235C3.4275 14.2907 3.105 14.1179 2.6925 14.2531L1.1775 14.7038C0.795 14.824 0.45 14.5235 0.5625 14.1179L1.065 12.4354C1.1475 12.2026 1.1325 11.9547 1.0125 11.7594C0.3675 10.5726 0 9.27316 0 8.01127ZM6.525 8.01127C6.525 8.54457 6.9525 8.97271 7.485 8.98022C8.0175 8.98022 8.445 8.54457 8.445 8.01878C8.445 7.48548 8.0175 7.05734 7.485 7.05734C6.96 7.04982 6.525 7.48548 6.525 8.01127ZM9.9825 8.01878C9.9825 8.54457 10.41 8.98022 10.9425 8.98022C11.475 8.98022 11.9025 8.54457 11.9025 8.01878C11.9025 7.48548 11.475 7.05734 10.9425 7.05734C10.41 7.05734 9.9825 7.48548 9.9825 8.01878ZM4.0275 8.98022C3.5025 8.98022 3.0675 8.54457 3.0675 8.01878C3.0675 7.48548 3.495 7.05734 4.0275 7.05734C4.56 7.05734 4.9875 7.48548 4.9875 8.01878C4.9875 8.54457 4.56 8.97271 4.0275 8.98022Z"
          fill="#3D1560"
        />
      </svg>

      View Chat
    </button>
  </div>
  <div class="col-12">
    <div class="accordion" id="accordionPanelsStayOpenExample">
      <div class="accordion-item mb-3">
        <h2 class="accordion-header" id="panelsStayOpen-headingOne">
          <button
            class="accordion-button fw-bold"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseOne"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapseOne"
          >
            Transaction detail
          </button>
        </h2>
        <div
          id="panelsStayOpen-collapseOne"
          class="accordion-collapse collapse show"
          aria-labelledby="panelsStayOpen-headingOne"
        >
          <div class="accordion-body">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <p class="text-muted">Transaction ID</p>
              </div>
              <div class="col-6 d-flex justify-content-end align-items-center">
                <p class="fw-500">#{{ transaction?._id }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <p class="text-muted">Status</p>
              </div>
              <div class="col-6 d-flex justify-content-end align-items-center">
                <p
                  class="fw-500 text-success status"
                  [ngClass]="{
                    'text-success':
                      (transaction?.bidding?.biddingStatus | titlecase) ===
                      'Completed',
                    'text-danger':
                      (transaction?.bidding?.biddingStatus | titlecase) ===
                      'Failed',
                    'text-secondary':
                      (transaction?.bidding?.biddingStatus | titlecase) ===
                      'Pending'
                  }"
                >
                  {{ transaction?.bidding?.biddingStatus | titlecase }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <p class="text-muted">Seller</p>
              </div>
              <div class="col-6 d-flex justify-content-end align-items-center">
                <p class="fw-700 text-primary">
                  {{ transaction?.bidding?.seller?.user?.username }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <p class="text-muted">Buyer</p>
              </div>
              <div class="col-6 d-flex justify-content-end align-items-center">
                <p class="fw-700 text-primary">
                  {{
                    transaction?.bidding?.buyer?.user?.username ||
                      "No Buyer Yet"
                  }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <p class="text-muted">Wants</p>
              </div>
              <div class="col-6 d-flex justify-content-end align-items-center">
                <p class="fw-700">
                  {{
                    calculateCurrency(transaction?.amount, transaction?.rate)
                      | currency
                        : transaction?.foreignCurrency?.code
                        : "symbol-narrow"
                  }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <p class="text-muted">Has</p>
              </div>
              <div class="col-6 d-flex justify-content-end align-items-center">
                <p class="fw-700">
                  {{
                    transaction?.amount
                      | currency
                        : transaction?.localCurrency?.code
                        : "symbol-narrow"
                  }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <p class="text-muted">Rate</p>
              </div>
              <div class="col-6 d-flex justify-content-end align-items-center">
                <p class="fw-700">
                  {{ transaction?.rate }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <p class="text-muted">Country</p>
              </div>
              <div class="col-6 d-flex justify-content-end align-items-center">
                <p class="fw-700">
                  <ng-container
                    *ngFor="let country of transaction?.preferredCountries"
                  >
                    {{ country.country }}
                    {{ transaction?.preferredCountries.length > 1 ? "," : "" }}
                  </ng-container>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <p class="text-muted">Payment sent from</p>
              </div>
              <div class="col-6 d-flex justify-content-end align-items-center">
                <p class="fw-700">
                  {{ transaction?.paymentSendFrom }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <p class="text-muted">Payment received through</p>
              </div>
              <div class="col-6 d-flex justify-content-end align-items-center">
                <p class="fw-700">
                  {{ transaction?.paymentReceiveThrough }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <p class="text-muted">Limit</p>
              </div>
              <div class="col-6 d-flex justify-content-end align-items-center">
                <p class="fw-700 text-end">
                  {{
                    transaction?.minAmount
                      | currency
                        : transaction?.localCurrency?.code
                        : "symbol-narrow"
                  }}
                  -
                  {{
                    transaction?.amount
                      | currency
                        : transaction?.localCurrency?.code
                        : "symbol-narrow"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
          <button
            class="accordion-button fw-bold"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseTwo"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapseTwo"
          >
            Payment Upload
          </button>
        </h2>
        <div
          id="panelsStayOpen-collapseTwo"
          class="accordion-collapse collapse show"
          aria-labelledby="panelsStayOpen-headingTwo"
        >
          <div class="accordion-body">
            <p class="text-muted mb-3">
              {{
                transaction?.bidding?.seller?.upload &&
                transaction?.bidding?.buyer?.upload
                  ? "2"
                  : transaction?.bidding?.seller?.upload ||
                    transaction?.bidding?.buyer?.upload
                  ? "1"
                  : "0"
              }}
              files
            </p>
            <div class="row gap-3">
              <div
                class="col-12 border-bottom"
                *ngIf="transaction?.bidding?.seller?.upload"
              >
                <div class="d-flex gap-3">
                  <img
                    [src]="
                      transaction?.bidding?.seller?.user?.avatar ||
                      'assets/icons/user.svg'
                    "
                    class="rounded-circle"
                    alt="avatar"
                    width="32"
                    height="32"
                  />
                  <div class="row gap-2">
                    <div class="col-12">
                      <p class="fw-700 mb-0">
                        {{ "@" + transaction?.bidding?.seller?.user?.username }}
                      </p>
                      <p class="text-muted mb-0">
                        Uploaded
                        <span class="text-secondary">
                          @
                          {{globals.formatTimestamp
                            (transaction.bidding.seller.upload.date)
                             
                          }}
                          |
                          {{
                            transaction?.bidding?.seller?.upload?.date
                            | date : "dd MMM yyyy" : globals.utc_offset
                          }}


                        </span>



                     
                    </div>
                    <div
                      class="col-12 d-flex align-items-center justify-content-between pb-2"
                    >
                      <div class="d-flex align-items-center gap-1">
                        <img
                          src="assets/icons/payment-proof.svg"
                          class="paymentProofImg"
                        />
                        <div class="ms-2">
                          <p class="mb-0 fw-500">
                            {{
                              transaction?.bidding?.seller?.upload?.url
                                .split("?")[0]
                                .split("/")
                                [
                                  transaction?.bidding?.seller?.upload?.url
                                    .split("?")[0]
                                    .split("/").length - 1
                                ].split(".")[0]
                                .substr(0, 10) +
                                "..." +
                                transaction?.bidding?.seller?.upload?.url
                                  .split("?")[0]
                                  .split("/")
                                  [
                                    transaction?.bidding?.seller?.upload?.url
                                      .split("?")[0]
                                      .split("/").length - 1
                                  ].split(".")[1]
                            }}
                          </p>
                          <!-- <p class="text-muted mb-0">720kb</p> -->
                        </div>
                      </div>
                      <button
                        type="button"
                        class="showProof"
                        (click)="
                          showProof.emit({
                            biddingId: transaction?._id,
                            type: 'seller'
                          })
                        "
                      >
                        <img src="assets/images/Show.svg" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-12 border-bottom"
                *ngIf="transaction?.bidding?.buyer?.upload"
              >
                <div class="d-flex gap-3">
                  <img
                    [src]="
                      transaction?.bidding?.buyer?.user?.avatar ||
                      'assets/icons/user.svg'
                    "
                    class="rounded-circle"
                    alt="avatar"
                    width="32"
                    height="32"
                  />
                  <div class="row gap-2">
                    <div class="col-12">
                      <p class="fw-700 mb-0">
                        {{ "@" + transaction?.bidding?.buyer?.user?.username }}
                      </p>
                      <p class="text-muted mb-0">
                        Uploaded
                        <span class="text-secondary">
                          @
                          {{globals.formatTimestamp
                            (transaction.bidding.buyer.upload.date)
                             
                          }}
                          |
                          {{
                            transaction?.bidding?.buyer?.upload?.date
                            | date : "dd MMM yyyy" : globals.utc_offset
                          }}
                        </span>

                     
                      </p>
                    </div>
                    <div
                      class="col-12 d-flex align-items-center justify-content-between pb-2"
                    >
                      <div class="d-flex align-items-center gap-1">
                        <img
                          src="assets/icons/payment-proof.svg"
                          class="paymentProofImg"
                        />
                        <div class="ms-2">
                          <p class="mb-0 fw-500">
                            {{
                              transaction?.bidding?.buyer?.upload?.url
                                .split("?")[0]
                                .split("/")
                                [
                                  transaction?.bidding?.buyer?.upload?.url
                                    .split("?")[0]
                                    .split("/").length - 1
                                ].split(".")[0]
                                .substr(0, 10) +
                                "..." +
                                transaction?.bidding?.buyer?.upload?.url
                                  .split("?")[0]
                                  .split("/")
                                  [
                                    transaction?.bidding?.buyer?.upload?.url
                                      .split("?")[0]
                                      .split("/").length - 1
                                  ].split(".")[1]
                            }}
                          </p>
                          <!-- <p class="text-muted mb-0">720kb</p> -->
                        </div>
                      </div>
                      <button
                        type="button"
                        class="showProof"
                        (click)="
                          showProof.emit({
                            biddingId: transaction?._id,
                            type: 'buyer'
                          })
                        "
                      >
                        <img src="assets/images/Show.svg" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-0 text-muted fw-400">Transfer approval</h6>
                  <p class="mb-0 fw-700">Yes</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
