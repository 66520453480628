<swap-modal
  [modalId]="modalId"
  [centered]="true"
  [keyboard]="true"
  backdrop="static"
>
  <div class="modal-header border-0 mx-3 mt-2">
    <h5 class="modal-title font-18 fw-700" id="staticBackdropLabel">
      View Image
    </h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="globals.openModal(previousModal)"
    ></button>
  </div>
  <div class="modal-body mx-3">
    <div class="row g-0 pb-4">
      <div class="col-12 text-center mb-2">
        <img
          loading="lazy"
          [src]="image"
          alt="currentImg"
          (click)="zoom = !zoom"
          [ngClass]="{ zoom: zoom }"
        />
      </div>
    </div>
  </div>
</swap-modal>
