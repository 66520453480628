<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header mb-4">
    <p class="modal-title fw-bolder fs-16" id="staticBackdropLabel">
      {{ currentTab | titlecase }} History
    </p>
    <button
      type="button"
      class="closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
    >
      <img loading="lazy" src="assets/icons/close-icon.svg" alt="" />
    </button>
  </div>
  <div class="modal-body m-3 mt-0 pt-0">
    <!-- Nav tabs -->
    <ul class="nav nav-pills flex-nowrap" id="myTab" role="tablist">
      <li class="nav-item" role="presentation" *ngFor="let tab of tabs">
        <button
          class="nav-link"
          [ngClass]="{ active: currentTab == tab }"
          id="home-tab"
          data-bs-toggle="tab"
          data-bs-target="#home"
          (click)="currentTab = tab; currentTabChange.emit(tab)"
          type="button"
          role="tab"
          aria-controls="home"
          aria-selected="true"
        >
          {{ tab | titlecase }}
        </button>
      </li>
    </ul>

    <div class="d-flex align-items-center justify-content-between my-5 mb-3">
      <p class="fw-bolder mb-0">Transaction Details</p>
      <button class="filter-btn">
        <img
          loading="lazy"
          src="assets/icons/filter-icon.svg"
          alt=""
          class="me-2"
        />
        Filters
      </button>
    </div>

    <!-- Tab panes -->
    <div class="">
      <div
        class="tab-pane active"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <ng-container *ngFor="let tokens of tokenHistory">
          <div class="text-center">
            <small class="text-muted">
              {{ tokens.date | date : "dd MMMM yyyy" : utc_offset }}
            </small>
          </div>
          <div class="row g-0">
            <div
              class="col-12 d-flex align-items-center justify-content-between border-bottom py-2"
              *ngFor="let token of tokens.tokens"
            >
              <div>
                <p class="mb-0 fw-bold">
                  {{ token.type | titlecase }}
                </p>
                <small class="text-muted">
                  {{globals.formatTimestamp(token.createdAt)}}
                </small>
              </div>
              <p
                class="mb-0"
                [ngClass]="{
                  'text-success':
                    currentTab == 'earned' || currentTab == 'purchased',
                  'text-secondary': currentTab == 'spent'
                }"
              >
                {{ currentTab == "spent" ? "-" : "+" }} {{ token.amount }}
                EXS
              </p>
            </div>
            <!-- <div
              class="col-12 d-flex align-items-center justify-content-between border-bottom py-2"
            >
              <div>
                <p class="mb-0 fw-bold">Content</p>
                <small class="text-muted">10:32pm</small>
              </div>
              <p class="mb-0 text-secondary">- 16.55 EXS</p>
            </div>
            <div
              class="col-12 d-flex align-items-center justify-content-between py-2"
            >
              <div>
                <p class="mb-0 fw-bold">Content</p>
                <small class="text-muted">10:32pm</small>
              </div>
              <p class="mb-0 text-secondary">- 16.55 EXS</p>
            </div> -->
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</swap-modal>
