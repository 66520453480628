<form
  class="row g-0 px-3 gap-2"
  (ngSubmit)="onSubmit()"
  [formGroup]="messageForm"
>
  <div class="col-12">
    <textarea
      class="form-control"
      name="comment"
      rows="3"
      formControlName="comment"
      placeholder="Send a message..."
      required
      *ngIf="showEmail"
      (keydown)="onKeydown($event)"
    ></textarea>

    <ngx-editor
      [editor]="editor"
      class="form-control"
      formControlName="comment"
      *ngIf="!showEmail"
      [placeholder]="'Send a message...'"
    ></ngx-editor>
  </div>
  <div class="col-12">
    <div class="d-flex align-items-center justify-content-between">
      <button type="button" class="transparentBtn" *ngIf="showEmail">
        <img src="assets/icons/Image-inv.svg" alt="Picture Image" />
      </button>
      <div class="toolbar" *ngIf="!showEmail">
        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
        </ngx-editor-menu>
      </div>
      <div class="d-flex align-items-center gap-5">
        <button type="submit" class="submitBtn">Send</button>
      </div>
    </div>
  </div>
</form>
