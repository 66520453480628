<div class="row justify-content-between g-0">
  <div class="col-6 d-flex align-items-center">
    <p class="fw-bolder h4">{{ title.split("-")[0].trim() | titlecase }}</p>
  </div>
  <div class="col-6 d-flex justify-content-end align-items-center gap-3">
    <button
      class="sm-ds-none notification"
      [attr.data-count]="
        globals.numberOfUnreadMessages > 9
          ? '9+'
          : globals.numberOfUnreadMessages
      "
      (click)="selectDropdown($event)"
      [ngClass]="{ active: globals.unreadMessages }"
    >
      <img
        loading="lazy"
        src="assets/icons/notification-bell-icon.svg"
        alt="Notification Icon"
      />
      <div class="dropdown-options">
        <div
          class="col-12 d-flex justify-content-between align-items-center border-bottom p-3"
        >
          <p class="mb-0 fw-500 fw-bolder">Notifications</p>

          <a
            role="button"
            class="text-decoration-none text-muted text-secondary fw-500"
            (click)="markAllNotificationsAsRead($event)"
            (mousedown)="keepDropdownOpen = true"
          >
            <img
              src="assets/icons/notification-checks.svg"
              alt="checkmark icon"
            />
            Mark all as read</a
          >
        </div>

        <div class="scroll-container">
          <a
            *ngFor="let notification of globals.notifications"
            class="dropdown-option gap-2 align-items-start justify-content-between text-decoration-none text-muted"
            (click)="markAsRead(notification._id)"
          >
            <img
              [src]="notification.avatar || 'assets/icons/user.svg'"
              alt="Notif Img"
            />
            <div class="col-9 text-start">
              <p class="mb-0 font-14 text-dark fw-500">
                {{ notification.title | titlecase }}
              </p>
              <p
                class="mb-2 font-12 text-muted"
                [innerHTML]="globals.replaceCurlies(notification.message)"
              ></p>
              <p class="text-muted mb-0 fw-500 font-12">
                {{ notification.createdAt | dateAsAgo }}
              </p>
            </div>
            <div class="col-1 d-flex align-items-center justify-content-end">
              <span
                class="dot"
                *ngIf="notification.markedAsRead == false"
              ></span>
            </div>
          </a>
          <ngx-skeleton-loader
            [theme]="{
              width: '100%',
              height: '100%'
            }"
            *ngIf="globals.notifications.length == 0"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </button>
    <!-- </div> -->

    <button
      class="profile-dropdown d-flex align-items-center gap-3"
      (click)="selectDropdown($event)"
    >
      <img
        [src]="globals.user?.avatar || 'assets/icons/user.svg'"
        alt="Profile Pic"
      />
      <h6 class="mb-0 fw-bolder">
        {{ globals.user?.name?.first }} {{ globals.user?.name?.last }}
      </h6>
      <img src="assets/icons/arrow.svg" alt="Chevron" width="25px" />
      <div class="dropdown-options">
        <a href="#" class="dropdown-option text-decoration-none text-muted">
          <img
            [src]="globals.user?.avatar || 'assets/icons/user.svg'"
            alt="Profile Pic"
          />
          <div class="text-start">
            <h6 class="mb-0 fw-bolder text-dark font-12">
              {{ globals.user?.name?.first }} {{ globals.user?.name?.last }}
            </h6>
            <p class="mb-0 font-12 truncate" style="max-width: 7rem">
              {{ globals.user?.email }}
            </p>
          </div>
        </a>
        <a
          routerLink="/main/settings/account-info"
          class="dropdown-option text-decoration-none text-muted"
          ><svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.5293 4.8607C11.5293 6.81872 9.95942 8.38873 8.00002 8.38873C6.04129 8.38873 4.4707 6.81872 4.4707 4.8607C4.4707 2.90268 6.04129 1.33333 8.00002 1.33333C9.95942 1.33333 11.5293 2.90268 11.5293 4.8607ZM8.00002 14.6667C5.10827 14.6667 2.66669 14.1967 2.66669 12.3833C2.66669 10.5693 5.12361 10.1159 8.00002 10.1159C10.8924 10.1159 13.3334 10.586 13.3334 12.3993C13.3334 14.2133 10.8764 14.6667 8.00002 14.6667Z"
              fill="#797B89"
            />
          </svg>
          View profile</a
        >
        <a
          routerLink="/main/settings"
          class="dropdown-option text-decoration-none text-muted"
          ><svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.6015 9.0534C13.84 9.18007 14.0239 9.38007 14.1534 9.58007C14.4055 9.9934 14.385 10.5001 14.1398 10.9467L13.6628 11.7467C13.4108 12.1734 12.9407 12.4401 12.4569 12.4401C12.2185 12.4401 11.9528 12.3734 11.7347 12.2401C11.5576 12.1267 11.3532 12.0867 11.1352 12.0867C10.4607 12.0867 9.89522 12.6401 9.87478 13.3001C9.87478 14.0667 9.24798 14.6667 8.46448 14.6667H7.53791C6.7476 14.6667 6.12079 14.0667 6.12079 13.3001C6.10717 12.6401 5.54169 12.0867 4.86719 12.0867C4.64236 12.0867 4.43797 12.1267 4.26765 12.2401C4.04963 12.3734 3.77711 12.4401 3.54546 12.4401C3.05492 12.4401 2.58482 12.1734 2.33274 11.7467L1.86264 10.9467C1.61056 10.5134 1.59693 9.9934 1.84901 9.58007C1.95802 9.38007 2.16241 9.18007 2.39406 9.0534C2.58482 8.96007 2.70746 8.80674 2.82328 8.62674C3.16393 8.0534 2.95954 7.30007 2.38043 6.96007C1.70594 6.58007 1.48792 5.7334 1.87627 5.0734L2.33274 4.28674C2.7279 3.62674 3.57271 3.3934 4.25402 3.78007C4.84675 4.10007 5.61663 3.88674 5.96409 3.32007C6.0731 3.1334 6.13442 2.9334 6.12079 2.7334C6.10717 2.4734 6.18211 2.22674 6.31156 2.02674C6.56364 1.6134 7.02012 1.34674 7.51747 1.3334H8.47811C8.98228 1.3334 9.43875 1.6134 9.69083 2.02674C9.81347 2.22674 9.89522 2.4734 9.87478 2.7334C9.86116 2.9334 9.92248 3.1334 10.0315 3.32007C10.379 3.88674 11.1488 4.10007 11.7484 3.78007C12.4229 3.3934 13.2745 3.62674 13.6628 4.28674L14.1193 5.0734C14.5145 5.7334 14.2965 6.58007 13.6151 6.96007C13.036 7.30007 12.8316 8.0534 13.1791 8.62674C13.2881 8.80674 13.4108 8.96007 13.6015 9.0534ZM6.0731 8.00674C6.0731 9.0534 6.93836 9.88674 8.00801 9.88674C9.07766 9.88674 9.92248 9.0534 9.92248 8.00674C9.92248 6.96007 9.07766 6.12007 8.00801 6.12007C6.93836 6.12007 6.0731 6.96007 6.0731 8.00674Z"
              fill="#797B89"
            />
          </svg>
          Settings</a
        >
        <a
          class="dropdown-option text-decoration-none text-muted"
          (click)="globals.loggedOut()"
          ><svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.59688 7.48668C6.30521 7.48668 6.07459 7.71334 6.07459 8.00001C6.07459 8.28001 6.30521 8.51334 6.59688 8.51334H10.6666V11.7C10.6666 13.3333 9.31684 14.6667 7.64824 14.6667H4.34494C2.68312 14.6667 1.33331 13.34 1.33331 11.7067V4.30001C1.33331 2.66001 2.6899 1.33334 4.35172 1.33334H7.6618C9.31684 1.33334 10.6666 2.66001 10.6666 4.29334V7.48668H6.59688ZM13.0868 5.69348L15.0334 7.63348C15.1334 7.73348 15.1868 7.86014 15.1868 8.00014C15.1868 8.13348 15.1334 8.26681 15.0334 8.36014L13.0868 10.3001C12.9868 10.4001 12.8534 10.4535 12.7268 10.4535C12.5934 10.4535 12.4601 10.4001 12.3601 10.3001C12.1601 10.1001 12.1601 9.77348 12.3601 9.57348L13.4268 8.51348H10.6668V7.48681H13.4268L12.3601 6.42681C12.1601 6.22681 12.1601 5.90014 12.3601 5.70014C12.5601 5.49348 12.8868 5.49348 13.0868 5.69348Z"
              fill="#E61B00"
            />
          </svg>
          Log out</a
        >
      </div>
    </button>
  </div>
</div>

<deactivate-modal
  [modalId]="modalId2"
  [username]="swapUserService.swapUser?.username"
  (emitDeleteAccount)="deactivateAccount()"
></deactivate-modal>

<deactivate-reason-modal
  [modalId]="modalId"
  (emitDeleteAccount)="reason = $event; globals.openModal(modalId2)"
></deactivate-reason-modal>

<freeze-modal
  [modalId]="modalId3"
  [username]="swapUserService.swapUser?.username"
  (emitFreezeAccount)="freezeAccount()"
></freeze-modal>

<swap-modal [modalId]="modalId4" [centered]="true">
  <contact-user (emitContactType)="contactUser($event)"></contact-user>
</swap-modal>

<swap-modal [modalId]="modalId5" [centered]="true">
  <email-contact-form
    [swapUser]="swapUserService.swapUser"
    (emitEmail)="sendEmail($event)"
  ></email-contact-form>
</swap-modal>

<!-- Choose Time Modal -->
<choose-time
  [modalId]="modalId6"
  (emitTimeExtention)="extendTime($event)"
></choose-time>
