import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'cancel-plan-reason',
  templateUrl: './cancel-plan-reason.component.html',
  styleUrls: ['./cancel-plan-reason.component.scss'],
})
export class CancelPlanReasonComponent {
  @Input() modalId: string = '';
  @Input() email: string = '';
  @Output() emitCancelPlan: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('closeModal') closeModal: HTMLButtonElement | any;

  reasons: string[] = [
    'Inactivity',
    'Violation of Terms of Service',
    'Duplicate Account',
    'Requested by User',
    'Security Concerns',
    'Others',
  ];

  selectedReasons: string[] = [];

  otherReason: string = '';

  constructor() {}

  ngOnInit(): void {}

  cancelPlan() {
    this.emitCancelPlan.emit(this.selectedReasons.join(', '));
    this.closeModal.nativeElement.click();
  }

  closeDialog() {
    this.closeModal.nativeElement.click();
  }

  selectReason(reason: string) {
    if (this.selectedReasons.includes(reason)) {
      this.selectedReasons = this.selectedReasons.filter(
        (selectedReason) => selectedReason !== reason
      );
    } else {
      this.selectedReasons.push(reason);
    }
  }
}
