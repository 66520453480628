<form [formGroup]="notificationsForm" class="mb-4" (ngSubmit)="submit()">
  <div class="row g-0">
    <div class="col-12 row g-4 px-3">
      <div class="row g-0 mb-5 justify-content-between">
        <div class="col-6">
          <p class="form-label fw-700 font-14 mb-0">Notifications</p>
          <p class="mb-0 text-muted font-12">
            Manage alerts sent when you change account information, or when you
            have discounts or special offers.
          </p>
        </div>
        <div class="col-6 d-flex justify-content-end">
          <div class="form-check form-switch mb-3">
            <input
              class="form-check-input"
              type="checkbox"
              id="r&r-push"
              formControlName="review_rating_push"
              name="review_rating_push"
            />
          </div>
        </div>
      </div>
      <div class="row g-0 mb-5 justify-content-between">
        <div class="col-6">
          <p class="form-label fw-700 font-14 mb-0">Payment Notifications</p>
          <p class="mb-0 text-muted font-12">
            Integrate Overpay payment notifications with your website.
          </p>
        </div>
        <div class="col-6 d-flex justify-content-end">
          <div class="form-check form-switch mb-3">
            <input
              class="form-check-input"
              type="checkbox"
              id="r&r-push"
              formControlName="review_rating_push"
              name="review_rating_push"
            />
          </div>
        </div>
      </div>
      <div class="row g-0 mb-5 justify-content-between">
        <div class="col-6">
          <p class="form-label fw-700 font-14 mb-0">Review Notification</p>
          <p class="mb-0 text-muted font-12">
            Receive a notifications for reviews
          </p>
        </div>
        <div class="col-6 d-flex justify-content-end">
          <div class="form-check form-switch mb-3">
            <input
              class="form-check-input"
              type="checkbox"
              id="r&r-push"
              formControlName="review_rating_push"
              name="review_rating_push"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
