import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'top-up-all-form',
  templateUrl: './top-up-all-form.component.html',
  styleUrls: ['./top-up-all-form.component.scss'],
})
export class TopUpAllFormComponent implements OnInit {
  @Input() swapUsersCount: number = 0;
  @Input() type: string = '';
  @Output() emitAllTopUp: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('closeBtn') closeBtn: any;

  topUpAllForm: FormGroup = new FormGroup({
    token_type: new FormControl('', Validators.compose([Validators.required])),
    validity: new FormControl('', Validators.compose([Validators.required])),
    status: new FormControl('', Validators.compose([Validators.required])),
    start_date: new FormControl('', Validators.compose([Validators.required])),
    end_date: new FormControl('', Validators.compose([Validators.required])),
    amount: new FormControl('', Validators.compose([Validators.required])),
  });

  tokenTypes: string[] = ['Bonus', 'Purchased', 'Subscription'];
  validities: string[] = ['1 Month', '2 Months', '3 Months'];
  statuses: string[] = ['Active', 'Inactive'];

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy() {
    this.topUpAllForm.reset();
  }

  clearCheckboxes() {
    this.emitAllTopUp.emit(true);
  }

  onSubmit() {
    this.topUpAllForm.reset();
    this.closeBtn.nativeElement.click();
  }

  selectDropdown(event: any) {
    // close other dropdown options
    event.preventDefault();
    const element = document.querySelectorAll('.dropdown-options');
    element.forEach((el) => {
      if (
        el.classList.contains('show') &&
        el !== event.target.nextElementSibling
      ) {
        el.classList.remove('show');
      }
    });

    // add show class to dropdown options
    event.target.children[0]?.classList.toggle('show');

    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target?.classList.contains('dropdown-option')) {
        element.forEach((el) => {
          if (el.classList.contains('show')) {
            el.classList.remove('show');
          }
        });
      }
    });
  }

  async setDate(event: string, type: string) {
    switch (type) {
      case 'start':
        this.topUpAllForm.controls['start_date'].setValue(event);
        break;
      case 'end':
        this.topUpAllForm.controls['end_date'].setValue(event);
        break;
      default:
        break;
    }
  }

  setDateValue(event: string, type: string) {
    switch (type) {
      case 'start':
        this.topUpAllForm.controls['start_date'].setValue(event);
        break;
      case 'end':
        this.topUpAllForm.controls['end_date'].setValue(event);
        break;
      default:
        break;
    }
  }

  selectItem(value: string, control: string) {
    this.topUpAllForm.controls[control].setValue(value);
  }
}
